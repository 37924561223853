/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getDefaultDate } from '../common/utils';

const initialState = {
  series: [],
  firstLoading: false,
  loading: false,
  tableLoading: true,
  tableData: [],
  tablePage: 1,
  tableTotal: 0,
  filters: {
    kpi: null,
    profile: null,
    date: getDefaultDate(),
  },
  tableFilter: { column: '', order: '' },
  last48Series: [],
};

const AnalyzeTopicsInsightSlice = createSlice({
  name: 'AnalyzeTopicsInsight',
  initialState,
  reducers: {
    updateStats(state, action) {
      const {
        payload: { series },
      } = action;
      state.series = series;
      state.firstLoading = false;
      state.loading = false;
    },
    resetTable(state, action) {
      state.tableLoading = true;
      state.tableData = [];
      state.tablePage = 1;
      state.tableTotal = 0;
    },
    updateTable(state, action) {
      const {
        payload: { tableData, tableTotal },
      } = action;
      state.tableData = tableData;
      state.tableLoading = false;
      state.tableTotal = tableTotal;
    },
    setLoading(state, action) {
      state.loading = true;
      state.tableLoading = true;
    },
    setTableLoading(state, action) {
      state.tableLoading = true;
    },
    updateFilter(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.filters[id] = value;
    },
    updateTableFilter(state, action) {
      const {
        payload: { column, order },
      } = action;
      if (!order) {
        state.tableFilter = { column: '', order: '' };
      } else {
        state.tableFilter = { column, order };
      }
    },
    updateTablePage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tablePage = page;
    },
    updateSeries(state, action) {
      const {
        payload: { series },
      } = action;
      state.last48Series = series;
    },
  },
});

export const {
  updateStats,
  resetTable,
  updateTable,
  setLoading,
  setTableLoading,
  updateFilter,
  updateTableFilter,
  updateTablePage,
  updateSeries,
} = AnalyzeTopicsInsightSlice.actions;

const fetchData = () => async (dispatch, getState) => {
  const mockTableData = [
    {
      key: 1,
      topic: 'investors',
      rank: 91,
      volume: 20,
      avgPageviews: { up: true, value: 138.7 },
      avgOcurrences: 5.67,
      posOrNegWords: 50,
      emotion: 32,
      series: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    },
    {
      key: 2,
      topic: 'portfolio',
      rank: 87,
      volume: 13,
      avgPageviews: { up: true, value: 165.69 },
      avgOcurrences: 4.21,
      posOrNegWords: 55,
      emotion: 78,
      series: [40934, 49503, 54177, 66658, 94031, 89931, 107133, 124175],
    },
    {
      key: 3,
      topic: 'market',
      rank: 85,
      volume: 12,
      avgPageviews: { up: false, value: 224.61 },
      avgOcurrences: 6.92,
      posOrNegWords: 21,
      emotion: 12,
      series: [42934, 51503, 56177, 68658, 96031, 109931, 127133, 144175],
    },
    {
      key: 4,
      topic: 'risk',
      rank: 79,
      volume: 13,
      avgPageviews: { up: true, value: 205.75 },
      avgOcurrences: 7.43,
      posOrNegWords: 94,
      emotion: 90,
      series: [41934, 50503, 57177, 67658, 95031, 99931, 117133, 134175],
    },
    {
      key: 5,
      topic: 'cio',
      rank: 78,
      volume: 12,
      avgPageviews: { up: true, value: 222.16 },
      avgOcurrences: 2.87,
      posOrNegWords: 67,
      emotion: 34,
      series: [38934, 47503, 52177, 64658, 92031, 69931, 87133, 74175],
    },
  ];

  const resp = {
    series: [
      {
        name: 'Invest',
        data: [
          { x: 73.5, y: 83.1, z: 10, name: 'NO', country: 'Norway' },
          { x: 71, y: 93.2, z: 24.7, name: 'UK', country: 'United Kingdom' },
          { x: 65.5, y: 126.4, z: 35.3, name: 'US', country: 'United States' },
        ],
      },
      {
        name: 'Assets',
        data: [
          { x: 95, y: 95, z: 13.8, name: 'BE', country: 'Belgium' },
          { x: 86.5, y: 102.9, z: 14.7, name: 'DE', country: 'Germany' },
          { x: 80.8, y: 91.5, z: 15.8, name: 'FI', country: 'Finland' },
          { x: 80.4, y: 102.5, z: 12, name: 'NL', country: 'Netherlands' },
        ],
      },
      {
        name: 'Stop',
        data: [
          { x: 69.2, y: 57.6, z: 10.4, name: 'IT', country: 'Italy' },
          { x: 68.6, y: 20, z: 16, name: 'RU', country: 'Russia' },
          { x: 65.4, y: 50.8, z: 28.5, name: 'HU', country: 'Hungary' },
          { x: 63.4, y: 51.8, z: 15.4, name: 'PT', country: 'Portugal' },
        ],
      },
      {
        name: 'Optimized',
        data: [
          { x: 78.4, y: 70.1, z: 16.6, name: 'ES', country: 'Spain' },
          { x: 77, y: 68.5, z: 14.5, name: 'FR', country: 'France' },
        ],
      },
    ],
    tableData: mockTableData,
    tableTotal: 5,
  };
  // TODO make api request here
  dispatch(updateStats({ series: resp.series }));
  dispatch(
    updateTable({ tableData: resp.tableData, tableTotal: resp.tableTotal })
  );
};

export const fetchStats = () => async dispatch => {
  dispatch(setLoading());
  dispatch(resetTable());
  dispatch(fetchData());
};

export const fetchTable = (column, order, page) => async dispatch => {
  // dispatch(updateTableFilter({ column, order }));
  dispatch(updateTablePage({ page }));
  dispatch(setTableLoading());
  dispatch(fetchData());
};

export default AnalyzeTopicsInsightSlice.reducer;
