import _ from 'lodash';
import {
  ERROR404,
  ACCOUNT_UPGRADE,
  ACCOUNT_EXPIRED,
  CHARGIFY_CALLBACK,
} from '../../constants/internalUrls';
import ApiClient from '../../util/api_client';
import { API_HOST } from '../../constants/apiUrls';

const exemptedPathNames = [ACCOUNT_UPGRADE, ACCOUNT_EXPIRED, CHARGIFY_CALLBACK];

export function display404() {
  window.location.pathname = ERROR404;
}

export function displayAccountExpiredScreen() {
  if (!exemptedPathNames.includes(window.location.pathname))
    window.location.pathname = ACCOUNT_EXPIRED;
}

export function displayAccountUpgradeScreen() {
  if (!exemptedPathNames.includes(window.location.pathname))
    window.location.pathname = ACCOUNT_UPGRADE;
}

export function isTrialExpired(accountInfo) {
  let expired = false;
  if (accountInfo.expiration !== null) {
    const d1 = new Date();
    const d2 = new Date(accountInfo.expiration);
    expired = d1.getTime() > d2.getTime();
  }

  return accountInfo.accountTypeId < 3 || expired;
}

export function isTrialActive(accountInfo) {
  return (
    parseInt(accountInfo.accountTypeId) >= 3 &&
    accountInfo.expiration !== null &&
    accountInfo.hasActiveSubscription === false
  );
}

export function isPayingClient(accountInfo) {
  return (
    parseInt(accountInfo.accountTypeId) >= 3 &&
    accountInfo.hasActiveSubscription === true
  );
}

export function extractedBlogData(record) {
  let isDraft = false;
  let postId = record.id;
  let { intType } = record;
  if (
    record.intType == null ||
    record.intType === 'AR' ||
    record.intType.includes('Custom')
  ) {
    intType = 'AR';
  } else {
    postId = record.intId;
  }

  if (
    (record.intType === 'AR' && record.status === 'DRAFT') ||
    record.status === 'ARCHIVED'
  ) {
    isDraft = true;
  }

  return {
    isDraft,
    postId,
    intType,
  };
}

export function hasAccessToProfiles(accountInfo) {
  const { accountTypeId } = accountInfo;
  // if (hasActiveSubscription && parseInt(accountTypeId) >= 5) {
  // Some accounts are updated manually and has no active chargify subs.
  if (parseInt(accountTypeId) >= 5) {
    return true;
  }

  return false;
}

export function hasAccessToDashboard(accountInfo) {
  const { accountTypeId } = accountInfo;
  if (parseInt(accountTypeId) >= 4) {
    return true;
  }

  return false;
}

export function setHeaders(configData) {
  const doc = document;
  doc.title = configData.companyName;

  let link = doc.querySelector("link[rel~='icon']");
  if (!link) {
    link = doc.createElement('link');
    link.rel = 'icon';
    doc.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = configData.favIconPath;
}

export function addIfNotExistsArray(arr, val) {
  arr.indexOf(val) >= 0 ? _.pull(arr, val) : arr.push(val);
  return arr;
}

export function getArClient() {
  if (!localStorage.session) {
    return null;
  }

  const session = JSON.parse(localStorage.session);
  const consumerKey = session.consumer_key;
  const consumerSecret = session.consumer_secret;

  return ApiClient.init(API_HOST, consumerKey, consumerSecret, () => {});
}

export function setParentSession(session) {
  localStorage.setItem('parentSession', session);
}

export function getParentSession() {
  return JSON.parse(localStorage.getItem('parentSession') || null);
}

export function removeParentSession() {
  localStorage.removeItem('parentSession');
}

export function stringToDownloadCSVFile(fileName, fileContent) {
  const blob = new Blob([fileContent], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // In case of IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
