export const KPIS = {
  '00': 'Select KPI',
  '1': 'KPI 1',
  '2': 'KPI 2',
  '3': 'KPI 3',
};
export const DEFAULT_KPI = 'GA_pageviews';
export const TABLE_PAGE_SIZE = 10;
export const CONTENT_TYPES = [
  {
    id: 'Article',
    name: 'Article',
    editorUrlId: 'blogs',
  },
  {
    id: 'Email',
    name: 'Email',
    editorUrlId: 'emails',
  },
];
export const DEFAULT_CONTENT_TYPE = 'Article';
