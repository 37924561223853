import {
  UPDATE_INITIAL_DEMO,
  UPDATE_OPTIMIZED_DEMO,
  UPDATE_DEMO_PROFILES,
  SELECT_DEMO_PROFILE,
  UPDATE_ACTIVE_TOKEN_INDEX,
  TRY_OPTIMIZE_DEMO,
  OPTIMIZE_DEMO_DONE,
} from '../constants/actionTypes';

const initialState = {
  editorContent: '',
  optimizedEditorContent: [],
  optimizingContent: false,
  profiles: [],
  profileSelected: {},
  activeTokenIndex: null,
};

function demoApp(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INITIAL_DEMO:
      return {
        ...state,
        editorContent: action.content,
      };
    case UPDATE_OPTIMIZED_DEMO:
      return {
        ...state,
        optimizedEditorContent: action.optimizedContent,
      };
    case UPDATE_DEMO_PROFILES:
      return {
        ...state,
        profiles: action.profiles.slice(),
      };
    case SELECT_DEMO_PROFILE:
      return {
        ...state,
        profileSelected: Object.assign({}, action.profile),
      };
    case UPDATE_ACTIVE_TOKEN_INDEX:
      return {
        ...state,
        activeTokenIndex: action.index,
      };
    case TRY_OPTIMIZE_DEMO:
      return {
        ...state,
        optimizingContent: true,
      };
    case OPTIMIZE_DEMO_DONE:
      return {
        ...state,
        optimizingContent: false,
      };
    default:
      return state;
  }
}

export default demoApp;
