export const CONTENT_TYPES = ['Articles', 'Emails', 'Ads'];
export const CONTENT_TYPES_STATUS = ['All', 'Published', 'Drafts'];

export const DEFAULT_CONTENT_TYPE = 'Articles';
export const DEFAULT_CONTENT_TYPES_STATUS = 'All';

export const DEFAULT_TABLE_PAGE_LIMIT = 10;

//API ENDPOINTS
export const API_OPTIMIZE_GET_CONTENT = '/optimize/getContent';
