import moment from 'moment';

import { TABLE_PAGE_SIZE } from './constants';
import {
  ANALYZE_GET_CONTENT,
  PROFILE_DATA_CLIENT_LIGHT_URL,
  PROFILE_DATA_CLIENT_LITE_URL,
  PROFILE_DATA_CLIENT_URL,
} from '../../../constants/apiUrls';
import { CONTENT_TYPES, DEFAULT_CONTENT_TYPE } from '../common/constants';
import { OK } from '../../../constants/platformStatusCodes';
import { getGraphLabels } from './RowTableUtil';
import arClient from '../../../util/api_client';
import { EDITOR_URL } from '../../../constants/internalUrls';
import { EMAIL_GENERIC_TYPE } from '../../../constants/internalConsts';

const getRowCellData = (columnNames, engagementData, n) => {
  const idx = columnNames[n];
  return engagementData[idx];
};

const buildEditorArticleUrl = (contentType, row) => {
  let { integrationType = 'AR', id: postId } = row;
  const { integrationPostId, status } = row;
  let isDraft = false;
  if (
    integrationType === null ||
    integrationType === 'AR' ||
    integrationType.includes('Custom')
  ) {
    integrationType = 'AR';
  } else {
    postId = integrationPostId;
  }
  if (
    (integrationType === 'AR' && status === 'DRAFT') ||
    status === 'ARCHIVED'
  ) {
    isDraft = true;
  }

  const obj = CONTENT_TYPES.find(el => el.id === contentType);

  const { protocol, host } = window.location;
  let editorUrl = `${protocol}//${host}${EDITOR_URL.replace(
    ':contentType',
    obj.editorUrlId
  )}`
    .replace(':contentId?', postId)
    .replace(':integrationType?', integrationType);
  if (isDraft) {
    editorUrl = `${editorUrl}?drafts`;
  }

  return editorUrl;
};

const buildEditorEmailUrl = (contentType, row) => {
  const {
    integrationType = 'HUBSPOT',
    id,
    integrationEmailId,
    isDraft,
    isArchived,
  } = row;
  const obj = CONTENT_TYPES.find(el => el.id === contentType);
  const { protocol, host } = window.location;
  const contentId =
    integrationType === EMAIL_GENERIC_TYPE ? id : integrationEmailId;
  let editorUrl = `${protocol}//${host}${EDITOR_URL.replace(
    ':contentType',
    obj.editorUrlId
  )}`
    .replace(':contentId?', contentId)
    .replace(':integrationType?', integrationType);

  if (integrationType === 'AR' && (isDraft === true || isArchived === true)) {
    editorUrl = `${editorUrl}?drafts`;
  }

  return editorUrl;
};

export const buildTableData = (apiData, contentType) => {
  const {
    contentItems,
    contentTableHeaders: { columnNames },
  } = apiData;
  const tableData = contentItems.map(row => {
    const {
      id,
      engagementData,
      trend,
      profileMatch,
      trendData,
      readability,
      arousal,
      wordsCount,
      topics,
      percentageParagraphsIdealWordCount,
      compareNowData,
      comparePrevData,
    } = row;

    const editorUrl =
      contentType === 'Article'
        ? buildEditorArticleUrl(contentType, row)
        : buildEditorEmailUrl(contentType, row);

    return {
      key: id,
      title: getRowCellData(columnNames, engagementData, 0),
      attention: getRowCellData(columnNames, engagementData, 1),
      leads: {
        value: getRowCellData(columnNames, engagementData, 2),
        up: true, // TODO set 'up' real value
      },
      conversion_rate: `${getRowCellData(columnNames, engagementData, 3)}%`,
      pageviews: {
        value: getRowCellData(columnNames, engagementData, 4),
        up: trend === '+',
      },
      url: engagementData.URL,
      editorUrl,
      trend: {
        labels: getGraphLabels(1, 14),
        datasets: [
          {
            label: 'Orders',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: profileMatch ? '#1fb7e4' : '#faa84f',
            borderDash: [0, 0],
            data: trendData.map(elem => parseInt(elem, 10)),
          },
        ],
      },
      expandedDetails: {
        stats: {
          url: engagementData.URL,
          profileMatch,
          readabilityMatch: readability,
          emotion: arousal,
          wordCount: wordsCount,
          topics: topics.join(', '),
          perIdealWordCount: percentageParagraphsIdealWordCount,
        },
        trend: {
          labels: getGraphLabels(1, 30),
          datasets: [
            {
              label: 'Now',
              lineTension: 0,
              fill: true,
              backgroundColor: 'transparent',
              borderColor: '#1fb7e4',
              data: compareNowData.map(elem => parseInt(elem, 10)),
            },
            {
              label: 'Previous',
              lineTension: 0,
              fill: true,
              backgroundColor: 'transparent',
              borderColor: '#fab15d',
              data: comparePrevData.map(elem => parseInt(elem, 10)),
            },
          ],
        },
      },
    };
  });
  return tableData;
};

export const requestApiProfiles = contentType =>
  new Promise((resolve, reject) => {
    // Callback executed on API response
    const cb = respApi => {
      const { data, error, status: respStatus } = respApi;
      if (respStatus !== OK) {
        console.log('requestApiProfiles error: ', error);
        reject(error);
      } else {
        // Be aware to store ids as strings to make easier comparison
        // with filters defined in the url query string
        const profiles = data
          .filter(({ status }) => status !== 'archived')
          .map(({ id, name }) => ({ id: `${id}`, name }));
        resolve(profiles);
      }
    };

    arClient.doApiCall(PROFILE_DATA_CLIENT_LITE_URL, { type: contentType }, cb);
  });

export const requestApiStats = (urlFilters, tableOnly = false) =>
  new Promise((resolve, reject) => {
    // Callback executed on API response
    const cb = respApi => {
      const { data, error, status: respStatus } = respApi;
      if (respStatus !== OK) {
        console.log('requestApiStats error: ', error);
        reject(error);
      } else {
        resolve(data);
      }
    };

    const apiParams = {
      profileId: urlFilters.profile,
      contentType: urlFilters.contentType,
      offset: TABLE_PAGE_SIZE * (urlFilters.page - 1),
      limit: TABLE_PAGE_SIZE,
      contentOnly: tableOnly ? 1 : 0,
    };
    if (urlFilters.kpi !== null && urlFilters.kpi !== '0') {
      apiParams.selectedKPI = urlFilters.kpi;
    }
    if (urlFilters.date) {
      const [startDate, endDate] = urlFilters.date
        .split('-')
        .map(elem => moment(elem, 'x').format('YYYY-MM-DD'));
      apiParams.startDate = startDate;
      apiParams.endDate = endDate;
    }

    arClient.doApiCall(ANALYZE_GET_CONTENT, apiParams, cb);
  });

export const getContentTypeFromUrl = searchParams => {
  const contentType = searchParams.get('contentType');
  if (!contentType || !CONTENT_TYPES.find(elem => elem.id === contentType)) {
    return DEFAULT_CONTENT_TYPE;
  }
  return contentType;
};

export const getDateFilterFromUrl = searchParams => {
  const urlDate = searchParams.get('date') || '-';
  const dateTokens = urlDate.split('-').filter(elem => /^\d+$/.test(elem));
  if (dateTokens.length === 2) {
    const moment0 = moment(dateTokens[0], 'x');
    const moment1 = moment(dateTokens[1], 'x');
    if (moment0.isBefore(moment1)) {
      return urlDate;
    }
  }
  return null;
};

export const getPageFromUrl = searchParams => {
  const page = searchParams.get('page');
  if (page && /^\d+$/.test(page)) {
    return page;
  }
  return 1;
};

export const getProfileFromUrl = (searchParams, profiles) => {
  if (!profiles || profiles.length === 0) {
    return null;
  }
  const profile = searchParams.get('profile');
  if (profile && profiles.find(elem => elem.id === profile)) {
    return profile;
  }
  return profiles[0].id;
};

export const formatTimeOnPage = duration => {
  const value = typeof duration === 'object' ? duration.value : duration;
  const isNeg = duration < 0 ? '-' : '';
  return `${isNeg}${moment()
    .startOf('day')
    .seconds(Math.abs(value))
    .format('mm:ss')}`;
};
