/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  TOPICS_RELATED_CLUSTERS_URL,
  TOPICS_RELATED_CLUSTERS_URL_V2,
    ENGAGEMENT_WORDS_URL,
} from '../../constants/apiUrls';
import arClient from '../../util/api_client';
import { OK } from '../../constants/platformStatusCodes';

export const MY_TABLE_SIZE = 500;

const initialState = {
  error: '',
  tableTopics: {
    activeRowByTopicId: null,
    indexes: [],
    apiData: {},
    page: 1,
    pageContent: [],
    loading: true,
    total: 0,
    headers: [],
  },
  tableClusters: {
    indexes: [],
    apiData: {},
    page: 1,
    pageContent: [],
    loading: true,
    total: 0,
    headers: [],
  },
  contentItems: {},
  contentItemHeaders: [],
  subTopic: {
    showTopRow: false,
    topicKey: null,
  },
  filters: {
    contentType: 'Article',
  },
};

const EngagementWordsSlice = createSlice({
  name: 'EngagementWords',
  initialState,
  reducers: {
    setAll(state, action) {
      const {
        payload: {
          topics,
          clusters,
          contentItems,
          topicsIndexes,
          clusterIndexes,
          topicsPageContent,
          clustersPageContent,
          topicHeaders,
          clusterHeaders,
          contentItemHeaders,
          error,
          tableLoading,
        },
      } = action;
      state.contentItems = contentItems;
      state.contentItemHeaders = contentItemHeaders;
      state.error = error;
      state.tableTopics = {
        indexes: topicsIndexes,
        apiData: topics,
        page: 1,
        pageContent: topicsPageContent,
        loading: tableLoading,
        total: topicsIndexes.length,
        headers: topicHeaders,
      };
      state.tableClusters = {
        indexes: clusterIndexes,
        apiData: clusters,
        page: 1,
        pageContent: clustersPageContent,
        loading: tableLoading,
        total: clusterIndexes.length,
        headers: clusterHeaders,
      };
    },
    setTopicsLoadPage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tableTopics.loading = true;
      state.tableTopics.page = page;
    },
    setTopicsPageContent(state, action) {
      const {
        payload: { pageContent },
      } = action;
      state.tableTopics.loading = false;
      state.tableTopics.pageContent = pageContent;
    },
    setClustersLoadPage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tableClusters.loading = true;
      state.tableClusters.page = page;
    },
    setClustersPageContent(state, action) {
      const {
        payload: { pageContent },
      } = action;
      state.tableClusters.loading = false;
      state.tableClusters.pageContent = pageContent;
    },
    setTopicTableRowActive(state, action) {
      const {
        payload: { activeRowByTopicId },
      } = action;
      state.tableTopics.activeRowByTopicId = activeRowByTopicId;
    },
    setSubTopic(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.subTopic[id] = value;
    },
    updateFilter(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.filters[id] = value;
    },
  },
});

export const {
  setAll,
  setTopicsLoadPage,
  setTopicsPageContent,
  setClustersLoadPage,
  setClustersPageContent,
  setSubTopic,
  setTopicTableRowActive,
  updateFilter,
} = EngagementWordsSlice.actions;

export default EngagementWordsSlice.reducer;

// ================================================

const compareKeys = (a, b) => {
  const nA = parseInt(a.substr(2), 10);
  const nB = parseInt(b.substr(2), 10);
  return nA - nB;
};

const buildPageContent = (rawApiData, indexes, page) => {
  const start = MY_TABLE_SIZE * (page - 1);
  const end = start + MY_TABLE_SIZE;
  const content = [];
  for (let i = start; i < indexes.length && i < end; i += 1) {
    const key = indexes[i];
    content.push({ key, ...rawApiData[key] });
  }
  return content;
};
export const resetAll = () => dispatch => {
  dispatch(
    setAll({
      topics: {},
      clusters: {},
      contentItems: {},
      topicsIndexes: [],
      clusterIndexes: [],
      topicsPageContent: [],
      clustersPageContent: [],
      topicHeaders: [],
      clusterHeaders: [],
      contentItemHeaders: [],
      error: '',
      activeRowByTopicId: null,
      tableLoading: true,
    })
  );
};

export const requestApiTopicsClusters = getState =>
  new Promise((resolve, reject) => {
    const {
      EngagementWords: {
        filters: { contentType },
      },
    } = getState();

    // Callback executed on API response
    const cb = respApi => {
      if (respApi.status !== OK) {
        console.error('requestApiEng Words error: ', respApi.error);
        reject(respApi.error);
      } else {
        const {
          data: { topics, clusters, contentItems, headers },
        } = respApi;
        const topicsIndexes = Object.keys(topics).sort(compareKeys);
        const clusterIndexes = Object.keys(clusters).sort(compareKeys);
        const topicHeaders = headers.topics;
        const clusterHeaders = headers.clusters;
        const contentItemHeaders = headers.contentItems;
        const topicsPageContent = buildPageContent(topics, topicsIndexes, 1);
        const clustersPageContent = buildPageContent(
          clusters,
          clusterIndexes,
          1
        );
        resolve({
          topics,
          clusters,
          contentItems,
          topicsIndexes,
          clusterIndexes,
          topicHeaders,
          clusterHeaders,
          contentItemHeaders,
          topicsPageContent,
          clustersPageContent,
        });
      }
    };
    arClient.doApiCall(ENGAGEMENT_WORDS_URL, { contentType }, cb);
  });

export const initEngagementWords = () => async (dispatch, getState) => {
  try {
    const {
      topics,
      clusters,
      contentItems,
      topicsIndexes,
      clusterIndexes,
      topicHeaders,
      clusterHeaders,
      contentItemHeaders,
      topicsPageContent,
      clustersPageContent,
    } = await requestApiTopicsClusters(getState);
    dispatch(
      setAll({
        topics,
        clusters,
        contentItems,
        topicsIndexes,
        clusterIndexes,
        topicHeaders,
        clusterHeaders,
        contentItemHeaders,
        topicsPageContent,
        clustersPageContent,
        error: '',
        activeRowByTopicId: null,
        tableLoading: false,
      })
    );
  } catch (error) {
    // TODO show error on UI
    dispatch(
      setAll({
        topics: {},
        clusters: {},
        contentItems: {},
        topicsIndexes: [],
        clusterIndexes: [],
        topicsPageContent: [],
        topicHeaders: [],
        clusterHeaders: [],
        contentItemHeaders: [],
        clustersPageContent: [],
        error: error.message,
        activeRowByTopicId: null,
        tableLoading: false,
      })
    );
  }
};

export const getTopicsPage = page => (dispatch, getState) => {
  const {
    EngagementWords: {
      tableTopics: { indexes, apiData },
    },
  } = getState();
  dispatch(setTopicsLoadPage({ loading: true, page }));
  const content = buildPageContent(apiData, indexes, page);
  dispatch(setTopicsPageContent({ pageContent: content }));
};

export const getClustersPage = page => (dispatch, getState) => {
  const {
    EngagementWords: {
      tableClusters: { indexes, apiData },
    },
  } = getState();
  dispatch(setClustersLoadPage({ loading: true, page }));
  const content = buildPageContent(apiData, indexes, page);
  dispatch(setClustersPageContent({ pageContent: content }));
};
