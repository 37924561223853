import { createSlice } from '@reduxjs/toolkit';
import arClient from '../../util/api_client';
import { getBase64 } from './static/utils/encodeImageFileAsURL';

const initialState = {
  mediaList: [],
  mediaListLoading: true,
  mediaFeedback: {
    id: '',
    type: '',
    path: '',
    thumbImgURL: '',
    imageURL: '',
    createdTimestamp: '',
    personaRequestId: null,
    segmentId: null,
    respData: {
      meta: {
        dataPath: '',
        dataFormat: '',
        dataExtension: '',
        dataLoaded: false,
      },
      analysis: {
        imageTopics: {},
        imageEmotions: {},
        imageSentiment: '',
        imageQuality: '',
        imageClarity: '',
        imageMemorability: '',
        imageCrowdedness: 0,
        imageMediaSegments: {
          imageInstagram: {
            label: '',
            viralPotential: '',
          },
          imageTwitter: {
            label: '',
            viralPotential: '',
          },
          imageFacebook: {
            label: '',
            viralPotential: '',
          },
          imageTiktok: {
            label: '',
            viralPotential: '',
          },
          imageYoutube: {
            label: '',
            viralPotential: '',
          },
        },
        radScore: 0,
      },
    },
  },
  personaName: '',
  mediaFeedbackLoading: false,
};

const MediaRankingSlice = createSlice({
  name: 'MediaRanking',
  initialState,
  reducers: {
    setMediaFeedback(state, action) {
      const { payload } = action;
      const respData = payload?.respData ? JSON.parse(payload.respData) : null;

      state.mediaFeedback = {
        ...payload,
        respData,
      };

      state.personaName = payload?.personaName || [];
    },

    setMediaFeedbackLoading(state, action) {
      const { payload } = action;
      state.mediaFeedbackLoading = payload;
    },

    setMediaList(state, action) {
      const { payload } = action;
      state.mediaList = payload;
    },

    setMediaListLoading(state, action) {
      const { payload } = action;
      state.mediaListLoading = payload;
    },

    deleteMediaFeedbackSuccess(state, action) {
      const deletedMediaIds = action.payload;
      state.mediaList = state.mediaList.filter(
        media => !deletedMediaIds.includes(media.id)
      );
    },
  },
});

export const {
  setMediaFeedback,
  setMediaFeedbackLoading,
  setMediaList,
  setMediaListLoading,
  deleteMediaFeedbackSuccess,
} = MediaRankingSlice.actions;

export const getMediaFeedback = (mediaId = 0) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(
        setMediaFeedback({ ...resp.data, personaName: resp.personaName })
      );
      dispatch(setMediaFeedbackLoading(false));
    }
  };

  dispatch(setMediaFeedbackLoading(true));
  arClient.doApiCall('/media-model/fetchByMediaId', { mediaId }, callback);
};

export const getMediaList = () => async dispatch => {
  const callback = resp => {
    if (resp.status === 10 && resp.data) {
      dispatch(setMediaList(resp.data));
    }
    dispatch(setMediaListLoading(false));
  };

  arClient.doApiCall('/media-model/fetch', {}, callback);
};

export const bulkUploadMedia = (
  requestId,
  segmentId,
  files
) => async dispatch => {
  return new Promise(resolve => {
    const responses = [];
    let resolved = 0;
    files.forEach(async (item, idx) => {
      const { name, URLFile, type, thumbnailURL, thumbImgName } = item;

      const payload = {
        name, // File name must be unique.
        type,
        dataURL: URLFile,
        thumbImg: thumbnailURL,
        thumbImgName,
        s3Enabled: '1',
      };

      // make a call for each media
      arClient.doApiCall(
        '/media-model/upload',
        {
          requestId,
          segmentId,
          uploadObj: JSON.stringify([payload]),
        },
        resp => {
          responses[idx] = resp;
          resolved += 1;
          // all media has been uploaded
          if (resolved === files.length) {
            const allFulfilled = responses.every(
              response => response.status === 10
            );
            resolve({
              status: allFulfilled ? 10 : 20,
            });
          }
        }
      );
    });
  });
};

export const deleteMediaFeedback = mediaIdArray => async dispatch => {
  try {
    // Check if mediaIdArray is an array, and if not, convert it to an array
    if (!Array.isArray(mediaIdArray)) {
      mediaIdArray = [mediaIdArray];
    }
    const callback = resp => {
      if (resp.status === 10) {
        dispatch(deleteMediaFeedbackSuccess(mediaIdArray));
      }
    };
    const convertedMediaIdArray = mediaIdArray.map(Number);

    arClient.doApiCall(
      '/media-model/delete',
      { mediaIdArray: JSON.stringify(convertedMediaIdArray) },
      callback
    );
  } catch (error) {
    return error;
  }
};

export default MediaRankingSlice.reducer;
// Test Push v2.1
