import React from 'react';
import PropTypes from 'prop-types';
import LogoSpinner from '../../common/LogoSpinner';

const Loader = ({ withContainer, message = '' }) => {
  const LoaderComponent = (
    <div className="d-flex justify-content-center">
      <LogoSpinner loadingMessage={message} />
    </div>
  );

  if (withContainer) {
    return (
      <div className="container rounded container-shadow bg-white mb-5 px-0 py-5">
        {LoaderComponent}
      </div>
    );
  }

  return LoaderComponent;
};

Loader.propTypes = {
  withContainer: PropTypes.bool.isRequired,
};

export default Loader;
