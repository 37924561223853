import md5 from 'md5';
import $ from 'jquery';
import { TrackJS } from 'trackjs';
import swal from 'sweetalert';
import React from 'react';
import { notification } from 'antd';
import Cookies from './js.cookie';
import OAuth from './oauth';
import { API_HOST, ENV } from '../constants/apiUrls';
import { FORBIDDEN, OK } from '../constants/platformStatusCodes';
import { SETTINGS_BILLING_URL } from '../constants/internalUrls';

function arClient() {
  const DEBUG_MODE = false;

  const client = {};

  const showAlert = (errorMsg, type = 'error') => {
    if (ENV !== 'PRODUCTION') {
      notification[type]({
        message: 'API Failed!!',
        duration: 5,
        maxCount: 2,
        description: errorMsg,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }
  };

  const consumerConfiguration = {
    consumerKey: '',
    consumerSecret: '',
    serviceProvider: {
      signatureMethod: 'HMAC-SHA1',
      host: API_HOST,
      requestTokenURL: '/oauth/request-token',
      userAuthorizationURL: '/oauth/authorize',
      accessTokenURL: '/oauth/access-token',
      echoURL: '/api/echo',
      analyzeURL: '/post/analyze',
      postAddURL: '/post/add',
      getPosts: '/posts',
      addSourceURL: '/source/add',
      getAudienceListURL: '/source/get-audience-list',
      trackWordpressDataURL: '/wordpress/track-data',
      addDictionaryURL: '/dictionary/add',
      removeDictionaryURL: '/dictionary/remove',
      listDictionariesURL: '/dictionary/list',
      getTokens: '/api/get-tokens',
      createAccount: '/account/create',
      addWebsite: '/source/add',
      addSocialNetwork: '/account/add-social-network',
      getSocialNetworks: '/account/get-social-networks',
      removeNetwork: '/account/remove-account',
      sendFeedback: '/account/feedback',
      countPostAnalyzeCalls: '/account/count-post-analyze-calls',
      stats: '/account/stats',
      getAccountData: '/account/data',
      listSources: '/source/list',
      getMostEngagedSegment: '/engagement/get-most-engaged-segment',
      getAtomicScore: '/account/get-atomic-score',
      getAvgScore: '/account/avg-score',
      webProfiles: '/engagement/web-profiles',
      gaProfiles: '/account/ga-profiles',
      updateSource: '/source/update',
      getAudience: '/audience',
      getInsightsEngagement: '/insights/engagement',
      getInsightsMeasures: '/insights/measures',
      getInsightsMeasureDetails: '/insights/measure-details',
      getAuthors: '/author',
      postCheckdate: '/post/checkdate',
      identifyCms: '/api/identify',
      pageContents: '/source/page-contents',
      getEmail: '/account/email',
      analyzeText: '/analyze-text/master',
      analyzeTextV2: '/text-analyze-v2/taskListParagraph',
      flagWord: '/text-feedback/replaceWord',
    },
  };

  // re-using the access token speeds up loading on refresh but it may not be supported by all apps
  const reuseTokenCookie = true;

  let keys;
  let request_resp;
  let interval = 1500;
  let oauth_token;
  let oauth_token_secret;
  const STATUS_OK = 10;
  const INVALID_ACCESS_TOKEN = 21;
  let retriedReAuth = false;
  let clientAppName = 'API_JS_Client';

  client.sophisticationBandIdsArray = {
    Genius: 1,
    Academic: 2,
    Specialist: 3,
    Knowledgeable: 4,
    General: 5,
  };

  function getRequestToken(callback) {
    return doRequest(
      {
        method: 'GET',
        action:
          consumerConfiguration.serviceProvider.host +
          consumerConfiguration.serviceProvider.requestTokenURL,
        parameters: {
          oauth_signature_method:
            consumerConfiguration.serviceProvider.signatureMethod,
          oauth_consumer_key: consumerConfiguration.consumerKey,
        },
      },
      {
        consumerSecret: consumerConfiguration.consumerSecret,
        tokenSecret: '',
      },
      callback
    );
  }

  function getAuthorize(oauth_token, callback) {
    const URL =
      consumerConfiguration.serviceProvider.host +
      consumerConfiguration.serviceProvider.userAuthorizationURL;
    $.ajax({
      url: URL,
      method: 'GET',
      data: { oauth_token, oauth_callback: '' },
      crossDomain: true,
      success() {
        callback();
      },
    });
  }

  function getAccessToken(token, secret, callback) {
    return doRequest(
      {
        method: 'GET',
        action:
          consumerConfiguration.serviceProvider.host +
          consumerConfiguration.serviceProvider.accessTokenURL,
        parameters: {
          oauth_signature_method:
            consumerConfiguration.serviceProvider.signatureMethod,
          oauth_consumer_key: consumerConfiguration.consumerKey,
          oauth_token: token,
        },
      },
      {
        consumerSecret: consumerConfiguration.consumerSecret,
        tokenSecret: secret,
      },
      callback
    );
  }

  function doRequest(message, accessor, callback) {
    const { method } = message;
    const URL = message.action;

    if (DEBUG_MODE) {
      console.log('\nENDPOINT | ', URL);
    }

    const sendObj = message.parameters;
    const requestParams = {};

    if (message.hasOwnProperty('requestData')) {
      for (const property in message.requestData) {
        if (message.requestData.hasOwnProperty(property)) {
          requestParams[property] = message.requestData[property];
          sendObj[property] = message.requestData[property];
        }
      }
    }

    requestParams.environment = sendObj.environment = ENV;

    message.parameters = sendObj;

    // SIGN request
    if (typeof accessor.consumerSecret !== 'undefined') {
      OAuth.setTimestampAndNonce(message);
      OAuth.SignatureMethod.sign(message, accessor);

      if (DEBUG_MODE) {
        console.log('SIGNED | ', message);
      }
    }

    if (DEBUG_MODE) {
      console.log('sendObj: ', sendObj);
    }

    $.ajax({
      url: URL,
      method,
      data: sendObj,
      crossDomain: true,
      success(result) {
        if (DEBUG_MODE) {
          console.log('RESP: ', result);
        }

        if (result.status === INVALID_ACCESS_TOKEN) {
          if (reuseTokenCookie && !retriedReAuth) {
            if (DEBUG_MODE) {
              console.log('RE_AUTH', message.action, callback);
            }

            Cookies.remove('oauthToken');
            retriedReAuth = true;

            // get new access token
            setOauth(
              consumerConfiguration.consumerKey,
              consumerConfiguration.consumerSecret,
              callbackRequestRetry
            );

            function callbackRequestRetry() {
              // add new access token and request params / options
              message.parameters = buildOauthParameter(requestParams);

              // refresh accessor to validate token
              accessor = {
                consumerSecret: consumerConfiguration.consumerSecret,
                tokenSecret: oauth_token_secret,
              };

              doRequest(message, accessor, callback);
            }
          }
        } else {
          if (DEBUG_MODE) console.log('RESP: ', result);

          if (typeof callback === 'function') {
            callback(result);
          }
          if (result.status !== OK) {
            trackError(sendObj, result, method, URL);
          }

          const content =
            result.errorMsg !== null
              ? result.errorMsg
              : 'Your current' +
                ' subscription plan does not have access to this feature';

          // If the entire module needs to be locked.
          if (result.status === FORBIDDEN && result.pageLock === true) {
            swal(
              {
                title: 'UPGRADE',
                text: content,
                html: true,
                closeOnClickOutside: false,
                closeOnEsc: false,
                type: '',
                animation: 'slide-from-top',
                showCancelButton: true,
                cancelButtonText: 'Close',
                confirmButtonText: 'Upgrade',
              },
              function(val) {
                if (val) {
                  if (
                    window.location.pathname !==
                    decodeURIComponent(SETTINGS_BILLING_URL)
                  ) {
                    window.location = decodeURIComponent(SETTINGS_BILLING_URL);
                  }
                }
              }
            );
          }
        }
      },
      error(result) {
        console.error('!!!!!!ERROR!!!!!!!!!:', result);
        if (typeof callback === 'function') {
          callback(result);
        }
        trackError(sendObj, result, method, URL);
        showAlert('Something went wrong. Please reload');
      },
      async: true,
      timeout: 100000, // sets timeout to 100 seconds
    });
  }

  function getTokens(
    username,
    password,
    onSuccessCallbackFunc,
    onErrorCallbackFunc
  ) {
    doRequest(
      {
        method: 'POST',
        action:
          consumerConfiguration.serviceProvider.host +
          consumerConfiguration.serviceProvider.getTokens,
        parameters: {
          username: encodeURIComponent(username),
          password: encodeURIComponent(md5(password)),
        },
      },
      {},
      value => {
        if (value.status !== STATUS_OK) {
          console.log('getTokens() | ERROR: ', value);
        } else {
          if (DEBUG_MODE) console.log(value.data);

          keys = value.data;
          setOauth(
            keys.consumer_key,
            keys.consumer_secret,
            onSuccessCallbackFunc,
            onErrorCallbackFunc
          );
        }
      }
    );
  }

  function trackError(apiRequest, apiResult, method = '', url = '') {
    TrackJS.console.log({
      method,
      url,
      request: apiRequest,
      response: apiResult,
    });
    let resultStr = '';
    Object.keys(apiResult).forEach(key => {
      resultStr += `${key}: ${apiResult[key]} `;
    });
    TrackJS.track(resultStr);
  }

  client.identifyCms = function(cmsUrl, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.identifyCms,
            parameters: buildOauthParameter({ url: cmsUrl }),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.verifyGoogleId = function(googleId) {
    // getTokens("","",googleId);

    doRequest(
      {
        method: 'POST',
        action:
          consumerConfiguration.serviceProvider.host +
          consumerConfiguration.serviceProvider.getTokens,
        parameters: {
          username: '',
          password: '',
          google_id: googleId,
        },
      },
      {},
      value => {
        if (value.status !== STATUS_OK) {
          console.log(value);
        } else {
          if (DEBUG_MODE) console.log(value.data);

          keys = value.data;
          setOauth(keys.consumer_key, keys.consumer_secret);
        }
      }
    );
  };

  client.init = function(
    apiHost,
    consumerKey,
    consumerSecret,
    onSuccessCallbackFunc,
    onErrorCallbackFunc,
    username,
    password
  ) {
    let doGetToken = true;
    oauth_token = 'BOOTING';
    consumerConfiguration.serviceProvider.host = apiHost;

    // if username and pass
    if (typeof username !== 'undefined') {
      getTokens(username, password, onSuccessCallbackFunc, onErrorCallbackFunc);
      doGetToken = false;
    } else if (reuseTokenCookie) {
      // try to read from cookie
      const token = Cookies.getJSON('oauthToken');

      if (DEBUG_MODE) {
        console.log('Try | Re-use token [cookie]: ', token);
      }

      if (typeof token !== 'undefined') {
        consumerConfiguration.consumerKey = consumerKey;
        consumerConfiguration.consumerSecret = consumerSecret;
        oauth_token = token.oauthToken;
        oauth_token_secret = token.tokenSecret;

        if (tokenReady()) {
          if (DEBUG_MODE) {
            console.log(`\nACTIVE-A token | ${oauth_token}`);
          }

          doGetToken = false;
          onSuccessCallbackFunc();
        }
      }
    }

    if (doGetToken) {
      setOauth(
        consumerKey,
        consumerSecret,
        onSuccessCallbackFunc,
        onErrorCallbackFunc
      );
    }

    return this;
  };

  client.initV2 = function(
    consumerKey,
    consumerSecret,
    onSuccessCallbackFunc,
    onErrorCallbackFunc
  ) {
    oauth_token = 'BOOTING';
    setOauth(
      consumerKey,
      consumerSecret,
      onSuccessCallbackFunc,
      onErrorCallbackFunc
    );
  };

  function setOauth(
    consumerKey,
    consumerSecret,
    onSuccessCallbackFunc,
    onErrorCallbackFunc,
    p1,
    p2,
    p3
  ) {
    consumerConfiguration.consumerKey = consumerKey;
    consumerConfiguration.consumerSecret = consumerSecret;
    getRequestToken(aux => {
      if (typeof aux.responseText !== 'undefined') {
        if (typeof onErrorCallbackFunc !== 'undefined')
          onErrorCallbackFunc(aux);
        else console.log(aux.responseText);
      } else {
        request_resp = getJsonFromUrl(aux);
        getAuthorize(request_resp.oauth_token, function() {
          getAccessToken(
            request_resp.oauth_token,
            request_resp.oauth_token_secret,
            function(aux2) {
              const access_resp = getJsonFromUrl(aux2);
              /// //////////////////////////////////////
              oauth_token = access_resp.oauth_token;
              oauth_token_secret = access_resp.oauth_token_secret;

              if (DEBUG_MODE) console.log(`\nACTIVE-B token | ${oauth_token}`);

              // save to cookie for re-use
              if (reuseTokenCookie) {
                const token = {
                  oauthToken: oauth_token,
                  tokenSecret: oauth_token_secret,
                };

                if (DEBUG_MODE) console.log('Saving token [cookie]: ', token);

                Cookies.set('oauthToken', token, { expires: 1 });
              }

              if (typeof onSuccessCallbackFunc !== 'undefined')
                onSuccessCallbackFunc(p1, p2, p3);
            }
          );
        });
      }
    });
  }

  client.getEcho = function(msg, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.echoURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              echo: encodeURIComponent(msg),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.addPost = function(
    text,
    teaser,
    sourceId,
    segmentId,
    title,
    pubDate,
    url,
    callback
  ) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.postAddURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              text: encodeURIComponent(text),
              teaser: encodeURIComponent(teaser),
              sourceId: encodeURIComponent(sourceId),
              segmentId: encodeURIComponent(segmentId),
              title: encodeURIComponent(title),
              pubDate: encodeURIComponent(pubDate),
              url: encodeURIComponent(url),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.analyzePost = function(
    content,
    title,
    sophisticationBandId,
    callback
  ) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.analyzeURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              content: encodeURIComponent(content),
              title: encodeURIComponent(title),
              sophisticationBandId: encodeURIComponent(sophisticationBandId),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);

    if (window.location.href === 'http://atomictrainer.atomicreach.com/') {
      if (DEBUG_MODE) {
        console.log('atomicTrainer!');
      }

      setTimeout(() => {
        // $('body').load(chrome.extension.getURL("src/options/Editor.html"),function(){});
        // $("#edit").click(function(){
        $('body')
          .find('#edit')
          .trigger('click');
      }, 15000);
    }
  };

  client.countPostAnalyzeCalls = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.countPostAnalyzeCalls,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.checkdate = function(date, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.postCheckdate,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              date: encodeURIComponent(date),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.stats = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.stats,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.addSource = function(title, segmentDataJson, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.addSourceURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              title: encodeURIComponent(title),
              segmentDataJson: encodeURIComponent(segmentDataJson),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };
  client.updateSource = function(sourceId, options, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);

        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.updateSource,
            parameters: $.extend({}, options, {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              sourceId: encodeURIComponent(sourceId),
            }),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.pageContents = function(url, js, async, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);

        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.pageContents,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              url: encodeURIComponent(url),
              js: encodeURIComponent(js),
              async: encodeURIComponent(async),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getAudienceList = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getAudienceListURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.trackWordpressData = function(data, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.trackWordpressDataURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data,
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.addDictionary = function(word, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.addDictionaryURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              word: encodeURIComponent(word),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.removeDictionary = function(word, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.removeDictionaryURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              word: encodeURIComponent(word),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.listDictionaries = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.listDictionariesURL,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.setApiHost = function(apiHost) {
    consumerConfiguration.serviceProvider.host = apiHost;
  };

  client.getOauthTokens = function() {
    return {
      consumerKey: consumerConfiguration.consumerKey,
      consumerSecret: consumerConfiguration.consumerSecret,
    };
  };

  client.getAccountData = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getAccountData,
            parameters: buildOauthParameter(),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.createAccount = function(
    email,
    password,
    receive_newsletters,
    receive_product_updates,
    google_id,
    callback
  ) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.createAccount,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              email,
              password,
              receive_newsletters:
                typeof receive_newsletters === 'undefined' ? 0 : 1,
              receive_product_updates:
                typeof receive_product_updates === 'undefined' ? 0 : 1,
              google_id: typeof google_id === 'undefined' ? null : google_id,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
          // }else{
          // , callback
          // }
        );
      }
    }, interval);
  };
  client.addWebsite = function(
    url,
    sophisticationBandId,
    articleSelector,
    titleSelector,
    contentSelector,
    options,
    callback
  ) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);

        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.addWebsite,
            parameters: $.extend({}, options, {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              url: encodeURIComponent(url),
              sophisticationBandId: encodeURIComponent(sophisticationBandId),
              articleSelector: encodeURIComponent(articleSelector),
              titleSelector: encodeURIComponent(titleSelector),
              contentSelector: encodeURIComponent(contentSelector),
            }),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.listSources = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.listSources,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getMostEngagedSegment = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getMostEngagedSegment,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.webProfiles = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.webProfiles,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getAtomicScore = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getAtomicScore,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getAvgScore = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getAvgScore,
            parameters: buildOauthParameter({}),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getSocialNetworks = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getSocialNetworks,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };
  client.gaProfiles = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        // alert(consumerConfiguration.serviceProvider.gaProfiles);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.gaProfiles,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.addSocialNetwork = function(network_code) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.addSocialNetwork,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              networkCode: network_code,
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          function(value) {
            console.log(value);
            window.open(value.login, 'auth', 'width=500, height=500');
          }
        );
      }
    }, interval);
  };

  client.removeNetwork = function(username, code, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.removeNetwork,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              userName: encodeURIComponent(username),
              networkCode: encodeURIComponent(code),
              data: encodeURIComponent(''),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.sendFeedback = function(feedback, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.sendFeedback,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              feedback: encodeURIComponent(feedback),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.sendFeedback = function(feedback, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.sendFeedback,
            parameters: {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              feedback: encodeURIComponent(feedback),
            },
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getPosts = function(options, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getPosts,
            parameters: buildOauthParameter(options),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getInsightsMeasures = function(options, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getInsightsMeasures,
            parameters: buildOauthParameter(options),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getInsightsEngagement = function(options, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getInsightsEngagement,
            parameters: buildOauthParameter(options),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getInsightsMeasureDetails = function(
    measure,
    state,
    options,
    callback
  ) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getInsightsMeasureDetails,
            parameters: $.extend({}, options, {
              oauth_signature_method:
                consumerConfiguration.serviceProvider.signatureMethod,
              oauth_consumer_key: consumerConfiguration.consumerKey,
              oauth_token,
              measure: encodeURIComponent(measure),
              state: encodeURIComponent(state),
            }),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getAudience = function(options, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getAudience,
            parameters: buildOauthParameter(options),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getAuthors = function(sourceId, callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'GET',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getAuthors,
            parameters: buildOauthParameter(sourceId),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.getEmail = function(callback) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);
        return doRequest(
          {
            method: 'POST',
            action:
              consumerConfiguration.serviceProvider.host +
              consumerConfiguration.serviceProvider.getEmail,
            parameters: buildOauthParameter(),
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );
      }
    }, interval);
  };

  client.analyzeText = function(requestData, callback) {
    if (typeof requestData.sophisticationBandId === 'undefined') {
      requestData.sophisticationBandId =
        client.sophisticationBandIdsArray.Knowledgeable;
    }

    if (typeof requestData.serviceNamesArray === 'object') {
      requestData.serviceNamesArray = JSON.stringify(
        requestData.serviceNamesArray
      );
    }

    return client.doApiCall(
      consumerConfiguration.serviceProvider.analyzeText,
      requestData,
      callback
    );
  };

  client.analyzeTextV2 = function(requestData, callback) {
    // if (typeof requestData.sophisticationBandId==='undefined')
    //   {requestData["sophisticationBandId"] = client.sophisticationBandIdsArray["Knowledgeable"];}

    // if (typeof requestData.serviceNamesArray === 'object')
    //   {requestData["serviceNamesArray"] = JSON.stringify(requestData["serviceNamesArray"]);}

    return client.doApiCall(
      consumerConfiguration.serviceProvider.analyzeTextV2,
      requestData,
      callback
    );
  };

  client.flagWord = function(requestData, callback) {
    return client.doApiCall(
      consumerConfiguration.serviceProvider.flagWord,
      requestData,
      callback
    );
  };

  client.doApiCall = function(
    endPoint,
    requestData,
    callback,
    doEncodeURIComponent
  ) {
    var tokenCheck = setInterval(() => {
      if (tokenReady()) {
        clearInterval(tokenCheck);

        if (typeof doEncodeURIComponent === 'undefined')
          doEncodeURIComponent = true;

        let params = {};

        if (doEncodeURIComponent === false) params = requestData;
        else {
          for (const property in requestData) {
            if (requestData.hasOwnProperty(property)) {
              params[property] = encodeURIComponent(requestData[property]);
            }
          }
        }

        const response = doRequest(
          {
            method: 'POST',
            action: consumerConfiguration.serviceProvider.host + endPoint,
            parameters: buildOauthParameter(),
            requestData: params,
          },
          {
            consumerSecret: consumerConfiguration.consumerSecret,
            tokenSecret: oauth_token_secret,
          },
          callback
        );

        return response;
      }
    }, interval);
  };

  client.setDelayInterval = function(newInterval) {
    interval = newInterval;
  };

  client.setAppName = function(newAppName) {
    clientAppName = newAppName;
  };

  function buildOauthParameter(options) {
    const ret = {
      oauth_signature_method:
        consumerConfiguration.serviceProvider.signatureMethod,
      oauth_consumer_key: consumerConfiguration.consumerKey,
      oauth_token,
      app_name: clientAppName,
      data: encodeURIComponent(''),
    };

    for (const property in options) {
      if (options.hasOwnProperty(property)) {
        ret[property] = options[property];
      }
    }

    // console.log("buildOauthParameter()", ret);

    return ret;
  }

  function tokenReady() {
    let r = false;
    if (oauth_token !== 'BOOTING' && oauth_token !== '') r = true;

    return r;
  }

  function getJsonFromUrl(query) {
    // if(typeof query === 'string') return {};
    const data = query.split('&');
    const result = {};
    for (let i = 0; i < data.length; i++) {
      const item = data[i].split('=');
      result[item[0]] = item[1];
    }
    return result;
  }

  return client;
}

export default arClient();
