export function getBase64(file) {
  return new Promise((res, rej) => {
    try {
      const reader = new FileReader(); // asynchronously read the files stored on the user's computer 
      reader.readAsDataURL(file); // encoded as Base 64 URL string 
      reader.onload = () => res(reader.result);
      reader.onerror = err => rej(err);
    } catch (e) {
      rej(e.message);
    }
  });
}
