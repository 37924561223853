import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import Dropdown from 'react-bootstrap/Dropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import configData from '../../config/loader';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { DropdownTopbarItemToggler } from '../../_metronic/_partials/dropdowns';
import { AUTO_LOGIN_URL, LOGOUT_URL } from '../../constants/internalUrls';
import { getParentSession, removeParentSession } from '../Global/globalLib';

const UserBanner = styled.div`
  position: relative;
  overflow: hidden;
`;

export class TopBarUserInfo extends Component {
  constructor(props) {
    super(props);
    this.bgImage = toAbsoluteUrl('/media/misc/bg_wave_green_2.svg');

    this.onClickLogout = this.onClickLogout.bind(this);
  }

  onClickLogout(event) {
    event.preventDefault();
    const logOutURL = this.getLogoutURL();

    const { location } = window;

    if (location !== LOGOUT_URL) {
      removeParentSession();
    }

    location.replace(location.origin + encodeURI(logOutURL));
  }

  getLogoutText = () => {
    const parentSession = getParentSession();
    return parentSession === null ? 'Sign Out' : 'Back to parent account';
  };

  getLogoutURL = () => {
    const parentSession = getParentSession();

    return parentSession === null
      ? '/logout'
      : `${AUTO_LOGIN_URL}?c=${parentSession.consumer_key}&s=${parentSession.consumer_secret}`;
  };

  render() {
    const {
      accountInfo: { email },
    } = this.props;
    const { childEmail } = JSON.parse(localStorage.getItem('session'));
    return (
      <>
        <div className="topbar-item mr-4">
          <UserBanner className="user-banner">{childEmail || email}</UserBanner>
        </div>

        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_actions_panel_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="quick-actions-tooltip">Profile & Settings</Tooltip>
              }
            >
              <a
                href="/logout"
                className="btn btn-primary-light btn-topbar-square"
              >
                <span className="avatar-letter">S</span>
              </a>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md">
            <form>
              <div className="p-2 bg-white">
                <div className="row row-paddingless">
                  <div className="col-3 p-2 d-flex justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Profile & Billing</Tooltip>}
                    >
                      <a
                        href="/settings?to=profile"
                        className="btn btn-white btn-topbar-square"
                      >
                        <span className="svg-icon svg-icon-2x svg-icon-success">
                          <SVG
                            src={toAbsoluteUrl(
                              '/media/svg/icons/Shopping/Dollar.svg'
                            )}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="col-3 p-2 d-flex justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Integration</Tooltip>}
                    >
                      <a
                        href="/settings?to=integrations"
                        className="btn btn-white btn-topbar-square"
                      >
                        <span className="svg-icon svg-icon-2x svg-icon-success">
                          <SVG
                            src={toAbsoluteUrl(
                              '/media/svg/icons/Files/Folder-plus.svg'
                            )}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  {/* <div className="col-3 p-2 d-flex justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Word List</Tooltip>}
                    >
                      <a
                        href="/settings?to=wordlist"
                        className="btn btn-white btn-topbar-square"
                      >
                        <span className="svg-icon svg-icon-2x svg-icon-success">
                          <SVG
                            src={toAbsoluteUrl(
                              '/media/svg/icons/Text/Align-justify.svg'
                            )}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div> */}
                  <div className="col-3 p-2 d-flex justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Support</Tooltip>}
                    >
                      <a
                        href="https://www.radintel.ai/contact"
                        className="btn btn-white btn-topbar-square"
                      >
                        <span className="svg-icon svg-icon-2x svg-icon-success">
                          <SVG
                            src={toAbsoluteUrl(
                              '/media/svg/icons/Code/Question-circle.svg'
                            )}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>

        <div className="topbar-item ml-4">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="quick-actions-tooltip">
                {this.getLogoutText()}
              </Tooltip>
            }
          >
            <span className="cursor-pointer" onClick={this.onClickLogout}>
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Navigation/Sign-out.svg'
                  )}
                />
              </span>
            </span>
          </OverlayTrigger>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  accountInfo: state.settings.accountInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarUserInfo);

TopBarUserInfo.propTypes = {
  accountId: PropTypes.number,
  accountInfo: PropTypes.object,
};

TopBarUserInfo.defaultProps = {
  accountInfo: {},
  accountId: null,
};
