import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configData from './config/loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import './index.scss'; // Standard version

// import App from './components/App';
import store from './store';
import {
  MetronicLayoutProvider,
  MetronicSubheaderProvider,
} from './_metronic/layout';
import { setHeaders } from './components/Global/globalLib';
import Loader from './components/Analyze/common/Loader';

const App = lazy(() => import('./components/App'));
const DefaultStyling = lazy(() => import('./config/customStyles/defaultStyle'));
const RadStyling = lazy(() => import('./config/customStyles/radStyle'));

setHeaders(configData);

// eslint-disable-next-line react/prop-types
const StyleSelector = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {configData.style === 'rad' && <RadStyling />}
      {configData.style === 'default' && <DefaultStyling />}

      {children}
    </>
  );
};

render(
  <Suspense fallback={<></>}>
    <StyleSelector>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </StyleSelector>
  </Suspense>,
  document.getElementById('root')
);
