/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_CONTENT_TYPES_STATUS,
  DEFAULT_CONTENT_TYPE,
  API_OPTIMIZE_GET_CONTENT,
  DEFAULT_TABLE_PAGE_LIMIT,
} from './Constants';
import { TABLE_PAGE_SIZE } from '../../Analyze/Content/constants';
import arClient from '../../../util/api_client';

const initialState = {
  tableData: [],
  tableLoading: false,
  tableTotal: 0,
  filters: {
    status: DEFAULT_CONTENT_TYPES_STATUS,
    contentType: DEFAULT_CONTENT_TYPE,
    searchText: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
    tablePage: 1,
  },
  tableFilters: {
    column: '',
    order: '',
    page: 0,
    currentPage: 1,
    totalSize: 0,
  },
};

const OptimizeContentSlice = createSlice({
  name: 'OptimizeContent',
  initialState,
  reducers: {
    setTableLoading(state, action) {
      const { payload } = action;
      state.tableLoading = payload;
    },
    updateFilter(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.filters[id] = value;

      state.tablePage = 1;
      state.tableTotal = 0;
    },
    updateTableFilter(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.tableFilters[id] = value;
    },
    updateTableData(state, action) {
      const {
        payload: { data },
      } = action;
      state.tableData = data;
    },
    updateTablePage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tablePage = page;
    },
    updateTableTotal(state, action) {
      const {
        payload: { totalCount },
      } = action;
      state.tableTotal = totalCount;
    },
  },
});

export const {
  updateFilter,
  updateTableFilter,
  updateTableData,
  setTableLoading,
  updateTablePage,
  updateTableTotal,
} = OptimizeContentSlice.actions;

export const fetchContent = () => async (dispatch, getState) => {
  dispatch(setTableLoading(true));
  const {
    OptimizeContent: { filters },
  } = getState();

  const apiParams = {
    offset: (filters.tablePage - 1) * DEFAULT_TABLE_PAGE_LIMIT, // 570
    limit: DEFAULT_TABLE_PAGE_LIMIT,
    contentType: filters.contentType,
    status: filters.status,
    searchText: filters.searchText,
    sortBy: filters.sortBy,
    sortOrder: filters.sortOrder,
  };

  const callback = respApi => {
    dispatch(setTableLoading(false));
    if (respApi.status === 10) {
      dispatch(updateTableData({ data: respApi.data }));
      dispatch(updateTableTotal({ totalCount: respApi.totalCount }));
    }
  };

  arClient.doApiCall(API_OPTIMIZE_GET_CONTENT, apiParams, callback);
};

export default OptimizeContentSlice.reducer;
