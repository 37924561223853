import {
  GET_EMAILS_REQUEST,
  SET_EMAIL_PAGE,
  GET_EMAILMEASURES_REQUEST,
  GET_EMAILS_SUCCESS,
  GET_EMAILMEASURES_SUCCESS,
  GET_EMAILS_FAILURE,
  GET_EMAILMEASURES_FAILURE,
  EMAIL_LOADING_DONE,
  EMAILMEASURES_LOADING_DONE,
  MERGE_EMAIL_DRAFTS,
  RESET_EMAIL_DATA,
  GET_DRAFTS_REQUEST,
  GET_DRAFTS_SUCCESS,
  GET_PROFILE_CONTENT_REQUEST_EMAIL,
  GET_PROFILE_CONTENT_SUCCESS_EMAIL,
  GET_PROFILE_CONTENT_FAILURE_EMAIL,
  DELETE_PROFILES_REQUEST_EMAIL,
  DELETE_PROFILES_SUCCESS_EMAIL,
  DELETE_PROFILES_FAILURE_EMAIL,
  ACTIVATE_PROFILES_REQUEST_EMAIL,
  ACTIVATE_PROFILES_SUCCESS_EMAIL,
  ACTIVATE_PROFILES_FAILURE_EMAIL,
  CREATE_PROFILES_REQUEST_EMAIL,
  CREATE_PROFILES_SUCCESS_EMAIL,
  CREATE_PROFILES_FAILURE_EMAIL,
  ADDED_CONTENT_REQUEST_EMAIL,
  ADDED_CONTENT_SUCCESS_EMAIL,
  ADDED_CONTENT_FAILURE_EMAIL,
  REMOVE_CONTENT_REQUEST_EMAIL,
  REMOVE_CONTENT_SUCCESS_EMAIL,
  REMOVE_CONTENT_FAILURE_EMAIL,
  GET_PROFILES_REQUEST_EMAIL,
  GET_PROFILES_SUCCESS_EMAIL,
  GET_PROFILES_FAILURE_EMAIL,
  GET_PROFILE_MEASURES_REQUEST_EMAIL,
  GET_PROFILE_MEASURES_SUCCESS_EMAIL,
  GET_PROFILE_MEASURES_FAILURE_EMAIL,
  GET_PROFILE_MEASURES_DETAILS_REQUEST_EMAIL,
  GET_PROFILE_MEASURES_DETAILS_SUCCESS_EMAIL,
  GET_PROFILE_MEASURES_DETAILS_FAILURE_EMAIL,
  GET_PROFILES_BUCKET_CORPUS_REQUEST_EMAIL,
  GET_PROFILES_BUCKET_CORPUS_SUCCESS_EMAIL,
  GET_PROFILES_BUCKET_CORPUS_FAILURE_EMAIL,
  PROFILE_CALCULATE_REQUEST_EMAIL,
  PROFILE_CALCULATE_SUCCESS_EMAIL,
  PROFILE_CALCULATE_FAIL_EMAIL,
  UPDATE_PROFILE_NAME_EMAIL,
  DELETE_LOCAL_DRAFTS,
} from '../constants/actionTypes';

const initialState = {
  fetchingEmails: false,
  fetchingEmailMeasures: false,

  emails: [],
  emailMeasures: {},
  campaignsData: [],
  drafts: [],

  fetchingDrafts: false,
  creatingProfileId: false,
  currentScoringProfile: 0,

  fetchingProfiles: false,
  fetchingProfileMeasureDetails: false,
  fetchingProfileAllMeasures: false,
  fetchingProfileBucketCorpus: false,

  engagementNames: {
    openRate: 'Open Rate',
    clickRate: 'CTR',
    unsubscribeRate: 'Unsubscribe Rate',
  },

  engagementSelection: [
    {
      icon: 'envelope-open',
      text: 'Open Rate',
      color: '#68d4f0',
      name: 'openRate',
    },
    {
      icon: 'hand-pointer',
      text: 'CTR',
      color: '#FBB157',
      name: 'ctr',
    },
    {
      icon: 'ban',
      text: 'Unsubscribe Rate',
      color: '#dc3545',
      name: 'unsubscribeRate',
    }
  ],

  negativeEngagement: ['unsubscribeRate'],


  addingContentData: false,
  calculatingProfile: false,

  profiles: [],
  profile: {},
  profileName: '',
  standardProfile: false,
  profileMeasures: {},
  profileBodyEngagement: '',
  profileTitleEngagement: '',
  profileBucket: '',
  selectedMeasures: [],
  profileCutOffEngagement: {}
};

const emailApp = (state = initialState, action) => {

  switch (action.type) {
    case GET_EMAILS_REQUEST:
      return {
        ...state,
        emails: [],
        fetchingEmails: true,
      };
    case SET_EMAIL_PAGE:
      return{
        ...state,
      };
    case GET_EMAILMEASURES_REQUEST:
      return {
        ...state,
        fetchingEmailMeasures: true,
        emailMeasures: {},
      };
    case GET_EMAILS_SUCCESS:
      // take care of the case when there are no emails


      return {
        ...state,
        emails: action.response.emailData,
        fetchingEmails: false,
        campaignsData: action.response.campaignsData,
        profileBodyEngagement: action.response.scoringProfileBodyEngagement,
        profileTitleEngagement: action.response.scoringProfileTitleEngagement,
      };
    case GET_EMAILMEASURES_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        emailMeasures: action.response.measuresData
      };
    case EMAIL_LOADING_DONE:
      return {
        ...state,
        fetchingEmails: false,
      };
    case EMAILMEASURES_LOADING_DONE:
      return {
        ...state,
        fetchingEmailMeasures: false,
      };
    case GET_EMAILS_FAILURE:
      return {
        ...state,
        fetchingEmails: false,
        fetchingEmailsError: action.error,
      };
    case GET_EMAILMEASURES_FAILURE:
      return {
        ...state,
        fetchingEmailMeasures: false,
        fetchingEmailMeasuresError: action.error,
      };
    case GET_DRAFTS_REQUEST:
      return {
        ...state,
        fetchingDrafts: true,
      };
    case GET_DRAFTS_SUCCESS:
      return {
        ...state,
        fetchingDrafts: false,
        drafts: action.response.data,
      };
    case MERGE_EMAIL_DRAFTS:
      return {
        ...state,
        emails: action.email.slice(),
        fetchingEmails: false,
      };

    /* Profile Details */

    case GET_PROFILE_CONTENT_REQUEST_EMAIL:
      return {
        ...state,
        fetchingContentData: true,
      };
    case GET_PROFILE_CONTENT_SUCCESS_EMAIL:
      return {
        ...state,
        fetchingContentData: false,
        contentData: action.response.contentDataIds
      };
    case GET_PROFILE_CONTENT_FAILURE_EMAIL:
      return {
        ...state,
        fetchingContentData: false,
        fetchingContentDataError: action.error,
      };

    case DELETE_PROFILES_REQUEST_EMAIL:
      return {
        ...state,
        deletingProfiles: true,
      };
    case DELETE_PROFILES_SUCCESS_EMAIL:
      return {
        ...state,
        deletingProfiles: false,
        deleteProfileIds: action.response.profileIds
      };
    case DELETE_PROFILES_FAILURE_EMAIL:
      return {
        ...state,
        deletingProfiles: false,
        deleteProfileIdError: action.error,
      };

    case ACTIVATE_PROFILES_REQUEST_EMAIL:
      return {
        ...state,
        activatingProfiles: true,
      };
    case ACTIVATE_PROFILES_SUCCESS_EMAIL:
      return {
        ...state,
        activatingProfiles: false,
        activatedProfileIds: action.response.profileIds
      };
    case ACTIVATE_PROFILES_FAILURE_EMAIL:
      return {
        ...state,
        activatingProfiles: false,
        activatedProfileIdError: action.error,
      };

    case CREATE_PROFILES_REQUEST_EMAIL:
      return {
        ...state,
        creatingProfileId: true,
      };
    case CREATE_PROFILES_SUCCESS_EMAIL:
      return {
        ...state,
        creatingProfileId: false,
        createdProfileId: action.response.data.profileId
      };
    case CREATE_PROFILES_FAILURE_EMAIL:
      return {
        ...state,
        creatingProfileId: false,
        creatingProfileIdError: action.error,
      };

    case ADDED_CONTENT_REQUEST_EMAIL:
      return {
        ...state,
        addingContentData: true,
      };
    case ADDED_CONTENT_SUCCESS_EMAIL:
      return {
        ...state,
        addingContentData: false,
        addedContentData: action.response.params.contentDataIds
      };
    case ADDED_CONTENT_FAILURE_EMAIL:
      return {
        ...state,
        addingContentData: false,
        addedContentDataError: action.error,
      };

    case REMOVE_CONTENT_REQUEST_EMAIL:
      return {
        ...state,
        removingContentData: true
      };
    case REMOVE_CONTENT_SUCCESS_EMAIL:
      return {
        ...state,
        removingContentData: false
      };
    case REMOVE_CONTENT_FAILURE_EMAIL:
      return {
        ...state,
        removingContentData: false
      };

    case GET_PROFILES_REQUEST_EMAIL:

      return {
        ...state,
        fetchingProfiles: true,
        profiles: [],
      };
    case GET_PROFILES_SUCCESS_EMAIL:


      return {
        ...state,
        fetchingProfiles: false,
        profiles: action.response.data,
      };
    case GET_PROFILES_FAILURE_EMAIL:
      return {
        ...state,
        fetchingProfiles: false,
        fetchingProfilesError: action.error,
      };

    case GET_PROFILE_MEASURES_REQUEST_EMAIL:
      return {
        ...state,
        fetchingProfileAllMeasures: true,
        profileMeasures: {},
      };
    case GET_PROFILE_MEASURES_SUCCESS_EMAIL:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        profileMeasures: action.response,
        standardProfile: action.response.standard,
        profileName: action.response.profileName,
        currentScoringProfile: action.response.profileId,

      };

    case GET_PROFILE_MEASURES_FAILURE_EMAIL:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        fetchingProfileMeasuresError: action.error,

      };

    case GET_PROFILE_MEASURES_DETAILS_REQUEST_EMAIL:
      return {
        ...state,
        fetchingProfileMeasureDetails: true,
        selectedMeasures: []
      };
    case GET_PROFILE_MEASURES_DETAILS_SUCCESS_EMAIL:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        selectedMeasures: action.response.data.measure,
      };
    case GET_PROFILE_MEASURES_DETAILS_FAILURE_EMAIL:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        fetchingProfileMeasureDetailsError: action.error,
      };
    case GET_PROFILES_BUCKET_CORPUS_REQUEST_EMAIL:
      return {
        ...state,
        fetchingProfileBucketCorpus: true,
      };
    case GET_PROFILES_BUCKET_CORPUS_SUCCESS_EMAIL:
      return {
        ...state,
        fetchingProfileBucketCorpus: false,
        selectedBucketCorpusMeasures: action.response,
      };
    case GET_PROFILES_BUCKET_CORPUS_FAILURE_EMAIL:
      return {
        ...state,
        fetchingProfileBucketCorpus: false,
        fetchingProfileBucketCorpusError: action.error,
      };
    case PROFILE_CALCULATE_REQUEST_EMAIL:
      return {
        ...state,
        calculatedProfile: '',
        calculatingProfile: true,
        calculatedError: ''
      };
    case PROFILE_CALCULATE_SUCCESS_EMAIL:
      return {
        ...state,
        calculatedProfile: action.response.message,
        calculatingProfile: false,
        calculatedError: ''
      };
    case PROFILE_CALCULATE_FAIL_EMAIL:
      return {
        ...state,
        calculatedProfile: '',
        calculatingProfile: false,
        calculatedError: action.error
      };

    case UPDATE_PROFILE_NAME_EMAIL:
      return {
        ...state,
        profileName: action.value,
      };
    case DELETE_LOCAL_DRAFTS:
      const { draftIds } = action;
      return {
        ...state,
        emails: state.emails.filter(el => (-1 == draftIds.indexOf(el.id))),
      };
    case RESET_EMAIL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default emailApp;
