import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNavMinimized: false,
};

const LayoutSlice = createSlice({
  name: 'Layout',
  initialState,
  reducers: {
    toggleNavigationBar(state, action) {
      state.isNavMinimized = !state.isNavMinimized;
    },
  },
});

export const { toggleNavigationBar } = LayoutSlice.actions;

export default LayoutSlice.reducer;
