import {
  TRY_LOGIN,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGIN_ERROR,
  LOGIN_ALERT,
  CONFIRM_LOGIN,
  LOG_OUT,
  CLEAR_LOGIN_ERROR,
  SIGNUP_REQUEST,
} from '../constants/actionTypes';

const initState = {
  authenticating: false,
  authorized: false,
  loginErrorMessage: '',
  loginAlert: '',
  loginStatus: '',
  session: {},
  accountId:null,
  first:'',
  last:'',
  email:'',
  signup:false,
};

const loginApp = (state = initState, action) => {




  switch (action.type) {
    case TRY_LOGIN:
      return {
        ...state,
        authenticating: true,
        signup:true,
        session: Object.assign({}, state.session, { email: action.email }),
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticating: false,
        authorized: true,
        session: Object.assign({}, action.session, { email: action.email }),
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signup:true,
        first:'',
        last:'',
        email:'',
      }
    case CONFIRM_LOGIN:
      return {
        ...state,
        authenticating: false,
        authorized: true,
        session: action.session,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        authenticating: false,
        authorized: false,
        loginErrorMessage: action.response.data,
        loginStatus: action.response.status,
        email: '',
        session: {},
      };
    case LOGIN_ALERT:
      return {
        ...state,
        authenticating: false,
        authorized: false,
        loginAlert: action.response.data,
        loginStatus: action.response.status,
        email: '',
        session: {},
      };
    case LOG_OUT:
      return {
        ...state,
        authorized: false,
        email: '',
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginErrorMessage: '',
        loginAlert: '',
      }
    case SIGNUP_REQUEST:

      return{
        ...state,
        first:action.data.first,
        last:action.data.last,
        email:action.data.email,

      }
    default:
      return state;
  }
};

export default loginApp;
