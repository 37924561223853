import $ from 'jquery';
import { isNull } from 'util';
import Mark from 'mark.js';
import smoothScroll from 'smoothscroll';
import _ from 'lodash';
import {
  API_MEASURE_RTSYNONYMS,
  GRAMMAR_POPOVER,
  OPTIMIZE_API_POPOVER,
  EMOTION_POPOVER,
} from '../../../constants/internalConsts';
import {
  ACTIVE_FEEDBACK_CARD_CLASSNAME,
  HIDDEN_HIGHLIGHTING_CLASSNAME,
  IGNORED_HIGHLIGHTING_CLASSNAME,
  PARAGRAPH_HIGHLIGHT_ACTIVE,
  SIDEBAR_WRAPPER_CLASSNAME,
  SIDEBAR_WRAPPER_HAS_ACTIVE_CARD,
  TITLE_ID,
} from './static/constants';
import { getArClient } from '../../Global/globalLib';

export function getEditorDocument() {
  return document.querySelector('.fr-element.fr-view');
}

/* ********* EVENT HANDLING ********** */
export function callOnEnterKey(editor, callback) {
  editor.on('keyup', e => {
    if (e.keyCode === 13) {
      callback(e);
    }
  });
}

export function callOnStopTyping(editor, callback, typingInterval = 500) {
  let typingTimer = null;

  editor.on('keyup', e => {
    // console.log("change: ", e.keyCode);
    clearInterval(typingTimer);
    if ([13, 37, 38, 39, 40].indexOf(e.keyCode) !== -1) return;
    typingTimer = setTimeout(() => {
      callback(e);
    }, typingInterval);
  });
}

/**
 * In javascript keypress detects only character letters
 * it does not capture delete, backspace, shift, ctrl, etc.
 */
export function callOnKeyPress(editor, callback) {
  editor.on('keypress', e => {
    callback(e);
  });
}

export function callOnChange(editor, callback) {
  editor.on('change', e => {
    if (e.originalEvent && e.originalEvent.type === 'execcommand') {
      callback(e);
    }
  });
}

export function callOnKeyUp(editor, callback) {
  editor.on('keyup', e => {
    callback(e);
  });
}

export function callOnSelectedKeys(editor, callback) {
  editor.on('keyup', e => {
    const keys = [8, 13, 37, 38, 39, 40];
    if (keys.indexOf(e.keyCode) !== -1) {
      callback(e);
    }
  });
}

export function callOnFocus(editor, callback) {
  editor.on('focus', e => {
    callback(e);
  });
}

export function callOnClick(editor, callback) {
  editor.on('click', e => {
    callback(e);
  });
}

export function callOnPaste(editor, callback) {
  editor.on('paste', e => {
    callback(e);
  });
}

export function callOnUndo(editor, callback) {
  editor.on('undo', e => {
    callback(e);
  });
}

/* *********  STRING OPERATIONS / CLEANUP ********** */

export function escapeCharForRegex(word) {
  if (word) {
    return word.replace(/(\.|\"|\'|\?|\/|\)|\(|\[|\]|\$|\+)/g, '\\$1');
  }
  return '';
}

export function cleanString(str) {
  let result = str;

  result = str.replace(/<iframe(.*?)>(.*?)<\/iframe>/g, ''); // removes iframe
  result = str.replace(/<(.*?)(display:\s?none)(.*?)<\/(.*?)>/g, ''); // remove all hidden tags.
  result = str.replace(
    /<(p|div)[^>]*\>(\s|&nbsp;|<\/?\s?br[^>]*\>?\/?>)*<\/?(p|div)>/g,
    ''
  ); // removes empty tags.
  result = str.replace(/<br[^>]*./g, '');
  result = str.replace('\u00A0', ' ');

  // CHARACTERS CLEAN UP
  result = str.replace(/[\u201C\u201D\u201E\u201F\u2033\u2036]/g, '"');
  result = str.replace(/[\u2018\u2019\u201A\u201B\u2032\u2035]/g, "'");

  result = str.replace(/(&acirc;&euro;&trade;)/g, "'");
  result = str.replace(/(&acirc;&euro;&rdquo;)/g, '"');
  result = str.replace(/<p.*class="placeholder-custom-label".*\/p>/, '');

  return removeZeroWidthChar(result);
}

export function strip(html) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export function cleanAtomicReachMarkUp(html, removeId) {
  if (typeof removeId === 'undefined') removeId = false;

  // console.log(html);

  // removes the <ar> and <mark> tags
  const pattern = '</?ar(.*?)>|</?mark(.*?)>';
  const regexr = new RegExp(pattern, 'g');
  let cleanHtml = html.replace(regexr, '');

  // SideEffect: It adds empty P tags. Let's remove that as well.
  const pattern2 = '<p></p>';
  const regexr2 = new RegExp(pattern2, 'g');
  cleanHtml = cleanHtml.replace(regexr2, '');

  // Removes readability border lines:
  let pattern3 =
    '(border-left:(.*?);|padding-left:(.*?);|border-radius:(.*?);)';
  let regexr3 = new RegExp(pattern3, 'g');
  cleanHtml = cleanHtml.replace(regexr3, '');

  // Removes follow link popup
  const pattern4 = '<p.*class=.ARLinkPopUp.*.*?</p>';
  const regexr4 = new RegExp(pattern4, 'g');
  cleanHtml = cleanHtml.replace(regexr4, '');

  if (removeId) {
    // removes the id with arIndex_
    pattern3 = '\\sid=["|\']arIndex_[0-9]{0,3}["|\']';
    regexr3 = new RegExp(pattern3, 'g');
    cleanHtml = cleanHtml.replace(regexr3, '');
  }
  cleanHtml = cleanHtml.replace('&nbsp;', ' ');
  return cleanHtml;
}

export function removeZeroWidthChar(text) {
  // return text.replace(/\u200B/, '').replace(/(\#|\@)\w\w+\s?/g, '');
  return text.replace(/\u200B/, '');
}

export function lastUncheckedIndex(paragraphs) {
  for (let i = paragraphs.length - 1; i >= 0; i--) {
    const pTag = getActiveDomByParagraphIndexNumber(i);
    if (
      !paragraphs[i].isChecked &&
      removeHubSpotVariables(removeZeroWidthChar($(pTag).text())).length >= 6
    ) {
      return i;
    }
  }
}

/* ********* TINYMCE DOCUMENT FUNCTIONS  ********** */
export const rtGoToParentTagsArray = [
  'A',
  'STRONG',
  'EM',
  'I',
  'SPAN',
  'SUP',
  'SUB',
  'CODE',
  'FONT',
  'MARK',
];
export const rtParagraphTagsArray = [
  'P',
  'LI',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'PRE',
  'TD',
];
export const rtParagraphTags = 'p,li,h1,h2,h3,h4,h5,pre';
/**
 * assings iDs to all paragraphs in editor content
 * @param editor tinymce instance
 * @param tags that are consider paragraphs
 * @return array of paragraphs [ {content: x, isChecked: false} ]
 */
export function mapTinyMceContent(editor) {
  const childNodes = getEditorChildNodes(editor);
  const paragraphs = [];
  for (let i = 0; i < childNodes.length; i += 1) {
    if (childNodes[i].className.indexOf('placeholder-custom-label') >= 0) {
      break;
    }
    childNodes[i].setAttribute('id', `arIndex_${i}`);
    if (childNodes[i].textContent === '') {
      paragraphs[i] = {
        isChecked: true,
        content: childNodes[i].innerHTML,
      };
      continue;
    }
    paragraphs[i] = { isChecked: false, content: childNodes[i].innerHTML };
  }

  return paragraphs;
}

export function arrayDifference(a, b) {
  return a.filter(elt => b.indexOf(elt) === -1);
}

/**
 * Retreive paragraphs residing in tinymce document body
 * @param editor
 */
export function getEditorChildNodes(editor) {
  const rootNode = editor.el;
  return rootNode.querySelectorAll(rtParagraphTags);
}

/**
 * retrieve only paragraph nodes from pasted content
 * @param pastedNodes args.node.children array coming from paste plugin
 */
export function filterPastedNodes(pastedNodes) {
  const result = [];
  for (let i = 0; i < pastedNodes.length; i += 1) {
    if (rtParagraphTagsArray.indexOf(pastedNodes[i].nodeName) >= 0) {
      result.push(pastedNodes[i]);
      continue;
    }
    let pastedChildNodes = pastedNodes[i].querySelectorAll(rtParagraphTags);
    pastedChildNodes = Array.prototype.slice.call(pastedChildNodes);
    result.push(...pastedChildNodes);
  }
  return result;
}

/**
 * used to get element to analyze as user is typing
 * @param e KeyUp event
 * @param editor tinymce instance
 */
// TODO: implementation is not finished here
export function getActiveElement(editor) {
  let focusedElement = editor.selection.element();

  // console.log('FOCUSED NODE NAME',focusedElement.nodeName.toUpperCase());

  if (
    rtGoToParentTagsArray.indexOf(focusedElement.nodeName.toUpperCase()) !== -1
  ) {
    // console.log('In if statement');

    for (let i = 0; i < rtParagraphTagsArray.length; i++) {
      const possibleParent = editor.node.blockParent(focusedElement);

      // console.log('Possible--',possibleParent);

      const possibleParentIndex = rtGetDataIndex(possibleParent);

      // console.log('possibleParentIndex--',possibleParentIndex);

      if (possibleParentIndex !== null && possibleParentIndex >= 0) {
        focusedElement = possibleParent;
        break;
      }
    }
  }
  return focusedElement;
}

export function getNextActiveElement(editor) {
  let focusedElement = editor.selection.element();

  if (
    rtGoToParentTagsArray.indexOf(focusedElement.nodeName.toUpperCase()) !== -1
  ) {
    for (let i = 0; i < rtParagraphTagsArray.length; i++) {
      const nodeName = rtParagraphTagsArray[i];
      const possibleParent = editor.node.blockParent(focusedElement);

      if (!isNull(possibleParent)) {
        const possibleNext = editor.dom.getNext(
          possibleParent,
          parent => parent.nodeName === nodeName
        );
        const possibleParentIndex = rtGetDataIndex(possibleNext);

        if (!isNull(possibleParentIndex) && possibleParentIndex >= 0) {
          focusedElement = possibleParent;
          break;
        }
      }
    }
  }
  return focusedElement;
}

/**
 * Breaks down document node's ID to get last bit (node with id arIndex_1 will return 1)
 * @param el document node
 */
export function rtGetDataIndex(el) {
  const $el = $(el);

  if (typeof $el === 'undefined' || typeof $el[0] === 'undefined') {
    return null;
  }

  if ($.inArray($el[0].nodeName, rtParagraphTagsArray) === -1) {
    return null;
  }

  if (typeof $el.attr('id') === 'undefined' || $el.attr('id') === null) {
    return null;
  }

  const partsArray = $el.attr('id').split('_');
  return Math.round(partsArray[1]);
}

export function getParagraphAsParentNode(element) {
  return $(element).closest(rtParagraphTags);
}

/**
 * @param text
 */
export function removeHubSpotVariables(text) {
  const newtext = removeHTMLComments(text);
  return newtext.replace(/\{{2}.+?\}{2}/gi, '');
}

function removeHTMLComments(html) {
  const pattern = '<!--.*?-->';
  const regexr = new RegExp(pattern, 'g');
  return html.replace(regexr, '');
}

/**
 * If content doesn't have paragraphs, break it down by line breaks
 * and wrap the chunks in <p> tags
 *
 * @param string content
 */
export function checkParagraphs(content) {
  const paragraphs = content.match(/<\/li>|<\/p>/g);

  if (!paragraphs) {
    if (content.indexOf('<div class="Content">') !== -1) {
      content = content.replace('<div class="Content">', '');
      content = content.substring(0, content.lastIndexOf('</div>'));
    }

    let chunks = content.split(/\n/);
    chunks = chunks.map(paragraph => {
      paragraph = paragraph.trim();
      if (!paragraph) {
        return '';
      }
      return `<p>${paragraph}</p>`;
    });

    content = `<div class="Content">${chunks.join('')}</div>`;
  }

  return content;
}

/**
 * @deprecated
 */
export function filterSynonymsWordsToHighlight(measureName, detail, dataArray) {
  let resultDataArray = [];

  switch (measureName) {
    case 'Arousal':
      resultDataArray = dataArray.synonyms.filter(
        val => val.emotion === detail
      );
      break;
    case 'Readability':
      if (detail == 'TOO SIMPLE') {
        resultDataArray = dataArray.synonyms.filter(
          val => val.audience === '+'
        );
      } else if (detail == 'TOO COMPLEX') {
        resultDataArray = dataArray.synonyms.filter(
          val => val.audience === '-'
        );
      }
      break;
  }

  return resultDataArray;
}
/* Underline the word when you click on it. Either in the editor or in the task list card. */
export function setActiveUnderlinedWordOnClicked(
  paragraphIndexOrDom,
  word,
  instance = 0
) {
  // console.log('set active: ', paragraphIndexOrDom, word, instance);

  let activeDomParagraph = paragraphIndexOrDom;
  let $wordToHighlight;
  if (Number.isInteger(paragraphIndexOrDom))
    activeDomParagraph = getActiveDomByParagraphIndexNumber(
      paragraphIndexOrDom
    );

  if (typeof instance === 'number') {
    $wordToHighlight = $(activeDomParagraph).find(
      `mark[data-instance='${instance}']:contains('${word}')`
    );
  } else {
    $wordToHighlight = $(activeDomParagraph).find(`#${instance}`);
  }

  // console.log('acive dom para: ', paragraphIndexOrDom, activeDomParagraph);
  // console.log('word to highlight: ', $wordToHighlight);
  // get iframe active words
  const prevOptimizedActiveWords = getEditorDocument().querySelectorAll(
    '.optimizedWord.active'
  );
  const prevGrammarActiveWords = getEditorDocument().querySelectorAll(
    '.checkSpellingGrammar.active'
  );

  $(activeDomParagraph)
    .find('mark')
    .not($wordToHighlight)
    .removeClass('active');
  if (prevOptimizedActiveWords.length > 0) {
    prevOptimizedActiveWords[0].removeAttribute('class');
    prevOptimizedActiveWords[0].setAttribute('class', 'optimizedWord');
  }
  if (prevGrammarActiveWords.length > 0) {
    prevGrammarActiveWords[0].removeAttribute('class');
    prevGrammarActiveWords[0].setAttribute('class', 'checkSpellingGrammar');
  }
  $wordToHighlight.addClass('active');
}

export function markAllTitleWordsInactive() {
  const activeWordsARr = document
    .getElementById(TITLE_ID)
    .querySelectorAll('mark.active');
  if (activeWordsARr.length > 0) {
    activeWordsARr.forEach(elem => {
      elem.classList.remove('active');
    });
  }
}

export function markTitleWordsInactiveByCardId(cardId) {
  const selector = `mark.active[data-feedbackcardid="${cardId}"]`;
  const activeWordsARr = document
    .getElementById(TITLE_ID)
    .querySelectorAll(selector);

  if (activeWordsARr.length > 0) {
    activeWordsARr.forEach(elem => {
      elem.classList.remove('active');
    });
  }
}

export function unMarkTitleWordByCardId(cardId) {
  const selector = `mark[data-feedbackcardid="${cardId}"]`;
  const activeWordsARr = document
    .getElementById(TITLE_ID)
    .querySelectorAll(selector);

  const marker = new Mark(activeWordsARr);
  marker.unmark();
}

export function markAllWordsInactive() {
  const activeWordsARr = getEditorDocument().querySelectorAll('mark.active');
  if (activeWordsARr.length > 0) {
    activeWordsARr.forEach(elem => {
      elem.classList.remove('active');
    });
  }
}

export function disableActiveUnderlinedWord(paragraphIndexOrDom) {
  let activeDomParagraph;

  if (typeof paragraphIndexOrDom === 'undefined') {
    markAllWordsInactive();
  }

  if (Number.isInteger(paragraphIndexOrDom)) {
    activeDomParagraph = getActiveDomByParagraphIndexNumber(
      paragraphIndexOrDom
    );
  }

  $(activeDomParagraph)
    .find('mark')
    .removeClass('active');
}

export function getActiveDomByParagraphIndexNumber(paragraphIndex) {
  const doc = getEditorDocument();
  if (doc) {
    const id = `#arIndex_${paragraphIndex}`;
    return doc.querySelector(id);
  }
}

/* ***************** DOM Operations in editor environment *********************** */
// TODO: FIX TOOLBAR. IT JUMPS ON CERTAIN HEIGHTS OF THE SCREEN
export function repositionToolbar(topPosition) {
  if ($(window).scrollTop() > topPosition) {
    const maxWidth = $('#editor-wrapper').width();
    $('.editor-info')
      .addClass('fixed-scroll-1')
      .css('max-width', `${maxWidth}px`);
    $('.mce-top-part.mce-container.mce-stack-layout-item.mce-first')
      .addClass('fixed-scroll-2')
      .css('max-width', `${maxWidth}px`);
    if (document.querySelector('#editor-wrapper'))
      document.querySelector('#editor-wrapper').style.paddingTop = '35px';
  } else {
    $('.editor-info')
      .removeClass('fixed-scroll-1')
      .css('max-width', 'none');
    $('.mce-top-part.mce-container.mce-stack-layout-item.mce-first')
      .removeClass('fixed-scroll-2')
      .css('max-width', 'none');
    if (document.querySelector('#editor-wrapper'))
      document.querySelector('#editor-wrapper').style.paddingTop = '0';
  }
}

export function toolbarFixedOnScroll() {
  const editorTopPosition = $('.editor-info').offset().top;
  $(window).scroll(() => {
    repositionToolbar(editorTopPosition);
  });
  $(window).resize(() => {
    repositionToolbar(editorTopPosition);
  });
}

export function repositionProgressBar(topPosition) {
  if ($(window).scrollTop() > topPosition) {
    const maxWidth = $('#RightPanelWrapper').width();
    $('.doc-progress-bar-wrapper')
      .addClass('fixed-scroll-3')
      .css('max-width', `${maxWidth}px`);
  } else {
    $('.doc-progress-bar-wrapper')
      .removeClass('fixed-scroll-3')
      .css('max-width', 'none');
  }
}

export function progressBarFixedOnScroll() {
  $(window).scroll(() => {
    if ($('.task-list-document-head').length) {
      const positionOnScroll =
        $('.task-list-document-head').offset().top +
        $('.task-list-document-head').height();
      repositionProgressBar(positionOnScroll);
    }
  });
  $(window).resize(() => {
    if ($('.task-list-document-head').length) {
      const positionOnResize =
        $('.task-list-document-head').offset().top +
        $('.task-list-document-head').height();
      repositionProgressBar(positionOnResize);
    }
  });
}

export function extractParagraphNumberById(strId) {
  if (strId.match(/arIndex/) != null) {
    return strId.split('_')[1];
  }

  return '0';
}

export function isWordHighlighted(paragraphIndex, wordInstance) {
  const doc = document.querySelector('.fr-element.fr-view');
  const paragNode = doc.querySelector(`#arIndex_${paragraphIndex}`);
  const marker = paragNode.querySelector(
    `mark[data-instance='${wordInstance}']`
  );

  return marker !== null;
}

export function isOptimizedWordHighlighted(paragraphIndex, wordInstance) {
  const paragNode = getEditorDocument().querySelector(
    `#arIndex_${paragraphIndex}`
  );
  const marker = paragNode.querySelector(
    `mark#optimize_id_${paragraphIndex}_${wordInstance}`
  );

  return marker !== null;
}

/**
 * @param activeParagraph number
 * @param editorParagraphs array of paragraphs from store
 */
export function toggleParagraphFading(activeParagraph, editorParagraphs) {
  if (activeParagraph !== null && activeParagraph >= 0) {
    $('.issue-icon').removeClass('faded');
    $('.issue-icon')
      .not(`#arMark_${activeParagraph}`)
      .addClass('faded');
  } else {
    $('.issue-icon').removeClass('faded');
  }
  const Doc = getEditorDocument();
  if (Doc) {
    try {
      if (activeParagraph !== null && activeParagraph >= 0) {
        editorParagraphs.forEach((paragraph, i) => {
          Doc.querySelector(`#arIndex_${i}`).classList.remove('faded');
          Doc.querySelector(`#arIndex_${i}`).classList.remove('not-faded');
          if (i === activeParagraph) {
            Doc.querySelector(`#arIndex_${i}`).classList.add('not-faded');
            const closestFaded = Doc.querySelector(`#arIndex_${i}`).closest(
              '.faded'
            );
            if (closestFaded) {
              closestFaded.classList.remove('faded');
            }
            return;
          }
          const closestUnfaded = Doc.querySelector(`#arIndex_${i}`).closest(
            '.not-faded'
          );
          if (!closestUnfaded) {
            Doc.querySelector(`#arIndex_${i}`).classList.add('faded');
          }
          // unHighlightAllByParagraphId(i,[".checkSpellingGrammar"]);
        });
      } else {
        editorParagraphs.forEach((paragraph, i) => {
          if (Doc.querySelector(`#arIndex_${i}`)) {
            Doc.querySelector(`#arIndex_${i}`).classList.remove('faded');
            Doc.querySelector(`#arIndex_${i}`).classList.remove('not-faded');
          }
        });
      }
    } catch (ignore) {}
  }
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetterOnly(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Check if editor's height is bigger than content within
 * and adjust it to fit the content
 * @param editorInstance tinymce editor object
 */
export function adjustEditorHeight(editorInstance) {
  const editorBody = getEditorDocument();
  if (
    editorBody.children.length > 0 &&
    editorBody.children[0].nodeName === 'TABLE'
  ) {
    setTimeout(() => {
      const height = Math.max(
        editorBody.children[0].clientHeight,
        editorBody.children[0].offsetHeight
      );
      if (height > editorBody.offsetHeight) {
        editorInstance.theme.resizeTo(null, height);
      }
    }, 100);
  }
}

export function replaceActiveWordInTitle($replacementWordDom) {
  const $wordToReplaceDom = $(`#${TITLE_ID}`).find('mark.active');

  if ($wordToReplaceDom.length === 0) return false;

  let replacementWordString = $replacementWordDom.text().trim();
  const wordToReplaceString = $wordToReplaceDom.text().trim();
  // if the original word's first letter is uppercase
  if (wordToReplaceString[0] === wordToReplaceString[0].toUpperCase()) {
    // replacement words's first letter should also be uppercase
    replacementWordString = capitalizeFirstLetter(replacementWordString);
  }
  $wordToReplaceDom.text(replacementWordString).fadeIn(100);
  $replacementWordDom.text(wordToReplaceString).fadeIn(100);

  return true;
}

/*
 * @paragraphIndex: Send the current active paragraph index number. Where the active highlighted word is.
 * @$replacementWordDom: Must be jQuery object of what just clicked on. Replacement word dom.
 * */
export function replaceActiveWord(paragraphIndexOrDom, $replacementWordDom) {
  // console.log('replace active wrd', paragraphIndexOrDom, $replacementWordDom);
  let activeDomParagraph = paragraphIndexOrDom;
  if (typeof paragraphIndexOrDom !== 'object') {
    activeDomParagraph = getActiveDomByParagraphIndexNumber(
      paragraphIndexOrDom
    );
  }

  const $wordToReplaceDom = $(activeDomParagraph).find('mark.active');
  if ($wordToReplaceDom.length === 0) return false;

  let replacementWordString = $replacementWordDom.text().trim();
  const wordToReplaceString = $wordToReplaceDom.text().trim();
  // if the original word's first letter is uppercase
  if (wordToReplaceString[0] === wordToReplaceString[0].toUpperCase()) {
    // replacement words's first letter should also be uppercase
    replacementWordString = capitalizeFirstLetter(replacementWordString);
  }
  $wordToReplaceDom.text(replacementWordString).fadeIn(100);
  $replacementWordDom.text(wordToReplaceString).fadeIn(100);

  return true;
}

export function replaceActiveWordProdDesc(
  paragraphIndexOrDom,
  $replacementWordDom
) {
  const $wordToReplaceDom = $(paragraphIndexOrDom).find('mark.active');
  if ($wordToReplaceDom.length == 0) return false;

  let replacementWordString = $replacementWordDom.text().trim();
  const wordToReplaceString = $wordToReplaceDom.text().trim();

  if (wordToReplaceString[0] === wordToReplaceString[0].toUpperCase()) {
    // replacement words's first letter should also be uppercase
    replacementWordString = capitalizeFirstLetter(replacementWordString);
  }

  $wordToReplaceDom.text(replacementWordString).fadeIn(100);

  return true;
}

/**
 * Works in Title
 * @param {domElement} exceptElement
 */
export function hidePopovers(exceptElement) {
  // Remove title popovers
  const titleElement = document.getElementById('custom-title');
  const titleMarks = titleElement.querySelectorAll('mark');

  if ($('.titleMarkPopover').length) {
    [].forEach.call(titleMarks, mark => {
      if (exceptElement !== mark) $(mark).popover('hide');
    });
  }

  const popovers = document.querySelectorAll('.popover.show');
  if (popovers.length > 0) {
    [].forEach.call(popovers, popover => {
      // popover.parentNode.removeChild(popover);
    });
  }
}

export function unHighlightAllByParagraphId(
  paragraphId,
  excludeSelectors = []
) {
  const activeParagraph = getActiveDomByParagraphIndexNumber(paragraphId);
  const options = {};
  if (excludeSelectors.length > 0) {
    options.exclude = excludeSelectors;
  }

  const marker = new Mark(activeParagraph);
  marker.unmark(options);
}

export function hideHighlightSelectorsByParagraphId(
  paragraphId,
  excludeSelectors = []
) {
  const activeParagraph = getActiveDomByParagraphIndexNumber(paragraphId);
  if (excludeSelectors.length > 0) {
    const markedWordElems = activeParagraph.querySelectorAll(excludeSelectors);
    if (markedWordElems.length > 0) {
      markedWordElems.forEach(elem =>
        elem.classList.add(HIDDEN_HIGHLIGHTING_CLASSNAME)
      );
    }
  }
}

export function showHighlightSelectorsByParagraphId(
  paragraphId,
  excludeSelectors = []
) {
  const activeParagraph = getActiveDomByParagraphIndexNumber(paragraphId);
  if (excludeSelectors.length > 0) {
    const markedWordElems = activeParagraph.querySelectorAll(excludeSelectors);
    if (markedWordElems.length > 0) {
      markedWordElems.forEach(elem =>
        elem.classList.remove(HIDDEN_HIGHLIGHTING_CLASSNAME)
      );
    }
  }
}

export function unHighlightAllByClassName(paragraphId, className = []) {
  const activeParagraph = getActiveDomByParagraphIndexNumber(paragraphId);
  const options = {};
  if (className.length > 0) {
    options.className = className;
  }

  const marker = new Mark(activeParagraph);
  marker.unmark(options);
}

export function addToDictionary(element, arClient = getArClient()) {
  arClient.addDictionary(element.innerText, res => {
    if (res.status === 10) {
      const marker = new Mark(element);
      marker.unmark();
    }
  });
}

export function getPopoverMessage(feedback) {
  try {
    switch (feedback.measureName) {
      case 'Readability':
        if (feedback.feedback.Readability.toLowerCase() === 'too complex')
          return 'Optimize with a simpler word';
        if (feedback.feedback.Readability.toLowerCase() === 'too simple')
          return 'Optimize with a more complex word';
        break;
      case 'Arousal':
        if (feedback.feedback.Arousal === '-')
          return 'Optimize with a calmer word';
        if (feedback.feedback.Arousal === '+')
          return 'Optimize with an exciting word';
        break;
      default:
        return '';
    }
  } catch (ignore) {
    return '';
  }
}

export function getParagraphPositionInWindow(index) {
  if (getEditorDocument()) {
    const ifr = getEditorDocument();
    const ifrRect = ifr.getBoundingClientRect();
    const paragraph = ifr.querySelector(`#arIndex_${index}`);
    const rect = paragraph.getBoundingClientRect();
    return {
      paragraphX: ifrRect.x + rect.x + window.pageXOffset,
      paragraphY: ifrRect.y + rect.y + window.pageYOffset,
    };
  }
}

export function disableParagraph(index) {
  const domNode = getActiveDomByParagraphIndexNumber(index);
  if (domNode && !domNode.classList.contains('ar-disabled')) {
    domNode.classList.add('ar-disabled');
  }
}

export function enableParagraph(index) {
  const domNode = getActiveDomByParagraphIndexNumber(index);
  if (domNode && domNode.classList.contains('ar-disabled')) {
    domNode.classList.remove('ar-disabled');
  }
}

export function offsetInDocument(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    nodeY: rect.top + scrollTop,
    nodeX: rect.left + scrollLeft,
  };
}

/**
 *
 * @param {*} feedback
 */
export function grammarPopoverContent(feedback) {
  let result = `<p class='spellingPopoverSummary'>${feedback[0][0]}</p>`;

  if (feedback.url) {
    result += `<a class='mb-1' href='${feedback.url}' target='_blank'>click here to learn more</a>`;
  } else {
    result += "<ul class='list-group pre-scrollable'>";
    for (const str of feedback[0][1]) {
      result += `${"<li class='list-group-item spellingGrammarReplacementWord arPopoveReplacementWordItem'" +
        '>'}${str}</li>`;
    }
    result += '</ul>';
  }

  return result;
}

/**
 * @param {*} feedback
 * @return {string}
 */
export function readabilityEmotionPopoverContent(feedback) {
  let result = '<ul class="list-group pre-scrollable">';
  for (const str of feedback) {
    result += `<li class='list-group-item synonymsReplacementWord'>
                <span class='replacement-word arPopoveReplacementWordItem'>${str}</span>
                <span class='bad-word-flag arBadWord'>
                  <i class="fas fa-flag"></i>
                </span>
              </li>`;
  }
  result += '</ul>';
  return result;
}

/**
 * find the index's position of the word in the string
 * @param {string} string
 * @param {string} subString
 * @param {num} index
 */
export function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

/**
 * check if there are issues in paragraph analyze response that require showing a bubble
 * or task list item
 * @param {object} response
 */
export function hasIssues(response) {
  const { critical, stylistic } = response.summaryIssues;
  const sumIssues = critical + stylistic;

  let issues = 0;

  if (!sumIssues) return false;

  Object.keys(response.data).map(measureKey => {
    if (paragraphHasIssues(response, measureKey)) issues++;
  });

  return issues !== 0;
}

/**
 * This run in the loop of api feedback. Check every key to see
 * whether a card should be displayed or not.
 * @param {object} response
 * @param {string} object key
 */
export function paragraphHasIssues(response, measureKey) {
  const toCheck = [
    'Readability',
    'Arousal',
    'RtSentenceLength',
    'RtParagraphLength',
  ];
  const feedback = response.data[measureKey];

  if (feedback && (feedback.state || toCheck.indexOf(measureKey) === -1))
    return false;

  if (
    ['Arousal', 'Readability'].indexOf(measureKey) >= 0 &&
    response.data[API_MEASURE_RTSYNONYMS] &&
    response.data[API_MEASURE_RTSYNONYMS].detail[measureKey.toLowerCase()]
      .length === 0
  ) {
    return false;
  }

  return true;
}

export function getEditorContentHTML() {
  const editorBody = getEditorDocument();
  return cleanAtomicReachMarkUp(editorBody.innerHTML);
}

/**
 * @param {DOMElement} mark
 * @return {object} state that will be passed to editor Popover element
 */
export function getPopoverState(mark) {
  const className = mark.getAttribute('class');
  const data = $(mark).data('arSuggestions');

  // console.log('getPOpooverOverState: ', mark, className, data);

  if (!data || !className) return null;

  const { feedback, originalWord } = data;

  const popoverState = {
    popoverOriginalWord: originalWord,
    popoverFeedback: feedback,
    popoverTarget: mark,
    showPopover: true,
    wordInstance: $(mark).data('instance'),
    parentParagraph: rtGetDataIndex($(mark).closest(rtParagraphTags)),
  };
  if (className.includes('checkSpellingGrammar')) {
    popoverState.popoverFeedback = feedback.suggestions;
    popoverState.type = GRAMMAR_POPOVER;
    if (feedback.type === 'spelling') {
      popoverState.showAddToDictionary = true;
    } else {
      popoverState.showAddToDictionary = false;
    }
  } else if (className.includes('optimizedWord')) {
    popoverState.popoverOriginalWord = data.text;
    popoverState.popoverFeedback = data;
    popoverState.showAddToDictionary = false;
    popoverState.type = OPTIMIZE_API_POPOVER;
  } else if (
    className.includes('ReadabilityHighlighting') ||
    className.includes('ArousalHighlighting')
  ) {
    popoverState.showAddToDictionary = false;
    popoverState.type = EMOTION_POPOVER;
  } else if (className.includes('complianceWord')) {
    popoverState.type = GRAMMAR_POPOVER;
    popoverState.popoverFeedback = feedback;
  } else {
    popoverState.type = EMOTION_POPOVER;
  }

  return popoverState;
}

export function getNumOfIssues(response) {
  const issues = {
    critical: 0,
    stylistic: 0,
  };

  Object.keys(response.data).forEach(measureKey => {
    if (!paragraphHasIssues(response, measureKey)) return;
    if (response.data[measureKey].issueSeverityRank == 'Stylistic')
      issues.stylistic += 1;
    if (response.data[measureKey].issueSeverityRank == 'Critical')
      issues.critical += 1;
  });

  return issues;
}

/**
 * checks if variable is an empty object or null
 * @param {variable} input to validate
 */
export function isEmpty(variable) {
  try {
    return !variable || Object.keys(variable).length === 0;
  } catch (i) {
    console.log('--- isEmpty:', variable);
  }
}

/**
 * copies a string to clipboard
 * @param {string}
 */
export function copyToClipboard(str) {
  function listener(e) {
    e.clipboardData.setData('text/html', str);
    e.clipboardData.setData('text/plain', str);
    e.preventDefault();
  }
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
}

// export function replaceMapOptimize(body, feedback) {
//   const objDuplicate = Object.assign({}, feedback);
//
//   let totalOriginalContent = '';
//   let thisTextContent = '';
//   const mapReplacementsForHighlighting = [];
//   const textNodes = [];
//
//   let rangeAdjustment = 0;
//
//   // MapReplaceTextNodes(body, objDuplicate);
//   recurseDomChildren(body, true);
//
//   function recurseDomChildren(start, output) {
//     let nodes;
//     if (start.childNodes) {
//       nodes = start.childNodes;
//       loopNodeChildren(nodes, output);
//     }
//   }
//   function outputNode(node) {
//     const whitespace = /^\s+$/g;
//     if (node.nodeType === 1) {
//       // console.log("element: " + node.tagName);
//       // console.log("element: " , node.getAttribute('id'));
//       if (node.getAttribute('id')) textNodes.push(node);
//     }
//     if (node.nodeType === 3) {
//       // clear whitespace text nodes
//       // node.data = node.data.replace(whitespace, '');
//       if (node.data) {
//         // console.log("text: " + node.data);
//       }
//     }
//   }
//   function loopNodeChildren(nodes, output) {
//     let node;
//     for (let i = 0; i < nodes.length; i++) {
//       node = nodes[i];
//       if (output) {
//         outputNode(node);
//       }
//       if (node.childNodes) {
//         recurseDomChildren(node, output);
//       }
//     }
//   }
//
//   MapReplaceTextNodes(textNodes, objDuplicate);
//
//   function MapReplaceTextNodes(nodes, obj) {
//     // Iterate filtered response
//     const textReplaced = Object.keys(obj).map((key) => {
//       const replacementsByParagraphs = [];
//
//       if (obj[key].length > 0) {
//         const tokens = Object.keys(obj[key]).reverse();
//         const orderedTokens = [];
//         // replacement found in paragraph
//         // @todo if node has children e.g anchor
//         const textNodeSplit = nodes[key].textContent.split('');
//
//         tokens.forEach((token) => {
//           const textLength = obj[key][token].text.length;
//
//           textNodeSplit[obj[key][token].idx] = `*[${obj[key][token].replacement}]*`;
//
//           // remove left overs chars
//           textNodeSplit.splice(obj[key][token].idx + 1, textLength - 1);
//
//           const newText = textNodeSplit.join('');
//           // console.log('new text =>', newText);
//
//           nodes[key].textContent = newText;
//
//           orderedTokens.push({
//             replacement: obj[key][token].replacement,
//             feedback: obj[key][token],
//           });
//
//           replacementsByParagraphs[0] = orderedTokens.reverse();
//         });
//       }
//       return replacementsByParagraphs;
//     });
//     mapReplacementsForHighlighting.push(textReplaced);
//   }
//   return mapReplacementsForHighlighting;
// }

export function getEditorBodyTextContent(editorBody) {
  let fullBody = '';

  getTextNodes(editorBody);

  function getTextNodes(node) {
    const paragraphTags = ['P', 'DD', 'DIV', 'LI'];

    if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) {
        if (node.childNodes[i].nodeType === 3) {
          const parentId = node.childNodes[i].parentElement.id;

          // Add space between nested nodes.
          if (
            fullBody[fullBody.length - 1] &&
            fullBody[fullBody.length - 1] !== ' ' &&
            node.childNodes[i].textContent[0] !== ' '
          ) {
            if (
              node.childNodes[i].textContent[0].match(/[\.|\?|\!|\,]/) === null
            ) {
              fullBody += ' ';
            }
          }

          const nodeName = !parentId
            ? node.childNodes[i].parentElement.parentElement.nodeName
            : node.childNodes[i].parentElement.nodeName;
          // console.log($(node)
          //   .parents('[id^="arIndex_"]'));

          // console.log(nodeName, node);

          if (paragraphTags.indexOf(nodeName) >= 0) {
            fullBody += removeZeroWidthChar(node.childNodes[i].textContent);
          } else {
            continue;
          }
          // }else{
          // console.log('SKIP ');
          // }

          // Separate Paragraphs with double line breaks.
          if (parentId.match(/arIndex_/) !== null && !node.childNodes[i + 1]) {
            // fullBody += '%EOP%';
            fullBody += '\n\n';
          }
        } else {
          getTextNodes(node.childNodes[i]);
          continue;
        }
      }
    }
  }

  // console.log(fullBody);

  return fullBody;
}
export const scrollToParagraph = activeParagraph => {
  const Doc = getEditorDocument();
  const el = Doc.querySelector(`#arIndex_${activeParagraph}`);
  // const iframe = document.getElementById('editor_ifr');
  // // const elOffsetTopifr = $(iframe).offset().top
  // // const elOffsetTop = $(el).offset().top
  // // const scrollPosition = $(window).scrollTop();
  // if ((elOffsetTop > scrollPosition) && (elOffsetTop < (scrollPosition + elOffsetTopifr))) {
  // } else {
  el.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
  // }
};

export function isElementInViewport(el, adjustment = 0) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top - adjustment >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export function Export2Doc(filename = '', subject = null, html = null) {
  if (html === null) {
    const editorBody = getEditorDocument();
    html = editorBody.innerHTML;
  }
  const title = document.getElementById('custom-title');
  const preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  const postHtml = '</body></html>';
  const titleStrong = `<h1>${
    subject === null ? title.innerHTML : subject
  }</h1>`;

  const fullHTML = preHtml + titleStrong + html + postHtml;

  const blob = new Blob(['\ufeff', fullHTML], {
    type: 'application/msword',
  });

  // Specify link url
  const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(
    fullHTML
  )}`;

  // Specify file name
  filename = filename ? `${filename}.doc` : 'document.doc';

  // Create download link element
  const downloadLink = document.createElement('a');

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    // triggering the function
    downloadLink.click();
  }

  document.body.removeChild(downloadLink);
}

export const copied = () => {
  const copyBtn = document.getElementById('textCopy-button');
  $(copyBtn).addClass('copied');
};

export const notCopied = () => {
  const copyBtn = document.getElementById('textCopy-button');
  $(copyBtn).removeClass('copied');
};

export const scrollToEditor = () => {
  const element = document.getElementsByClassName('top-section')[0];
  const nav = document.getElementsByClassName('navbar')[0];

  const top_of_element = $(element).offset().top;
  const bottom_of_element = $(element).offset().top + $(element).outerHeight();
  const bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
  const top_of_screen = $(window).scrollTop();

  if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
    smoothScroll(element);
  } else {
  }
};

export function isEditorReadOnly(editor) {
  return editor.getBody().classList.contains('mce-content-readonly');
}

export const browserBackButton = (url, history) => {
  window.addEventListener(
    'popstate',
    event => {
      history.push(url);
      window.location.reload();
    },
    false
  );
};

function clearActiveCards() {
  const sidebarElement = getSidebarElement();
  const prevActiveCards = sidebarElement.querySelectorAll(
    `.${ACTIVE_FEEDBACK_CARD_CLASSNAME}`
  );
  if (prevActiveCards.length > 0) {
    prevActiveCards.forEach(elem =>
      elem.classList.remove(ACTIVE_FEEDBACK_CARD_CLASSNAME)
    );
  }
}

const getSidebarElement = () =>
  document.querySelector(`.${SIDEBAR_WRAPPER_CLASSNAME}`);

export const alignCardWithParagraph = (activeParagraphIndex, cardId) => {
  const activeParagraphElem = getActiveDomByParagraphIndexNumber(
    activeParagraphIndex
  );
  const sidebarElement = getSidebarElement();
  const cardsContainer = sidebarElement.querySelector(
    '.bodyMeasuresFeedbackWrapper'
  );
  const activeCard = cardsContainer.querySelector(`.${cardId}`);
  const activeCardTop = activeCard.getBoundingClientRect().top;
  const activeParagraphElemTop = activeParagraphElem.getBoundingClientRect()
    .top;
  const cardsContainerAligment = activeParagraphElemTop - activeCardTop;

  clearActiveCards();

  if (activeCard !== null) {
    activeCard.classList.add(ACTIVE_FEEDBACK_CARD_CLASSNAME);
    cardsContainer.style.marginTop = '0px';

    const activeCardDomElement = activeCard.querySelector(`.feedbackCard`);
    activeCardDomElement.classList.remove('feedbackCardCollapsed');

    cardsContainer.style.marginTop = `${cardsContainerAligment}px`;
  }

  toggleCardOpacity();
};

export const activateTitleFeedbackCard = cardId => {
  const allCards = document.getElementsByClassName('feedbackCardContainer');

  if (allCards) {
    allCards.forEach(card => {
      const cardContents = card.querySelector('.ant-card');
      if (card.classList.contains(cardId)) {
        cardContents.classList.remove('feedbackCardCollapsed');
      } else {
        cardContents.classList.add('feedbackCardCollapsed');
      }
    });
  }
};

export const closestNumber = (num, arr) => {
  return arr.reduce((a, b) => {
    const aDiff = Math.abs(a - num);
    const bDiff = Math.abs(b - num);

    if (aDiff == bDiff) {
      // Choose largest vs smallest (> vs <)
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
};

export const alignCardWithWord = activeWordElem => {
  const activeWordElemTop = activeWordElem.getBoundingClientRect().top;
  const { feedbackcardid } = activeWordElem.dataset;
  const sidebarElement = getSidebarElement();
  const cardsContainer = sidebarElement.querySelector(
    '.bodyMeasuresFeedbackWrapper'
  );
  const activeCard = cardsContainer.querySelector(`.${feedbackcardid}`);
  const activeCardTop = activeCard.getBoundingClientRect().top;
  const cardsContainerAligment = activeWordElemTop - activeCardTop;

  clearActiveCards();

  if (activeCard !== null) {
    activeCard.classList.add(ACTIVE_FEEDBACK_CARD_CLASSNAME);
    cardsContainer.style.marginTop = '0px';

    const activeCardDomElement = activeCard.querySelector(`.feedbackCard`);
    activeCardDomElement.classList.remove('feedbackCardCollapsed');

    cardsContainer.style.marginTop = `${cardsContainerAligment}px`;
  }

  toggleCardOpacity();
};

export const toggleCardOpacity = () => {
  const sidebarElement = getSidebarElement();
  const cardsContainer = sidebarElement.querySelector(
    '.bodyMeasuresFeedbackWrapper'
  );
  const cardsContainerTitle = sidebarElement.querySelector('h4.text-muted');
  const cardsArray = [
    ...cardsContainer.querySelectorAll('.feedbackCardContainer'),
  ];

  cardsArray.forEach(e => {
    if (
      e.getBoundingClientRect().top <
      cardsContainerTitle.getBoundingClientRect().top +
        cardsContainerTitle.clientHeight
    ) {
      e.style.opacity = 0;
      e.style.transition = '0s all';
    } else {
      e.style.opacity = null;
      e.style.transition = null;
    }
  });
};

export const hideActiveCard = () => {
  const sidebarElement = document.querySelector(
    `.${SIDEBAR_WRAPPER_CLASSNAME}`
  );
  const activeCards = sidebarElement.querySelectorAll(
    `.${ACTIVE_FEEDBACK_CARD_CLASSNAME}`
  );

  const cardsContainer = sidebarElement.querySelector(
    '.bodyMeasuresFeedbackWrapper'
  );

  if (activeCards.length > 0) {
    cardsContainer.style.marginTop = '0px';
    toggleCardOpacity();

    activeCards.forEach(elem => {
      elem.classList.remove(ACTIVE_FEEDBACK_CARD_CLASSNAME);
      elem.querySelector('.ant-card').classList.add('feedbackCardCollapsed');
      elem.classList.remove('activeOutOfList');
    });

    markAllWordsInactive();
  }
};

export const getMarkedWordByWordId = cardId => {
  const selector = `mark[data-feedbackcardid="${cardId}"]`;
  return getEditorDocument().querySelector(selector);
};

export const markParagraphLengthCardActive = paragNumber => {
  const paragraphElement = getActiveDomByParagraphIndexNumber(paragNumber);
  alignCardWithWord(paragraphElement);
};

export const unHighlightAllParagraphs = () => {
  const doc = getEditorDocument();
  const allHighlightedParags = doc.querySelectorAll(
    `.${PARAGRAPH_HIGHLIGHT_ACTIVE}`
  );
  if (allHighlightedParags.length > 0) {
    allHighlightedParags.forEach(elem =>
      elem.classList.remove(PARAGRAPH_HIGHLIGHT_ACTIVE)
    );
  }
};

export const unHighlightParagraphById = paragIndex => {
  const paragraphElement = getActiveDomByParagraphIndexNumber(paragIndex);
  if (paragraphElement) {
    paragraphElement.classList.remove(PARAGRAPH_HIGHLIGHT_ACTIVE);
  }
};

export const highlightParagraphById = paragIndex => {
  const paragraphElement = getActiveDomByParagraphIndexNumber(paragIndex);
  if (paragraphElement) {
    paragraphElement.classList.add(PARAGRAPH_HIGHLIGHT_ACTIVE);
  }
};

export const markWordActiveInTitle = cardId => {
  const selector = `mark[data-feedbackcardid="${cardId}"]`;
  const mark = document.getElementById(TITLE_ID).querySelector(selector);

  if (mark) {
    markAllTitleWordsInactive();
    mark.classList.add('active');
  }
};
export const markWordInActiveInTitle = cardId => {
  const selector = `mark[data-feedbackcardid="${cardId}"]`;
  const mark = document.getElementById(TITLE_ID).querySelector(selector);

  if (mark) {
    markAllTitleWordsInactive();
    mark.classList.remove('active');
  }
};
export const markWordActiveByDataCardId = cardId => {
  const mark = getMarkedWordByWordId(cardId);
  if (mark) {
    markAllWordsInactive();
    alignCardWithWord(mark);
    mark.classList.add('active');
  }
};

export const unmarkWordByWordId = cardId => {
  const markedWordElem = getMarkedWordByWordId(cardId);
  if (markedWordElem) {
    const marker = new Mark(markedWordElem);
    marker.unmark();
  }
};

export const addWordToDictionary = (word, callback) => {
  const arClient = getArClient();
  arClient.addDictionary(word, res => {
    if (callback) callback(res);
  });
};

export const markSidebarActiveCard = () => {
  const sidebarElement = document.querySelector(
    `.${SIDEBAR_WRAPPER_CLASSNAME}`
  );
  const activeCards = sidebarElement.querySelectorAll(
    `.${ACTIVE_FEEDBACK_CARD_CLASSNAME}`
  );
  if (activeCards.length > 0) {
    sidebarElement.classList.add(SIDEBAR_WRAPPER_HAS_ACTIVE_CARD);
  }
};
export const unMarkSidebarActiveCard = () => {
  document
    .querySelector(`.${SIDEBAR_WRAPPER_CLASSNAME}`)
    .classList.remove(SIDEBAR_WRAPPER_HAS_ACTIVE_CARD);
};

export const unMarkAllActiveElements = paragraphId => {
  disableActiveUnderlinedWord(paragraphId);
  hideActiveCard();
  unMarkSidebarActiveCard();
};

export const hideHighlightByClassName = className => {
  const edElem = getEditorDocument();
  const markedWordElems = edElem.querySelectorAll(`mark${className}`);

  if (markedWordElems.length > 0) {
    markedWordElems.forEach(elem =>
      elem.classList.add(HIDDEN_HIGHLIGHTING_CLASSNAME)
    );
  }
};

export const showHighlightByClassName = className => {
  const edElem = getEditorDocument();
  const markedWordElems = edElem.querySelectorAll(`mark${className}`);

  if (markedWordElems.length > 0) {
    markedWordElems.forEach(elem =>
      elem.classList.remove(HIDDEN_HIGHLIGHTING_CLASSNAME)
    );
  }
};

export const hideHighlightByCardID = cardID => {
  const edElem = getEditorDocument();
  const markedWordElems = edElem.querySelectorAll(
    `[data-feedbackcardid=${cardID}]`
  );

  if (markedWordElems.length > 0) {
    markedWordElems.forEach(elem =>
      elem.classList.add(IGNORED_HIGHLIGHTING_CLASSNAME)
    );
  }
};

export const showHighlightByCardID = cardID => {
  const edElem = getEditorDocument();
  const markedWordElems = edElem.querySelectorAll(
    `[data-feedbackcardid=${cardID}`
  );

  if (markedWordElems.length > 0) {
    markedWordElems.forEach(elem =>
      elem.classList.remove(IGNORED_HIGHLIGHTING_CLASSNAME)
    );
  }
};

export const getMeasuresIDsFromMeasureCardsArrayRightRail = measuresArr => {
  const measureIDsArray = [];

  for (let i = 0; i < measuresArr.length; ++i)
    measureIDsArray.push(measuresArr[i].key);

  if (measureIDsArray.includes('RtParagraphLength')) {
    measureIDsArray.push('RtSpellingGrammar');
  }

  return measureIDsArray;
};

export const filterReplacementReasons = reasons => {
  return reasons.map(reason => {
    switch (reason) {
      case 'emotion':
        // Temporary, since ML call it emotion instead of arousal. Pav to fix this.
        return 'Arousal';
        break;
      case 'seo':
        return 'SEOValue';
        break;
      default:
        return _.capitalize(reason);
        break;
    }
  });
};
/* returns Array */
export const getTypeByMeasureName = (measureName, measureRankingFeedback) => {
  let cardCategory = [];
  if (measureRankingFeedback.data) {
    if (_.isArray(measureName)) {
      cardCategory = [];
      _.forEach(measureName, name => {
        const props = _.find(measureRankingFeedback.data.measures.BODY, {
          name,
        });

        cardCategory.push(props ? props.type : 'optional');
      });
    } else {
      switch (measureName) {
        case 'RtParagraphLength':
          const prop = _.find(measureRankingFeedback.data.measures.BODY, {
            name: 'PercentageParagraphsIdealWordCount',
          });
          cardCategory = [prop.type];
        case 'RtSpellingGrammar':
        case 'RtComplianceList':
          return 'critical';
        default:
          const props = _.find(measureRankingFeedback.data.measures.BODY, {
            name: measureName,
          });

          cardCategory = props ? [props.type] : ['optional'];
      }
    }
  }
  return _.uniq(cardCategory);
};
