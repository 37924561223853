import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import LogoSpinner from '../../common/LogoSpinner';

const BlueText = styled.span`
  margin-left: 5px;
  color: #1fb7e4;
`;

const RedText = styled.span`
  margin-left: 5px;
  color: #f37a7d;
`;

const ChartWrap = styled.div`
  width: 500px;
  height: 250px;
`;

const ChartJsOptions = {
  legend: {
    position: 'bottom',
    align: 'start',
  },
};

const trendChartJsOptions = {
  maintainAspectRatio: false,
  showLine: true,
  spanGaps: true,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    intersect: false,
  },
  plugins: {
    filler: {
      propagate: false,
    },
  },
  scales: {
    xAxes: [
      {
        reverse: true,
        display: false,
        gridLines: {
          color: 'rgba(0,0,0,0.05)',
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          stepSize: 500,
        },
        display: false,
        borderDash: [5, 5],
        gridLines: {
          color: 'rgba(0,0,0,0)',
          fontColor: '#fff',
        },
      },
    ],
  },
};

const TrendChartWrap = styled.div`
  width: 100%;
  height: 50px;
`;

export const getExpandedRowRender = (title, details) => {
  const { stats, trend: DetailsTrend } = details;
  const trend = _.cloneDeep(DetailsTrend);

  return (
    <Row>
      <Col xs={12} xl={6} className="py-5">
        <div className="p-5 border rounded">
          <div className="pb-5 border-bottom">
            <strong>{title}</strong>
          </div>
          <div className="pb-2 border-bottom">
            <a href={stats.url} rel="noopener noreferrer" target="_blank">
              {stats.url}
            </a>
          </div>

          <div className="d-flex flex-fill py-2 border-bottom">
            <div className="flex-fill">
              <strong>Profile Match:</strong>
              {stats.profileMatch ? (
                <BlueText>YES</BlueText>
              ) : (
                <RedText>NO</RedText>
              )}
            </div>
            <div className="flex-fill">
              <strong>Paragraph Length:</strong>
              {stats.perIdealWordCount ? (
                <BlueText>YES</BlueText>
              ) : (
                <RedText>NO</RedText>
              )}
            </div>
          </div>

          <div className="d-flex flex-fill py-2 border-bottom">
            <div className="flex-fill">
              <strong>Readability Match:</strong>
              {stats.readabilityMatch ? (
                <BlueText>YES</BlueText>
              ) : (
                <RedText>NO</RedText>
              )}
            </div>
            <div className="flex-fill">
              <strong>Emotion:</strong>
              {stats.emotion ? <BlueText>YES</BlueText> : <RedText>NO</RedText>}
            </div>
          </div>

          <div className="d-flex py-2 border-bottom">
            <div className="flex-fill">
              <strong>Word Count:</strong>
              {stats.wordCount ? (
                <BlueText>YES</BlueText>
              ) : (
                <RedText>NO</RedText>
              )}
            </div>
            <div className="flex-fill"></div>
          </div>

          <div className="pt-2">
            <div>
              <strong>Topics</strong>
            </div>
            <div>
              <span>{stats.topics}</span>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} xl={6} className="py-5">
        <div className="p-5 border rounded">
          <div className="pb-5 border-bottom">
            <strong className="">Comparison to the previous 30 days</strong>
          </div>

          <ChartWrap>
            <Line data={trend} options={ChartJsOptions} />
          </ChartWrap>
        </div>
      </Col>
    </Row>
  );
};

export const getLoader = withContainer => {
  const Loader = (
    <div className="d-flex justify-content-center">
      <LogoSpinner />
    </div>
  );

  if (withContainer) {
    return (
      <div className="container rounded container-shadow bg-white mb-5 px-0 py-5">
        {Loader}
      </div>
    );
  }

  return Loader;
};

export const getRowRenderTrend = rowTrend => {
  const trend = _.cloneDeep(rowTrend);

  return (
    <TrendChartWrap>
      <Line data={trend} options={trendChartJsOptions} />
    </TrendChartWrap>
  );
};

export const getGraphLabels = (limitStart, limitEnd) => {
  const labels = [];
  for (let i = limitEnd; i >= limitStart; i -= 1) {
    labels.push(`T-${i}`);
  }
  return labels;
};
