/* eslint-disable import/prefer-default-export */
import {
  LOGIN_SUCCESS,
  LOG_OUT,
  UPDATE_CUSTOM_WORD_LIST,
  UPDATE_IGNORE_WORD_LIST,
  UPDATE_COMPLIANCE_WORD_LIST,
  CONFIRM_LOGIN,
} from './constants/actionTypes';

const authChecker = store => next => action => {
  if (action.type === LOGIN_SUCCESS) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const session = {
      ...action.session,
      expiration: currentDate,
      email: action.email,
    };

    localStorage.setItem('session', JSON.stringify(session));
    localStorage.setItem('accountData', JSON.stringify(action.accountData));

    // let sessions = JSON.stringify(session);
  } else if (action.type === LOG_OUT) {
    localStorage.removeItem('session');
    localStorage.removeItem('parentSession');
    localStorage.removeItem('accountData');
    localStorage.removeItem('subscribeto');
    localStorage.removeItem('plan');
  }

  return next(action);
};

const alphabetSorter = store => next => action => {
  if (
    action.type === UPDATE_CUSTOM_WORD_LIST ||
    action.type === UPDATE_IGNORE_WORD_LIST ||
    action.type === UPDATE_COMPLIANCE_WORD_LIST
  ) {
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    let words = action.wordList.slice();

    words = words.filter(a => a.length > 0);
    words = words.sort((a, b) => {
      return a.localeCompare(b);
    });
    const result = {};
    alphabet.forEach(letter => {
      result[letter] = [];
      words.forEach(word => {
        if (word[0].toUpperCase() === letter) {
          result[letter].push(word);
        }
      });
    });
    action.wordList = result;
  }

  return next(action);
};

/**
 * middleware for custom debugging, to see what's going to the store
 * and where actions are triggered from
 */
const debugMiddleware = store => next => action => {
  // if (action.type === 'FEEDBACK_MEASURE_CLICKED') {
  // console.log(action);
  //   console.trace();
  // }

  return next(action);
};

export { authChecker, alphabetSorter, debugMiddleware };
