// API_HOST = "https://app.atomicreach.com"; // PRODUCTION
// API_HOST = "https://api.score.atomicreach.com"; // MAINTENANCE
// API_HOST = "https://api-staging.atomicreach.com"; // STAGING
// API_HOST = "https://dev.atomicreach.com"; // STAGING
let API_HOST = '';
let ENV = '';

switch (window.location.hostname) {
  case 'localhost':
    // API_HOST = 'http://localhost'; // to run against docker container api
    // API_HOST = 'https://api-hz.atomicreach.com';
    // API_HOST = 'https://api-dev.radintel.ai';
    // API_HOST = 'https://api-dev2.radintel.ai';
    // API_HOST = 'https://api-staging.atomicreach.com';
    // API_HOST = 'https://api-staging.atomicreach.com';
    // API_HOST = 'https://api-dev2.radintel.ai';
    // API_HOST = 'https://api.score.radintel.ai'; // Maintenance
    API_HOST = 'https://api-maint.radintel.ai'; // Maintenance
    // API_HOST = 'https://api.radintel.ai';
    // ENV = 'PRODUCTION';
    // ENV = 'STAGING';
    ENV = 'LOCAL';
    break;
  case 'dev-newui.atomicreach.com':
  case 'dev-newui.radintel.ai':
    // API_HOST = 'https://api.score.radintel.ai'; // Maintenance
    API_HOST = 'https://api-maint.radintel.ai'; // Maintenance
    // API_HOST = 'https://api-dev2.radintel.ai';
    // API_HOST = 'https://api.radintel.ai';
    // API_HOST = 'https://api-dev.atomicreach.com';
    // API_HOST = 'https://app.atomicreach.com';
    ENV = 'STAGING';
    break;
  case 'dev.radintel.ai':
    API_HOST = 'https://api-maint.radintel.ai'; // Maintenance
    // API_HOST = 'https://api-dev2.radintel.ai';
    ENV = 'STAGING';
    break;
  case 'new.radintel.ai':
  case 'app.radintel.ai':
    API_HOST = 'https://api.radintel.ai';
    // API_HOST = 'https://api-maint.radintel.ai'; // Maintenance
    ENV = 'PRODUCTION';
    break;
  default:
    // API_HOST = 'https://api-maint.radintel.ai'; // Maintenance
    API_HOST = 'https://api.radintel.ai';
    ENV = 'PRODUCTION';
    break;
}

export { API_HOST, ENV };
export const AUTHENTICATION_URL = `${API_HOST}/api/get-tokens/`;
export const ACP_AUTHENTICATION_URL = `${API_HOST}/api/acp-login/`;
export const SIGNUP_URL = `${API_HOST}/account/create/`;

/* EMAIL END POINTS */
export const EMAIL_DATA_CLIENT_URL = '/email-data/getEmailData';
export const EMAIL_CONTENT_CLIENT_URL = '/email-data/getEmailContent';
export const EMAIL_DATA_MEASURES_CLIENT_URL =
  '/email-data/getEmailMeasuresData';
export const EMAIL_DATA_UPDATE = '/email-data/updateEmailData';

/* BLOG POSTS END POINTS */
export const BLOG_POSTS_DATA_CLIENT_URL = '/posts/getAllPosts';
export const BLOG_POSTS_DATA_MEASURES_CLIENT_URL =
  '/posts/getPostsMeasuresData';
export const BLOG_POSTS_CONTENT_AR = '/posts/getPostById';
export const BLOG_POSTS_CONTENT_HUBSPOT = '/Hubspot/getBlogPost'; // "blogPostId"=>$postId
export const HUBSPOT_BLOG_INSTACES_LIST = '/Hubspot/getBlogs';
export const GET_GOAL_NAMES = '/Content/getGoals';
export const CREATE_NEW_HUBSPOT_POST = '/Hubspot/createNewBlogPost';

/* ADS END POINTS */
export const ADS_DATA_CLIENT_URL = '/adwords/get-ads-v2';
export const ADS_FOR_CAMPAIGNS_ADGROUPS_DATA_CLIENT_URL =
  '/adwords/get-ads-for-campaigns-and-adgroups';

export const GET_AD_PROFILE_SELECTION = '/adwords/get-ads-selection-data';

export const ADWORDS_TOTALS = '/adwords/get-ads-totals';
export const CAMPAIGNS_DATA_CLIENT_URL = '/adwords/get-campaigns';
export const PRODUCT_DESCRIPTIONS_DATA_CLIENT_URL =
  '/product-descriptions/get-data';
export const PRODUCT_DESCRIPTIONS_CATEGORIES_DATA_CLIENT_URL =
  '/product-categories/get-data';
export const PRODUCT_DESCRIPTIONS_ACCOUNT_DATA_URL = '/product-descriptions/';
export const ADGROUP_DATA_CLIENT_URL = '/adwords/get-ad-groups';
export const ADGROUP_CAMPAIGN_ID_DATA_CLIENT_URL =
  '/adwords/get-ad-groups-by-campaign-id';
export const GET_AD_BY_ID_URL = '/adwords/get-ad-by-id';
export const GET_AD_DRAFT_BY_ID_URL = '/editor-drafts/getDraftAd';
export const GET_AD_MEASURES_BY_ID = '/adwords/get-ads-measures-data';
export const SAVE_ADS_DRAFT_URL = '/editor-drafts/saveDraftsAds';
export const DELETE_ADS_DRAFT_URL = '/editor-drafts/deleteDraftsAds';
export const OPTIMIZE_AD_URL = '/service/OptimizeAds';
export const PERS_REQUEST1_URL = '/content/rewrite-diff';
export const PERS_REQUEST2_URL = '/content/merge-html-text';

export const PRODUCT_DESCRIPTIONS_FOR_CAMPAIGNS_PRODUCT_DESCRIPTION_GROUPS_DATA_CLIENT_URL =
  '/adwords/get-ads-for-campaigns-and-adgroups';

export const GET_PRODUCT_DESCRIPTION_PROFILE_SELECTION =
  '/product-descriptions/get-selected-data';

export const PRODUCT_DESCRIPTIONS_TOTALS = '/product-descriptions/get-totals';
export const PRODUCT_DESCRIPTION_GROUP_DATA_CLIENT_URL =
  '/product-descriptions/get-groups';
export const PRODUCT_DESCRIPTION_GROUP_CAMPAIGN_ID_DATA_CLIENT_URL =
  '/adwords/get-ad-groups-by-campaign-id';
export const GET_PRODUCT_DESCRIPTION_BY_ID_URL = '/adwords/get-ad-by-id';
export const GET_PRODUCT_DESCRIPTION_DRAFT_BY_ID_URL =
  '/editor-drafts/getDraftAd';
export const GET_PRODUCT_DESCRIPTION_MEASURES_BY_ID =
  '/product-descriptions/get-measures-data';
export const SAVE_PRODUCT_DESCRIPTIONS_DRAFT_URL =
  '/editor-drafts/saveDraftsAds';
export const DELETE_PRODUCT_DESCRIPTIONS_DRAFT_URL =
  '/editor-drafts/deleteDraftsAds';
export const OPTIMIZE_PRODUCT_DESCRIPTION_URL = '/service/OptimizeAds';
export const SERVICE_GET_SUMMARY = '/service/getSummary/';

export const GET_ALL_BATCH = '/batch/get-batches';
export const DELETE_BATCHES = '/batch/delete-batch-content';
export const SAVE_BATCH = '/batch/save-batch-content';
export const GET_ADS_BATCH = '/batch/get-batch-by-id';
export const UPDATE_BATCH_CONTENT = '/batch/update-batch-content';
export const UPDATE_BATCH_PROFILE = '/batch/update-batch-profile';
export const REMOVE_BATCH_CONTENT = '/batch/remove-batch-content';

export const GET_PRODUCT_DESCRIPTIONS_BATCH = '/batch/get-batch-by-id';

/* DRAFTS END POINTS */
export const DRAFTS_DATA_CLIENT_URL = '/editor-drafts/getDrafts';

/* Fetching All Drafts from accountId
 * Param: No Parameter
 * The Endpoint doesn't accept no parameters
 * */
export const POST_DRAFTS_DATA_CLIENT_URL = '/posts/getAllDrafts';
export const DRAFT_DATA_CLIENT_URL = '/editor-drafts/getDraft';
export const SAVE_DRAFT_DATA_CLIENT_URL = '/editor-drafts/saveDrafts';
export const UPDATE_DRAFT_STATUS_CLIENT_URL =
  '/editor-drafts/updateDraftStatus';
export const DELETE_DRAFTS = '/editor-drafts/deleteDrafts';

export const SAVE_HUBSPOT_EMAIL_DATA_CLIENT_URL = '/Hubspot/updateEmail';
export const SAVE_HUBSPOT_BLOG_DATA_CLIENT_URL = '/Hubspot/updateBlogPost';

export const TOPICS_RELATED_CLUSTERS_URL = '/topic/get-insights';
export const TOPICS_RELATED_CLUSTERS_URL_V2 = '/topic/getInsightsV2';

export const PERFORMANCE_DASHBOARD_URL = '/analyze/get-performance';

export const ENGAGEMENT_WORDS_URL = '/engagement/get-words';

/* PROFILE END POINTS */
export const PROFILE_DATA_CLIENT_URL = '/scoring-profile/getAllProfiles';
export const PROFILE_DATA_CLIENT_LITE_URL =
  '/scoring-profile/getAllProfilesLite';
export const PROFILE_MEASURES = '/scoring-profile/getScoringProfileDetails';
export const PROFILE_BUCKET_CORPUS =
  '/scoring-profile/getScoringProfileMeasureCorpus';
export const PROFILE_CONTENT = '/scoring-profile/getScoringProfileCorpus';
export const PROFILE_CREATE = '/scoring-profile/createScoringProfile';
export const ADD_CONTENTIDS = '/scoring-profile/addCorpusElements';
export const REMOVE_CONTENTIDS = '/scoring-profile/deleteCorpusElements';
export const PROFILES_DELETE = '/scoring-profile/deleteScoringProfiles';
export const PROFILES_ACTIVATE = '/scoring-profile/undoDeleteScoringProfiles';
export const PROFILE_DELETE = '/scoring-profile/deleteScoringProfile';
export const PROFILE_ACTIVATE = '/scoring-profile/undoDeleteScoringProfile';
export const GET_STANDARD_PROFILE = '/scoring-profile/getStandardProfile';

export const PROFILE_CALCULATE = '/scoring-profile/calculate';
export const PROFILE_CALCULATE_CUTOFF = '/scoring-profile/calculateCutOffScore';
export const PROFILE_UPDATE = '/scoring-profile/updateScoringProfileName';

/* SCRAPE ARTICLE */
export const SCRAPE_ARTICLE_URL = '/scrape/article'; // "postId"=>$postId

/* ACCOUNT SETTINGS */

export const ACCOUNT_FORGOT_PASSWORD = '/account/forgot-password';
export const ACCOUNT_RECOVER_PASSWORD = '/account/recover-password';
export const ACCOUNT_CHANGE_PASSWORD = '/account/change-password';
export const ACCOUNT_UPDATE_DETAILS = '/account/update-account-details';
export const ACCOUNT_REQUEST_TERMINATION = '/account/request-termination';

export const BILLING_GET_INFO = '/billing/getInfo';
export const BILLING_CANCEL = '/billing/cancel';
export const BILLING_GET_PORTAL_LINK = '/billing/getPortalLink';

export const GET_CHARGIFY_INFO = '/account/get-chargify-info';
export const GET_WORD_LIST = '/:list/list';
export const DELETE_WORD = '/:list/remove';
export const ADD_WORD = '/:list/add';

/* Sub Accounts */
export const GET_SUB_ACCOUNTS = '/partner/sub-accounts';
export const ADD_SUB_ACCOUNTS = '/partner/create-sub-account';
export const REMOVE_SUB_ACCOUNTS = '/partner/remove-sub-account';
export const UPDATE_SUB_ACCOUNT = '/partner/update-sub-account';

/* INTEGRATIONS */

export const CHECK_HUBSPOT = '/hubspot/check-connection';
export const HUBSPOT_AUTH_LINK = '/hubspot/get-auth-link';
export const HUBSPOT_CONNECT = '/hubspot/connect-account';
export const HUBSPOT_DISCONNECT = '/hubspot/disconnect';
export const HUBSPOT_FORM_SUBMIT = '/hubspot/form-submit';
export const HUBSPOT_IMPORT_DATA = '/Hubspot/uploadMetrics';

export const CHECK_MAILCHIMP = '/mail-chimp/isConnected';
export const MAILCHIMP_CONNECT = '/mail-chimp/connect';
export const MAILCHIMP_AUTH_LINK = '/mail-chimp/get-auth-link';
export const MAILCHIMP_DISCONNECT = '/mail-chimp/disconnect';

// export const CHECK_WORDPRESS = '/wordpress/check-connection';
export const CHECK_WORDPRESS = '/wordpress/getAllConnections';
export const WORDPRESS_DISCONNECT = '/wordpress/disconnect';
export const WORDPRESS_DISCONNECT_BY_SOURCE_ID =
  '/wordpress/disconnectBySourceId';
export const WORDPRESS_UPDATE_POSTS = '/wordpress/update-posts';
export const CREATE_NEW_WP_POST = '/wordpress/create-new-post';
export const BLOG_POSTS_CONTENT_WORDPRESS = '/wordpress/get-post-by-idv2'; // "postId"=>$postId

export const GET_CONTENT_LIST = '/source/list';
export const GET_CONTENT_URL = '/source/add-v2';
export const ADD_NEW_ARTICLE_URL = '/posts/addPostByUrl';
export const GET_SEARCH_CONSOLE_PROPERTIES_LIST =
  '/search-console/getProperties';

export const GET_GA_PROFILES = '/account/ga-profiles2';
export const UPDATE_GA_PROFILE = '/source/update';
export const ADD_GA_PROFILE = '/account/add-social-network';

export const IS_MARKETO_CONNECTED = '/marketo/isConnected';
export const MARKETO_CONNECT = '/marketo/connect';
export const MARKETO_GET_FOLDERS = '/marketo/getFolders';
export const MARKETO_DISCONNECT = '/marketo/disconnect';

export const MARKETO_UPDATE_CONFIGURATION = '/marketo/updateConfiguration';
export const CHECK_ADWORDS_CONNECTED = '/adwords/checkMultiConnection';
export const ADWORDS_CONNECT = '/adwords/connect-from-app';
export const ADWORDS_AUTH_LINKS = '/adwords/getAuthFromApp';
export const ADWORDS_DEFAULT_ACCOUNT = '/adwords/save-default-customer';
export const ADWORDS_ACTIVATE_CONNECTIONID = '/adwords/activateConnectionId';
export const ADWORDS_DISCONNECT_CONNECTIONID =
  '/adwords/disconnectConnectionId';
export const ADWORDS_DISCONNECT = '/adwords/disconnect';

// export const MARKETO_DISCONNECT = '/marketo/disconnect';
export const OPTIMIZE_PRODUCT_DESC_URL = '/service/OptimizeProductDescription';
export const ONE_CLICK_OPTIMIZE = '/service/OneClickOptimize2';
export const ATD_TEXT_ANALYZE_URL = '/text-analyze-v2/getATDInfo';

// Tableau AR Endpoint
export const TABLEAU_GETTOKEN_URL = '/tableau/getToken';

// logging
export const LOG_EVENT = `${API_HOST}/logging/log`;

export const SEARCHCONSOLE_AUTH_LINKS = '/search-console/getAuthFromApp';
export const SEARCHCONSOLE_CONNECT = '/search-console/connect-from-app';
export const SET_PROPERTY_TO_SOURCE = '/search-console/setProperty';

export const GOOGLE_ANALYTICS_AUTH_LINKS = '/analytics/getAuthFromApp';
export const GOOGLE_ANALYTICS_CONNECT = '/analytics/connectFromApp';

export const CHARGIFY_SUBSCRIPTION_VALIDATION =
  '/account/chargifySubscriptionValidation';

export const ANALYZE_GET_CONTENT = '/analyze/get-content';

export const ANALYZE_TRENDING = '/trending-topics/trends-v7';
