/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_BODY_TYPE, DEFAULT_TITLE_TYPE } from '../static/constants';

const initialState = {
  activeCardIdsArray: [],
  activeCardId: null,
  originalWord: null,
  replacedWord: null,
  replacedWordElem: null,
  activeMeasureTab: 'Body',
  activeTypeBody: DEFAULT_BODY_TYPE,
  activeTypeTitle: DEFAULT_TITLE_TYPE,
  categoryCountTitle: { critical: null, recommended: null, optional: null },
  categoryCountBody: { critical: null, recommended: null, optional: null },
};

const SidebarEditorSlice = createSlice({
  name: 'SidebarEditor',
  initialState,
  reducers: {
    resetActiveCard(state, action) {
      state.activeCardId = initialState.activeCardId;
      state.originalWord = initialState.originalWord;
      state.replacedWord = initialState.replacedWord;
      state.replacedWordElem = initialState.replacedWordElem;
    },
    resetActiveCardsArray(state, action) {
      state.activeCardIdsArray = initialState.activeCardIdsArray;
    },
    resetAll(state, action) {
      state.activeCardId = initialState.activeCardId;
      state.activeCardIdsArray = initialState.activeCardIdsArray;
      state.originalWord = initialState.originalWord;
      state.replacedWord = initialState.replacedWord;
      state.replacedWordElem = initialState.replacedWordElem;
      state.activeMeasureTab = initialState.activeMeasureTab;
      state.activeTypeBody = initialState.activeTypeBody;
      state.activeTypeTitle = initialState.activeTypeTitle;
      state.categoryCountTitle = initialState.categoryCountTitle;
      state.categoryCountBody = initialState.categoryCountBody;
    },
    activeCard(state, action) {
      const {
        payload: { activeCardId, originalWord, replacedWord, replacedWordElem },
      } = action;

      state.activeCardId = activeCardId;
      state.originalWord = originalWord;
      state.replacedWord = replacedWord;
      state.replacedWordElem = replacedWordElem;
    },
    addToActiveCardIdsArray(state, action) {
      const {
        payload: { activeCardId },
      } = action;
      state.activeCardIdsArray.push(activeCardId);
    },
    updateActiveMeasureTab(state, action) {
      const {
        payload: { activeTab },
      } = action;
      state.activeMeasureTab = activeTab;
    },
    updateActiveTypeBody(state, action) {
      const {
        payload: { activeTab },
      } = action;
      state.activeTypeBody = activeTab;
    },
    updateActiveTypeTitle(state, action) {
      const {
        payload: { activeTab },
      } = action;
      state.activeTypeTitle = activeTab;
    },
    updateCategoryCountTitle(state, action) {
      const {
        payload: { categoryCountTitle },
      } = action;

      state.categoryCountTitle = categoryCountTitle;
    },
    updateCategoryCountBody(state, action) {
      const {
        payload: { categoryCountBody },
      } = action;

      state.categoryCountBody = categoryCountBody;
    },
  },
});

export const {
  resetAll,
  resetActiveCardsArray,
  resetActiveCard,
  activeCard,
  addToActiveCardIdsArray,
  updateActiveMeasureTab,
  updateActiveTypeBody,
  updateActiveTypeTitle,
  updateCategoryCountTitle,
  updateCategoryCountBody,
} = SidebarEditorSlice.actions;

export const resetAllSidebarEditor = () => async dispatch => {
  dispatch(resetAll());
};

export const resetActiveCardId = () => async dispatch => {
  dispatch(resetActiveCard());
};

export const showConfirm = (
  activeCardId,
  originalWord,
  replacedWord,
  replacedWordElem
) => async (dispatch, getState) => {
  dispatch(
    activeCard({ activeCardId, originalWord, replacedWord, replacedWordElem })
  );
};

export const confirmCardId = activeCardId => async (dispatch, getState) => {
  dispatch(addToActiveCardIdsArray({ activeCardId }));
};

export const setActiveMeasureTab = activeTab => async (dispatch, getState) => {
  dispatch(updateActiveMeasureTab({ activeTab }));
};

export const setActiveTypeBody = activeTab => async (dispatch, getState) => {
  dispatch(updateActiveTypeBody({ activeTab }));
};

export const setActiveTypeTitle = activeTab => async (dispatch, getState) => {
  dispatch(updateActiveTypeTitle({ activeTab }));
};

export const setCategoryCountBody = (
  criticalCount,
  recommendedCount,
  optionalCount
) => async (dispatch, getState) => {
  dispatch(
    updateCategoryCountBody({
      categoryCountBody: {
        critical: criticalCount,
        recommended: recommendedCount,
        optional: optionalCount,
      },
    })
  );
};

export const setCategoryCountTitle = (
  criticalCount,
  recommendedCount,
  optionalCount
) => async (dispatch, getState) => {
  dispatch(
    updateCategoryCountTitle({
      categoryCountTitle: {
        critical: criticalCount,
        recommended: recommendedCount,
        optional: optionalCount,
      },
    })
  );
};

export default SidebarEditorSlice.reducer;
