import React from 'react';
import '../Emails/static/loading.css';
import configData from '../../config/loader';

const LogoSpinner = props => {
  const { loadingMessage } = props;
  const className =
    configData.loader.animation === 'spin' ? 'spinner-container' : '';
  return (
    <div className={className}>
      <img src={configData.loader.imagePath} alt="loading" />
      <p className="loading-message">{loadingMessage}</p>
    </div>
  );
};

export default LogoSpinner;
