export const ACTIVE_FEEDBACK_CARD_CLASSNAME = 'cardIsActive';
export const SIDEBAR_WRAPPER_CLASSNAME = 'measureSuggestionsWrapper';
export const SIDEBAR_WRAPPER_HAS_ACTIVE_CARD = 'hasActiveCard';
export const HIDDEN_HIGHLIGHTING_CLASSNAME = 'hiddenHighlighting';
export const IGNORED_HIGHLIGHTING_CLASSNAME = 'ignoredHighlighting';
export const SPELLING_GRAMMAR = 'RtSpellingGrammar';
export const PARAGRAPH_LENGTH = 'RtParagraphLength';
export const COMPLIANCE_LIST = 'RtComplianceList';
export const PARAGRAPH_HIGHLIGHT_ACTIVE = 'paragHighlightActive';
export const DEFAULT_ACTIVE_BODY_MEASURES_ARRAY = [
  SPELLING_GRAMMAR,
  PARAGRAPH_LENGTH,
  COMPLIANCE_LIST,
  'Readability',
  'Arousal',
  'RtTopicDensity',
  'RtWritingStyle',
  'SEOValue',
  'PowerWordDensity',
];
export const TITLE_ID = 'custom-title';
export const TITLE_SPELLING_GRAMMAR_MISTAKES =
  'title_TitleSpellingGrammarMistakes_';
export const DEFAULT_BODY_TYPE = 'critical';
export const DEFAULT_TITLE_TYPE = 'critical';
export const MEASURE_TYPE_MAP = {
  critical: 'critical',
  recommended: 'suggested',
  optional: 'optional',
};
