import {
  EDITOR_CONTENT_CHANGE,
  TOGGLE_DISTRACTION_FREE,
  UPDATE_PARAGRAPHS,
  CHECK_PARAGRAPH,
  EDITOR_CONTENT_TYPE,
  UNCHECK_PARAGRAPH,
  UPDATE_PARAGRAPH_CONTENT,
  UPDATE_PARAGRAPH_ANALYZE_RESULT,
  UPDATE_TITLE_VALUE,
  UPDATE_SELECTED_PROFILE,
  UPDATE_LOADED_CONTENT_DETAILS,
  RESET_EDITOR,
  GET_TITLE_FEEDBACK,
  GET_DOCUMENT_ANALYZE_FEEDBACK,
  FEEDBACK_MEASURE_CLICKED,
  UPDATE_ACTIVE_PARAGRAPH,
  GET_DRAFTS_REQUEST,
  GET_DRAFTS_SUCCESS,
  GET_DRAFTS_FAILURE,
  SAVE_DRAFT_REQUEST,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_FAILURE,
  SAVE_HUBSPOT_EMAIL_REQUEST,
  SAVE_HUBSPOT_EMAIL_SUCCESS,
  SAVE_HUBSPOT_EMAIL_FAILURE,
  UPDATE_DRAFT_STATUS_REQUEST,
  UPDATE_DRAFT_STATUS_SUCCESS,
  UPDATE_EMAIL_LIST,
  GET_TITLE_DOCUMENT_ANALYZE_FEEDBACK,
  TITLE_DOCUMENT_LOADING,
  UPDATE_EDITOR_CHANGE_STATE,
  SAVE_WORDPRESS_REQUEST,
  SAVE_WORDPRESS_SUCCESS,
  SAVE_WORDPRESS_FAILURE,
  CALL_OPTIMIZE_API,
  OPTIMIZE_API_FEEDBACK,
  OPTIMIZE_API_TRIGGERED,
  SHOW_OPTIMIZE_MISMATCH_MSG,
  UPDATE_ACTIVE_MEASURE_ANALYZE_STATUS,
  CLONELOADING,
  TOGGLE_OPTIMIZE_BUTTON,
  TOGGLE_REVERT_BUTTON,
  PARAGRAPH_HIGHLIGHTING_TRIGGER,
  TOGGLE_PERSONABLE_PLUGIN,
  HIGHLIGHTING_TOGGLE_ACTIVE_MEASURE,
  HIGHLIGHTING_TOGGLE_ALL_MEASURES,
  MEASURE_RANKING_FEEDBACK,
  MEASURE_RANKING_LOADING,
  DRAFT_LOADING,
  OPTIMIZE_FEEDBACK,
  OPTIMIZE_FEEDBACK_IS_LOADING,
  ADD_TO_REJECT_LIST,
  ADD_TO_RECENTLY_ADDED_TO_LISTS_LIST,
  REMOVE_FROM_REJECT_LIST,
  HIGHLIGHTING_ACTIVE_PARAGRAPH,
  REGION_SUGGESTION_FEEDBACK,
  REGION_SUGGESTION_IS_LOADING,
  REGION_SUGGESTION_IS_ACTIVE,
  OPTIMIZE_FEEDBACK_REPLACEMENT_COUNT,
  REGION_SUGGESTION_SET_ACTIVE,
  REGION_SUGGESTION_SET_INACTIVE,
  RESET_API_FEEDBACK,
  OPTIMIZE_FEEDBACK_FILTERED,
  TITLE_OPTIMIZE_FEEDBACK_FILTERED,
  OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS,
  OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS_RESET,
  TITLE_REPHRASE,
  TITLE_REPHRASE_IS_LOADING,
} from '../constants/actionTypes';
import { isEmpty } from '../components/common/writingEnvironment/EditorLib';
import { DEFAULT_ACTIVE_BODY_MEASURES_ARRAY } from '../components/common/writingEnvironment/static/constants';

const initialState = {
  activeMeasureForHighlighting: {
    feedback: {},
    measureName: '',
  },
  content: '',
  contentId: '',
  contentIntegrationType: '',
  distractionFree: false,
  paragraphs: [],
  title: {
    value: '',
  },
  profileSelected: null,
  callTitleDocumentFlag: false,
  analyzeFeedback: {},
  titleFeedback: {},
  currentParagraph: null,
  optimizeApi: {
    // This is the old optimize feedback.
    isLoading: false,
    triggered: false,
    showOptimizeMisMatchMsg: false,
    enableOptimizeButton: false,
    enableRevertButton: false,
    tokens: [],
    tokensCount: 0,
  },
  updatingDraftStatus: false,
  savingDrafts: false,
  savingDraftsError: false,
  savedDraft: false,
  documentAnalyzeFeedback: null,
  isModified: false,
  titleDocumentFeedback: {},
  titleDocumentLoading: false,
  loading: false,
  contentType: 'posts',
  triggerParagraphHighlighting: false,

  // Flag to turn on/off personable plugin
  isPersonableActive: false,
  measureRankLoading: false,
  draftLoading: false,
  measureRankingFeedback: {},
  qualityScore: { valueNow: 0.0, valueMax: 0.0 },
  bodyScore: { valueNow: 0.0, valueMax: 0.0 },
  titleScore: { valueNow: 0.0, valueMax: 0.0 },
  titleRePhrase: {},
  titleRePhraseIsLoading: false,
  highlighting: {
    activeParagraph: null,
    allMeasuresEnabled: true,
    activeBodyMeasures: DEFAULT_ACTIVE_BODY_MEASURES_ARRAY,
    activeTitleMeasures: [],
  },
  optimizeFeedback: {
    data: {},
    isLoading: false,
    replacementWordsCount: null,
    filteredData: {},
    acceptedCardIds: [],
  },
  titleOptimizeFeedback: {
    filteredData: [],
  },
  regionSuggestionFeedback: {
    data: {},
    isLoading: false,
    isActive: false,
  },
  ignoreList: [],
  recentlyAddedToListsList: [],
};

const editorApp = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TITLE_VALUE:
      return {
        ...state,
        title: { ...state.title, value: action.value },
      };
    case EDITOR_CONTENT_CHANGE:
      return {
        ...state,
        content: action.content,
      };
    case EDITOR_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.contentType,
      };
    case TOGGLE_DISTRACTION_FREE:
      return {
        ...state,
        distractionFree: !state.distractionFree,
      };
    case UPDATE_PARAGRAPHS:
      return {
        ...state,
        paragraphs: action.paragraphs.slice(),
      };
    case UPDATE_PARAGRAPH_CONTENT:
      return {
        ...state,
        paragraphs: state.paragraphs.map((paragraph, i) => {
          if (i === action.index) {
            return { ...paragraph, content: action.content };
          }
          return paragraph;
        }),
      };
    case UPDATE_PARAGRAPH_ANALYZE_RESULT:
      return {
        ...state,
        paragraphs: state.paragraphs.map((paragraph, i) => {
          if (i === action.index) {
            return { ...paragraph, result: action.result };
          }
          return paragraph;
        }),
      };
    case CHECK_PARAGRAPH:
      return {
        ...state,
        paragraphs: state.paragraphs.map((paragraph, i) => {
          if (i === action.index) {
            return { ...paragraph, isChecked: true };
          }
          return paragraph;
        }),
      };
    case UNCHECK_PARAGRAPH:
      return {
        ...state,
        paragraphs: state.paragraphs.map((paragraph, i) => {
          if (i === action.index) {
            return { ...paragraph, isChecked: false };
          }
          return paragraph;
        }),
      };
    case UPDATE_SELECTED_PROFILE:
      return {
        ...state,
        profileSelected: action.profile,
      };
    case GET_TITLE_FEEDBACK:
      // deprecated
      return {
        ...state,
        titleFeedback: action.feedback,
      };
    case UPDATE_LOADED_CONTENT_DETAILS:
      return {
        ...state,
        contentId: action.contentId,
        contentIntegrationType: action.integrationType,
      };
    case GET_DOCUMENT_ANALYZE_FEEDBACK:
      // deprecated
      return {
        ...state,
        documentAnalyzeFeedback: action.feedback,
      };
    case GET_TITLE_DOCUMENT_ANALYZE_FEEDBACK:
      // it is best if we can filter the response here.
      return {
        ...state,
        titleDocumentFeedback: action.feedback,
        titleFeedback: action.feedback,
        documentAnalyzeFeedback: action.feedback,
      };
    case TITLE_DOCUMENT_LOADING:
      return {
        ...state,
        titleDocumentLoading: action.status,
      };
    case FEEDBACK_MEASURE_CLICKED:
      return {
        ...state,
        activeMeasureForHighlighting: {
          feedback: !isEmpty(action.activeMeasureForHighlighting.feedback)
            ? { ...action.activeMeasureForHighlighting.feedback }
            : {},
          measureName: action.activeMeasureForHighlighting.measureName,
        },
      };
    case UPDATE_ACTIVE_PARAGRAPH:
      return {
        ...state,
        currentParagraph: action.index,
        activeMeasureForHighlighting: action.activeMeasureForHighlighting,
      };
    case CALL_OPTIMIZE_API:
      return {
        ...state,
        optimizeApi: {
          ...state.optimizeApi,
          isLoading: action.index,
          triggered: true, // always keep it true
        },
      };
    case SHOW_OPTIMIZE_MISMATCH_MSG:
      return {
        ...state,
        optimizeApi: {
          ...state.optimizeApi,
          showOptimizeMisMatchMsg: action.index,
        },
      };
    case OPTIMIZE_API_TRIGGERED:
      return {
        ...state,
        optimizeApi: {
          ...state.optimizeApi,
          triggered: action.triggered,
        },
      };
    case TOGGLE_OPTIMIZE_BUTTON:
      return {
        ...state,
        optimizeApi: {
          ...state.optimizeApi,
          enableOptimizeButton: action.enabled,
        },
      };
    case TOGGLE_REVERT_BUTTON:
      return {
        ...state,
        optimizeApi: {
          ...state.optimizeApi,
          enableRevertButton: action.enabled,
        },
      };
    case OPTIMIZE_API_FEEDBACK:
      return {
        ...state,
        optimizeApi: {
          ...state.optimizeApi,
          tokens: action.index,
          tokensCount: action.count,
        },
      };
    case GET_DRAFTS_REQUEST:
      return {
        ...state,
        fetchingDrafts: true,
      };

    case GET_DRAFTS_SUCCESS:
      return {
        ...state,
        fetchingDrafts: false,
        drafts: action.response.data,
      };

    case GET_DRAFTS_FAILURE:
      return {
        ...state,
        fetchingDrafts: false,
        fetchingDraftsError: true,
      };
    case UPDATE_EMAIL_LIST:
      return {
        ...state,
        savedDraft: false,
      };
    case SAVE_DRAFT_REQUEST:
      return {
        ...state,
        savingDrafts: true,
        savedDraft: true,
      };
    case SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        savingDrafts: false,
      };
    case SAVE_DRAFT_FAILURE:
      return {
        ...state,
        savingDrafts: false,
        savingDraftsError: action.error,
      };
    case SAVE_HUBSPOT_EMAIL_REQUEST:
      return {
        ...state,
        savingHubspot: true,
      };
    case SAVE_HUBSPOT_EMAIL_SUCCESS:
      return {
        ...state,
        savingHubspot: false,
      };
    case SAVE_HUBSPOT_EMAIL_FAILURE:
      return {
        ...state,
        savingHubspot: false,
      };
    case SAVE_WORDPRESS_REQUEST:
      return {
        ...state,
        savingWordPress: true,
      };
    case SAVE_WORDPRESS_SUCCESS:
      return {
        ...state,
        savingWordPress: false,
      };
    case SAVE_WORDPRESS_FAILURE:
      return {
        ...state,
        savingWordPress: false,
      };

    case UPDATE_DRAFT_STATUS_REQUEST:
      return {
        ...state,
        updatingDraftStatus: true,
      };
    case UPDATE_DRAFT_STATUS_SUCCESS:
      return {
        ...state,
        updatingDraftStatus: false,
      };
    case RESET_EDITOR:
      return initialState;
    case RESET_API_FEEDBACK:
      return {
        ...state,
        activeMeasureForHighlighting: initialState.activeMeasureForHighlighting,
        analyzeFeedback: initialState.analyzeFeedback,
        titleFeedback: initialState.titleFeedback,
        currentParagraph: initialState.currentParagraph,
        documentAnalyzeFeedback: initialState.documentAnalyzeFeedback,
        isPersonableActive: initialState.isPersonableActive,
        measureRankLoading: initialState.measureRankLoading,
        draftLoading: initialState.draftLoading,
        measureRankingFeedback: initialState.measureRankingFeedback,
        qualityScore: initialState.qualityScore,
        bodyScore: initialState.bodyScore,
        titleScore: initialState.titleScore,
        highlighting: initialState.highlighting,
        optimizeFeedback: initialState.optimizeFeedback,
        regionSuggestionFeedback: initialState.regionSuggestionFeedback,
        ignoreList: initialState.ignoreList,
        paragraphs: state.paragraphs.map((paragraph, i) => {
          return { content: paragraph.content, isChecked: false };
        }),
      };
    case UPDATE_EDITOR_CHANGE_STATE:
      return {
        ...state,
        isModified: action.isEdited,
      };
    case UPDATE_ACTIVE_MEASURE_ANALYZE_STATUS:
      return {
        ...state,
        activeMeasureForHighlighting: {
          ...state.activeMeasureForHighlighting,
          analyzing: action.status,
        },
      };
    case CLONELOADING:
      return {
        ...state,
        loading: action.loadingState,
      };
    case PARAGRAPH_HIGHLIGHTING_TRIGGER:
      return {
        ...state,
        triggerParagraphHighlighting: action.status,
      };

    // Personable plugin toggle flag
    case TOGGLE_PERSONABLE_PLUGIN:
      return {
        ...state,
        isPersonableActive: action.enabled,
      };

    case HIGHLIGHTING_TOGGLE_ACTIVE_MEASURE:
      return {
        ...state,
        highlighting: {
          ...state.highlighting,
          activeBodyMeasures: action.measuresArray,
          allMeasuresEnabled: action.enabled,
        },
      };

    case HIGHLIGHTING_TOGGLE_ALL_MEASURES:
      return {
        ...state,
        highlighting: {
          ...state.highlighting,
          allMeasuresEnabled: action.enabled,
          activeBodyMeasures: action.measuresArray,
        },
      };

    case HIGHLIGHTING_ACTIVE_PARAGRAPH:
      return {
        ...state,
        highlighting: {
          ...state.highlighting,
          activeParagraph: action.paragraphIndex,
        },
      };

    case MEASURE_RANKING_LOADING:
      return {
        ...state,
        measureRankLoading: action.status,
      };

    case DRAFT_LOADING:
      return {
        ...state,
        draftLoading: action.status,
      };

    case MEASURE_RANKING_FEEDBACK:
      return {
        ...state,
        measureRankingFeedback: action.feedback,
      };

    case OPTIMIZE_FEEDBACK_IS_LOADING:
      return {
        ...state,
        optimizeFeedback: {
          ...state.optimizeFeedback,
          isLoading: action.status,
        },
      };

    case OPTIMIZE_FEEDBACK:
      return {
        ...state,
        optimizeFeedback: {
          ...state.optimizeFeedback,
          data: action.feedback.data,
        },
      };

    case TITLE_OPTIMIZE_FEEDBACK_FILTERED:
      return {
        ...state,
        titleOptimizeFeedback: {
          ...state.titleOptimizeFeedback,
          filteredData: action.filteredFeedback,
        },
      };
    case OPTIMIZE_FEEDBACK_FILTERED:
      return {
        ...state,
        optimizeFeedback: {
          ...state.optimizeFeedback,
          filteredData: action.filteredFeedback,
        },
      };

    case OPTIMIZE_FEEDBACK_REPLACEMENT_COUNT:
      return {
        ...state,
        optimizeFeedback: {
          ...state.optimizeFeedback,
          replacementWordsCount: action.count,
        },
      };

    case OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS:
      return {
        ...state,
        optimizeFeedback: {
          ...state.optimizeFeedback,
          acceptedCardIds: state.optimizeFeedback.acceptedCardIds.concat(
            action.cardId
          ),
        },
      };

    case OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS_RESET:
      return {
        ...state,
        optimizeFeedback: {
          ...state.optimizeFeedback,
          acceptedCardIds: [],
        },
      };

    case TITLE_REPHRASE:
      return {
        ...state,
        titleRePhrase: action.titleRePhrase,
      };

    case TITLE_REPHRASE_IS_LOADING:
      return {
        ...state,
        titleRePhraseIsLoading: action.status,
      };

    case REGION_SUGGESTION_FEEDBACK:
      return {
        ...state,
        regionSuggestionFeedback: {
          ...state.regionSuggestionFeedback,
          data: action.feedback.data,
        },
      };

    case REGION_SUGGESTION_IS_LOADING:
      return {
        ...state,
        regionSuggestionFeedback: {
          ...state.regionSuggestionFeedback,
          isLoading: action.status,
        },
      };

    case REGION_SUGGESTION_IS_ACTIVE:
      return {
        ...state,
        regionSuggestionFeedback: {
          ...state.regionSuggestionFeedback,
          isActive: !state.regionSuggestionFeedback.isActive,
        },
      };

    case REGION_SUGGESTION_SET_ACTIVE:
      return {
        ...state,
        regionSuggestionFeedback: {
          ...state.regionSuggestionFeedback,
          isActive: true,
        },
      };

    case REGION_SUGGESTION_SET_INACTIVE:
      return {
        ...state,
        regionSuggestionFeedback: {
          ...state.regionSuggestionFeedback,
          isActive: false,
        },
      };

    case ADD_TO_REJECT_LIST:
      return {
        ...state,
        ignoreList: state.ignoreList.concat(action.wordId),
      };

    case REMOVE_FROM_REJECT_LIST:
      return {
        ...state,
        ignoreList: state.ignoreList.filter(e => e !== action.wordId),
      };

    case ADD_TO_RECENTLY_ADDED_TO_LISTS_LIST:
      return {
        ...state,
        recentlyAddedToListsList: state.recentlyAddedToListsList.concat(
          action.wordId
        ),
      };

    default:
      return state;
  }
};

export default editorApp;
