// Login relevant action types
export const TRY_LOGIN = 'TRY_LOGIN';
export const TRY_AUTO_LOGIN = 'TRY_AUTO_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CONFIRM_LOGIN = 'CONFIRM_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_ALERT = 'LOGIN_ALERT';
export const LOG_OUT = 'LOG_OUT';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE';

// Emails module relevant action types
export const RESET_EMAIL_DATA = 'RESET_EMAIL_DATA';
export const GET_EMAILS_REQUEST = 'GET_EMAILS_REQUEST';
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS';
export const GET_EMAILS_FAILURE = 'GET_EMAILS_FAILURE';
export const GET_EMAILMEASURES_REQUEST = 'GET_EMAILMEASURES_REQUEST';
export const GET_EMAILMEASURES_SUCCESS = 'GET_EMAILMEASURES_SUCCESS';
export const GET_EMAILMEASURES_FAILURE = 'GET_EMAILMEASURES_FAILURE';
export const EMAIL_LOADING_DONE = 'EMAIL_LOADING_DONE';
export const EMAILMEASURES_LOADING_DONE = 'EMAILMEASURES_LOADING_DONE';
export const SET_EMAIL_PAGE = 'SET_EMAIL_PAGE';

// Blog Post module relevant action types
export const RESET_BLOG_POSTS_DATA = 'RESET_BLOG_POSTS_DATA';
export const GET_BLOG_POSTS_REQUEST = 'GET_BLOG_POSTS_REQUEST';
export const GET_BLOG_POSTS_MEASURES_REQUEST =
  'GET_BLOG_POSTS_MEASURES_REQUEST';
export const GET_BLOG_POSTS_MEASURES_SUCCESS =
  'GET_BLOG_POSTS_MEASURES_SUCCESS';
export const GET_BLOG_POSTS_SUCCESS = 'GET_BLOG_POSTS_SUCCESS';
export const GET_PROFILE_BLOG_POST_SUCCESS = 'GET_PROFILE_BLOG_POST_SUCCESS';
export const BLOG_POSTS_LOADING_DONE = 'BLOG_POSTS_LOADING_DONE';
export const BLOG_POSTS_MEASURES_LOADING_DONE =
  'BLOG_POSTS_MEASURES_LOADING_DONE';
export const RE_SCRAPE_REQUEST = 'RE_SCRAPE_REQUEST';
export const RE_SCRAPE_SUCCESS = 'RE_SCRAPE_SUCCESS';
export const RE_SCRAPE_FAILURE = 'RE_SCRAPE_FAILURE';
export const SCRAPE_ARTICLE = 'SCRAPE_ARTICLE';

// ads module actions

export const RESET_ADS_DATA = 'RESET_ADS_DATA';
export const GET_ADS_REQUEST = 'GET_ADS_REQUEST';
export const GET_ADS_FOR_CAMPAIGNS_AND_ADGROUPS_REQUEST =
  'GET_ADS_FOR_CAMPAIGNS_AND_ADGROUPS_REQUEST';
export const GET_ADS_FOR_CAMPAIGNS_AND_ADGROUPS_SUCCESS =
  'GET_ADS_FOR_CAMPAIGNS_AND_ADGROUPS_SUCCESS';
export const GET_PROFILE_ADS_REQUEST = 'GET_PROFILE_ADS_REQUEST';
export const GET_AD_TOTALS_REQUEST = 'GET_AD_TOTALS_REQUEST';
export const GET_ADGROUPS_REQUEST = 'GET_ADGROUPS_REQUEST';
export const GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST';
export const GET_ADS_MEASURES_REQUEST = 'GET_ADS_MEASURES_REQUEST';
export const GET_ADS_MEASURES_SUCCESS = 'GET_ADS_MEASURES_SUCCESS';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_TOTALS = 'GET_ADS_TOTALS';
export const ADS_SELECTION_REQUEST = 'ADS_SELECTION_REQUEST';
export const ADS_SELECTION_SUCCESS = 'ADS_SELECTION_SUCCESS';
export const GET_ADGROUPS_SUCCESS = 'GET_ADGROUPS_SUCCESS';
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const ADS_LOADING_START = 'ADS_LOADING_START';
export const ADS_LOADING_DONE = 'ADS_LOADING_DONE';
export const ADS_MEASURES_LOADING_DONE = 'ADS_MEASURES_LOADING_DONE';
export const GET_PROFILES_REQUEST_ADS = 'GET_PROFILES_REQUEST_ADS';
export const GET_PROFILES_SUCCESS_ADS = 'GET_PROFILES_SUCCESS_ADS';
export const GET_PROFILES_FAILURE_ADS = 'GET_PROFILES_FAILURE_ADS';
export const ACTIVATE_PROFILES_REQUEST_ADS = 'ACTIVATE_PROFILES_REQUEST_ADS';
export const ACTIVATE_PROFILES_SUCCESS_ADS = 'ACTIVATE_PROFILES_SUCCESS_ADS';
export const ACTIVATE_PROFILES_FAILURE_ADS = 'ACTIVATE_PROFILES_FAILURE_ADS';
export const DELETE_PROFILES_REQUEST_ADS = 'DELETE_PROFILES_REQUEST_ADS';
export const DELETE_PROFILES_SUCCESS_ADS = 'DELETE_PROFILES_SUCCESS_ADS';
export const DELETE_PROFILES_FAILURE_ADS = 'DELETE_PROFILES_FAILURE_ADS';
export const GET_PROFILE_CONTENT_REQUEST_ADS =
  'GET_PROFILE_CONTENT_REQUEST_ADS';
export const GET_PROFILE_CONTENT_SUCCESS_ADS =
  'GET_PROFILE_CONTENT_SUCCESS_ADS';
export const GET_PROFILE_CONTENT_FAILURE_ADS =
  'GET_PROFILE_CONTENT_FAILURE_ADS';

export const GET_PROFILE_BUCKET_CORPUS_REQUEST_ADS =
  'GET_PROFILE_BUCKET_CORPUS_REQUEST_ADS';
export const GET_PROFILE_BUCKET_CORPUS_SUCCESS_ADS =
  'GET_PROFILE_BUCKET_CORPUS_SUCCESS_ADS';
export const GET_PROFILE_BUCKET_CORPUS_FAILURE_ADS =
  'GET_PROFILE_BUCKET_CORPUS_FAILURE_ADS';

export const CREATE_PROFILES_REQUEST_ADS = 'CREATE_PROFILES_REQUEST_ADS';
export const CREATE_PROFILES_SUCCESS_ADS = 'CREATE_PROFILES_SUCCESS_ADS';
export const CREATE_PROFILES_FAILURE_ADS = 'CREATE_PROFILES_FAILURE_ADS';
export const ADDED_CONTENT_REQUEST_ADS = 'ADDED_CONTENT_REQUEST_ADS';
export const ADDED_CONTENT_SUCCESS_ADS = 'ADDED_CONTENT_SUCCESS_ADS';
export const ADDED_CONTENT_FAILURE_ADS = 'ADDED_CONTENT_FAILURE_ADS';
export const REMOVE_CONTENT_REQUEST_ADS = 'REMOVE_CONTENT_REQUEST_ADS';
export const REMOVE_CONTENT_SUCCESS_ADS = 'REMOVE_CONTENT_SUCCESS_ADS';
export const REMOVE_CONTENT_FAILURE_ADS = 'REMOVE_CONTENT_FAILURE_ADS';
export const GET_PROFILE_MEASURES_REQUEST_ADS =
  'GET_PROFILE_MEASURES_REQUEST_ADS';
export const GET_PROFILE_MEASURES_SUCCESS_ADS =
  'GET_PROFILE_MEASURES_SUCCESS_ADS';
export const GET_PROFILE_MEASURES_FAILURE_ADS =
  'GET_PROFILE_MEASURES_FAILURE_ADS';
export const GET_PROFILE_MEASURES_DETAILS_REQUEST_ADS =
  'GET_PROFILE_MEASURES_DETAILS_REQUEST_ADS';
export const GET_PROFILE_MEASURES_DETAILS_FAILURE_ADS =
  'GET_PROFILE_MEASURES_DETAILS_FAILURE_ADS';
export const PROFILE_CALCULATE_REQUEST_ADS = 'PROFILE_CALCULATE_REQUEST_ADS';
export const PROFILE_CALCULATE_SUCCESS_ADS = 'PROFILE_CALCULATE_SUCCESS_ADS';
export const PROFILE_CALCULATE_FAIL_ADS = 'PROFILE_CALCULATE_FAIL_ADS';
export const GET_PROFILE_ADS_SUCCESS = 'GET_PROFILE_ADS_SUCCESS';
export const UPDATE_PROFILE_NAME_ADS = 'UPDATE_PROFILE_NAME_ADS';
export const GET_PROFILE_MEASURES_DETAILS_SUCCESS_ADS =
  'GET_PROFILE_MEASURES_DETAILS_SUCCESS_ADS';
export const UPDATE_ADS_EDITOR_ORIGINAL_AD = 'UPDATE_ADS_EDITOR_ORIGINAL_AD';
export const SELECT_EDITOR_PROFILE = 'SELECT_EDITOR_PROFILE';
export const SWITCH_SIDEBAR_EDITOR_MODE = 'SWITCH_SIDEBAR_EDITOR_MODE';
export const UPDATE_ORIGINAL_AD_ALL_FIELDS = 'UPDATE_ORIGINAL_AD_ALL_FIELDS';
export const SELECT_ADS_EDITOR_CAMPAIGN = 'SELECT_ADS_EDITOR_CAMPAIGN';
export const SELECT_ADS_EDITOR_AD_GROUP = 'SELECT_ADS_EDITOR_AD_GROUP';
export const SELECT_ADS_CONNECTION = 'SELECT_ADS_CONNECTION';
export const RECEIVE_ADS_EDITOR_CAMPAIGNS = 'RECEIVE_ADS_EDITOR_CAMPAIGNS';
export const RECEIVE_EDITOR_AD_GROUPS = 'RECEIVE_EDITOR_AD_GROUPS';
export const GET_ADS_PROFILE_SUCCESS = 'GET_ADS_PROFILE_SUCCESS';
export const SELECT_ADS_EDITOR_CAMPAIGN_NAME =
  'SELECT_ADS_EDITOR_CAMPAIGN_NAME';
export const SELECT_ADS_EDITOR_AD_GROUP_NAME =
  'SELECT_ADS_EDITOR_AD_GROUP_NAME';
export const DESELECT_EDITOR_PROFILE = 'DESELECT_EDITOR_PROFILE';
export const ADD_OPTIMIZED_AD = 'ADD_OPTIMIZED_AD';
export const RESET_OPTIMIZED_AD = 'RESET_OPTIMIZED_AD';
export const RESET_ORIGINAL_AD = 'RESET_ORIGINAL_AD';
export const POP_OPTIMIZED_AD = 'POP_OPTIMIZED_AD';
export const RESET_EDITOR_PROFILES = 'RESET_EDITOR_PROFILES';
export const SELECTED_PROFILE_FOR_MUTILPLE_ADS =
  'SELECTED_PROFILE_FOR_MUTILPLE_ADS';
export const SELECT_MULTI_EDITOR_PROFILE = 'SELECT_MULTI_EDITOR_PROFILE';
export const UPDATE_AD_REPLACEMENT = 'UPDATE_AD_REPLACEMENT';
export const RESET_ADS_LIST = 'RESET_ADS_LIST';

export const TRY_SAVING_BATCH = 'TRY_SAVING_BATCH';
export const SAVED_BATCH_SUCCESS = 'SAVED_BATCH_SUCCESS';
export const UPDATE_BATCH_ADS = 'UPDATE_BATCH_ADS';
export const BATCH_UPDATE_LIST = 'BATCH_UPDATE_LIST';
export const UPDATE_QUEUE_ADS = 'UPDATE_QUEUE_ADS';
export const UPDATE_BATCH_PROFILE_SUCCESS = 'UPDATE_BATCH_PROFILE_SUCCESS';
export const UPDATE_BATCH_ORIGINAL_AD = 'UPDATE_BATCH_ORIGINAL_AD';
export const BATCH_UPDATE_LIST_RT = 'BATCH_UPDATE_LIST_RT';
export const BATCH_REMOVE_LIST_RT = 'BATCH_REMOVE_LIST_RT';
export const UPDATE_SINGLE_OPTIMIZE_LOADING = 'UPDATE_SINGLE_OPTIMIZE_LOADING';

export const TRY_BATCHES_REQUEST = 'TRY_BATCHES_REQUEST';
export const SUCCESS_BATCHES_REQUEST = 'SUCCESS_BATCHES_REQUEST';
export const TRY_DELETE_BATCHES_REQUEST = 'TRY_DELETE_BATCHES_REQUEST';
export const SUCCESS_DELETE_BATCHES_REQUEST = 'SUCCESS_DELETE_BATCHES_REQUEST';
export const UPDATE_ADS_OPTIMIZE_LOADING = 'UPDATE_ADS_OPTIMIZE_LOADING';

// productDescriptions module actions

export const PRODUCT_DESCRIPTIONS_LOADING_DONE =
  'PRODUCT_DESCRIPTIONS_LOADING_DONE';
export const PRODUCT_DESCRIPTIONS_SELECTION_SUCCESS =
  'PRODUCT_DESCRIPTIONS_SELECTION_SUCCESS';
export const GET_PROFILE_PRODUCT_DESCRIPTIONS_SUCCESS =
  'GET_PROFILE_PRODUCT_DESCRIPTIONS_SUCCESS';
export const PRODUCT_DESCRIPTIONS_SELECTION_REQUEST =
  'PRODUCT_DESCRIPTIONS_SELECTION_REQUEST';
export const GET_PRODUCT_DESCRIPTIONS_REQUEST =
  'GET_PRODUCT_DESCRIPTIONS_REQUEST';
export const GET_PRODUCT_DESCRIPTIONS_FOR_CAMPAIGNS_AND_ADGROUPS_REQUEST =
  'GET_PRODUCT_DESCRIPTIONS_FOR_CAMPAIGNS_AND_ADGROUPS_REQUEST';
export const GET_PRODUCT_DESCRIPTIONS_FOR_CAMPAIGNS_AND_ADGROUPS_SUCCESS =
  'GET_PRODUCT_DESCRIPTIONS_FOR_CAMPAIGNS_AND_ADGROUPS_SUCCESS';
export const GET_PRODUCT_DESCRIPTIONS_MEASURES_REQUEST =
  'GET_PRODUCT_DESCRIPTIONS_MEASURES_REQUEST';
export const GET_PRODUCT_DESCRIPTIONS_MEASURES_SUCCESS =
  'GET_PRODUCT_DESCRIPTIONS_MEASURES_SUCCESS';
export const GET_PRODUCT_DESCRIPTIONS_SUCCESS =
  'GET_PRODUCT_DESCRIPTIONS_SUCCESS';
export const GET_PRODUCT_DESCRIPTIONS_TOTALS =
  'GET_PRODUCT_DESCRIPTIONS_TOTALS';
export const GET_PRODUCT_DESCRIPTIONS_PROFILE_SUCCESS =
  'GET_PRODUCT_DESCRIPTIONS_PROFILE_SUCCESS';
export const GET_PRODUCT_DESCRIPTION_TOTALS_REQUEST =
  'GET_PRODUCT_DESCRIPTION_TOTALS_REQUEST';
export const GET_PRODUCT_DESCRIPTIONS_CATEGORIES_REQUEST =
  'GET_PRODUCT_DESCRIPTIONS_CATEGORIES_REQUEST';
export const GET_PRODUCT_DESCRIPTIONS_CATEGORIES_SUCCESS =
  'GET_PRODUCT_DESCRIPTIONS_CATEGORIES_SUCCESS';

export const RESET_PRODUCT_DESCRIPTIONS_DATA =
  'RESET_PRODUCT_DESCRIPTIONS_DATA';
export const RESET_PRODUCT_DESCRIPTIONS_PROFILE_DATA =
  'RESET_PRODUCT_DESCRIPTIONS_PROFILE_DATA';
export const PRODUCT_DESCRIPTIONS_GET_PROFILES_REQUEST =
  'PRODUCT_DESCRIPTIONS_GET_PROFILES_REQUEST';
export const PRODUCT_DESCRIPTIONS_GET_PROFILES_SUCCESS =
  'PRODUCT_DESCRIPTIONS_GET_PROFILES_SUCCESS';
export const GET_PROFILES_FAILURE_PRODUCT_DESCRIPTIONS =
  'GET_PROFILES_FAILURE_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_BUCKET_CORPUS_REQUEST_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_BUCKET_CORPUS_REQUEST_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_BUCKET_CORPUS_SUCCESS_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_BUCKET_CORPUS_SUCCESS_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_BUCKET_CORPUS_FAILURE_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_BUCKET_CORPUS_FAILURE_PRODUCT_DESCRIPTIONS';
export const CREATE_PROFILES_REQUEST_PRODUCT_DESCRIPTIONS =
  'CREATE_PROFILES_REQUEST_PRODUCT_DESCRIPTIONS';
export const CREATE_PROFILES_SUCCESS_PRODUCT_DESCRIPTIONS =
  'CREATE_PROFILES_SUCCESS_PRODUCT_DESCRIPTIONS';
export const CREATE_PROFILES_FAILURE_PRODUCT_DESCRIPTIONS =
  'CREATE_PROFILES_FAILURE_PRODUCT_DESCRIPTIONS';
export const ADDED_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS =
  'ADDED_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS';
export const ADDED_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS =
  'ADDED_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS';
export const ADDED_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS =
  'ADDED_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS';
export const REMOVE_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS =
  'REMOVE_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS';
export const REMOVE_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS =
  'REMOVE_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS';
export const REMOVE_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS =
  'REMOVE_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_MEASURES_REQUEST_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_MEASURES_REQUEST_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_MEASURES_SUCCESS_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_MEASURES_SUCCESS_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_MEASURES_FAILURE_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_MEASURES_FAILURE_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_MEASURES_DETAILS_REQUEST_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_MEASURES_DETAILS_REQUEST_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_MEASURES_DETAILS_FAILURE_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_MEASURES_DETAILS_FAILURE_PRODUCT_DESCRIPTIONS';
export const PROFILE_CALCULATE_REQUEST_PRODUCT_DESCRIPTIONS =
  'PROFILE_CALCULATE_REQUEST_PRODUCT_DESCRIPTIONS';
export const PROFILE_CALCULATE_SUCCESS_PRODUCT_DESCRIPTIONS =
  'PROFILE_CALCULATE_SUCCESS_PRODUCT_DESCRIPTIONS';
export const PROFILE_CALCULATE_FAIL_PRODUCT_DESCRIPTIONS =
  'PROFILE_CALCULATE_FAIL_PRODUCT_DESCRIPTIONS';
export const UPDATE_PROFILE_NAME_PRODUCT_DESCRIPTIONS =
  'UPDATE_PROFILE_NAME_PRODUCT_DESCRIPTIONS';
export const GET_PROFILE_MEASURES_DETAILS_SUCCESS_PRODUCT_DESCRIPTIONS =
  'GET_PROFILE_MEASURES_DETAILS_SUCCESS_PRODUCT_DESCRIPTIONS';

// Drafts module relevant action types
export const GET_DRAFTS_REQUEST = 'GET_DRAFTS_REQUEST';
export const GET_DRAFTS_SUCCESS = 'GET_DRAFTS_SUCCESS';
export const GET_DRAFTS_FAILURE = 'GET_DRAFTS_FAILURE';

export const GET_DRAFT_REQUEST = 'GET_DRAFT_REQUEST';
export const GET_DRAFT_SUCCESS = 'GET_DRAFT_SUCCESS';
export const GET_DRAFT_FAILURE = 'GET_DRAFT_FAILURE';

export const UPDATE_EMAIL_LIST = 'UPDATE_EMAIL_LIST';
export const UPDATE_BLOG_POSTS_LIST = 'UPDATE_BLOG_POSTS_LIST';

export const SAVE_DRAFT_REQUEST = 'SAVE_DRAFT_REQUEST';
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS';
export const SAVE_DRAFT_FAILURE = 'SAVE_DRAFT_FAILURE';

export const SAVE_HUBSPOT_EMAIL_REQUEST = 'SAVE_HUBSPOT_EMAIL_REQUEST';
export const SAVE_HUBSPOT_EMAIL_SUCCESS = 'SAVE_HUBSPOT_EMAIL_SUCCESS';
export const SAVE_HUBSPOT_EMAIL_FAILURE = 'SAVE_HUBSPOT_EMAIL_FAILURE';

export const MERGE_EMAIL_DRAFTS = 'MERGE_EMAIL_DRAFTS';

export const UPDATE_DRAFT_STATUS_REQUEST = 'UPDATE_DRAFT_STATUS_REQUEST';
export const UPDATE_DRAFT_STATUS_SUCCESS = 'UPDATE_DRAFT_STATUS_SUCCESS';
export const DELETE_LOCAL_DRAFTS = 'DELETE_LOCAL_DRAFTS;';

export const MERGE_BLOG_POSTS_DRAFTS = 'MERGE_BLOG_POSTS_DRAFTS';

// Writing environment module relevant action types
export const EDITOR_CONTENT_CHANGE = 'EDITOR_CONTENT_CHANGE';
export const EDITOR_CONTENT_TYPE = 'EDITOR_CONTENT_TYPE';
export const TOGGLE_DISTRACTION_FREE = 'TOGGLE_DISTRACTION_FREE';
export const UPDATE_PARAGRAPHS = 'UPDATE_PARAGRAPHS';
export const CHECK_PARAGRAPH = 'CHECK_PARAGRAPH';
export const UNCHECK_PARAGRAPH = 'UNCHECK_PARAGRAPH';
export const UPDATE_PARAGRAPH_CONTENT = 'UPDATE_PARAGRAPH_CONTENT';
export const UPDATE_PARAGRAPH_ANALYZE_RESULT =
  'UPDATE_PARAGRAPH_ANALYZE_RESULT';
export const UPDATE_TITLE_VALUE = 'UPDATE_TITLE_VALUE';
export const UPDATE_SELECTED_PROFILE = 'UPDATE_SELECTED_PROFILE';
export const GET_ANALYZE_FEEDBACK = 'GET_ANALYZE_FEEDBACK';
export const GET_TITLE_FEEDBACK = 'GET_TITLE_FEEDBACK';
export const GET_DOCUMENT_ANALYZE_FEEDBACK = 'GET_DOCUMENT_ANALYZE_FEEDBACK';
export const GET_TITLE_DOCUMENT_ANALYZE_FEEDBACK =
  'GET_TITLE_DOCUMENT_ANALYZE_FEEDBACK';
export const TITLE_DOCUMENT_LOADING = 'TITLE_DOCUMENT_LOADING';
export const FEEDBACK_MEASURE_CLICKED = 'FEEDBACK_MEASURE_CLICKED';
export const TASK_LIST_SYNONYM_WORD_CLICKED = 'TASK_LIST_SYNONYM_WORD_CLICKED';
export const UPDATE_LOADED_CONTENT_DETAILS = 'UPDATE_LOADED_CONTENT_DETAILS';
export const RESET_EDITOR = 'RESET_EDITOR';
export const UPDATE_ACTIVE_PARAGRAPH = 'UPDATE_ACTIVE_PARAGRAPH';
export const UPDATE_EDITOR_CHANGE_STATE = 'UPDATE_EDITOR_CHANGE_STATE';
export const UPDATE_EDITOR_CHANGE_STATE_FALSE =
  'UPDATE_EDITOR_CHANGE_STATE_FALSE';
export const CALL_OPTIMIZE_API = 'CALL_OPTIMIZE_API';
export const OPTIMIZE_API_FEEDBACK = 'OPTIMIZE_API_FEEDBACK';
export const OPTIMIZE_API_TRIGGERED = 'OPTIMIZE_API_TRIGGERED';
export const TOGGLE_OPTIMIZE_BUTTON = 'TOGGLE_OPTIMIZE_BUTTON';
export const PARAGRAPH_HIGHLIGHTING_TRIGGER = 'PARAGRAPH_HIGHLIGHTING_TRIGGER';

export const TOGGLE_REVERT_BUTTON = 'TOGGLE_REVERT_BUTTON';
export const SHOW_OPTIMIZE_MISMATCH_MSG = 'SHOW_OPTIMIZE_MISMATCH_MSG';
export const UPDATE_ACTIVE_MEASURE_ANALYZE_STATUS =
  'UPDATE_ACTIVE_MEASURE_ANALYZE_STATUS';
export const CLONELOADING = 'CLONELOADING';

// Profiles' actions
export const GET_PROFILES_REQUEST_EMAIL = 'GET_PROFILES_REQUEST_EMAIL';
export const GET_PROFILES_SUCCESS_EMAIL = 'GET_PROFILES_SUCCESS_EMAIL';
export const GET_PROFILES_FAILURE_EMAIL = 'GET_PROFILES_FAILURE_EMAIL';

export const GET_PROFILES_BUCKET_CORPUS_REQUEST_EMAIL =
  'GET_PROFILES_BUCKET_CORPUS_REQUEST_EMAIL';
export const GET_PROFILES_BUCKET_CORPUS_SUCCESS_EMAIL =
  'GET_PROFILES_BUCKET_CORPUS_SUCCESS_EMAIL';
export const GET_PROFILES_BUCKET_CORPUS_FAILURE_EMAIL =
  'GET_PROFILES_BUCKET_CORPUS_FAILURE_EMAIL';

export const GET_PROFILE_MEASURES_REQUEST_EMAIL =
  'GET_PROFILE_MEASURES_REQUEST_EMAIL';
export const GET_PROFILE_MEASURES_SUCCESS_EMAIL =
  'GET_PROFILE_MEASURES_SUCCESS_EMAIL';
export const GET_PROFILE_MEASURES_FAILURE_EMAIL =
  'GET_PROFILE_BUCKET_FAILURE_EMAIL';

export const GET_PROFILE_MEASURES_DETAILS_REQUEST_EMAIL =
  'GET_PROFILE_MEASURES_DETAILS_REQUEST_EMAIL';
export const GET_PROFILE_MEASURES_DETAILS_SUCCESS_EMAIL =
  'GET_PROFILE_MEASURES_DETAILS_SUCCESS_EMAIL';
export const GET_PROFILE_MEASURES_DETAILS_FAILURE_EMAIL =
  'GET_PROFILE_MEASURES_DETAILS_FAILURE_EMAIL';

export const CREATE_PROFILES_REQUEST_EMAIL = 'CREATE_PROFILES_REQUEST_EMAIL';
export const CREATE_PROFILES_SUCCESS_EMAIL = 'CREATE_PROFILES_SUCCESS_EMAIL';
export const CREATE_PROFILES_FAILURE_EMAIL = 'CREATE_PROFILES_FAILURE_EMAIL';

export const ADDED_CONTENT_REQUEST_EMAIL = 'ADDED_CONTENT_REQUEST_EMAIL';
export const ADDED_CONTENT_SUCCESS_EMAIL = 'ADDED_CONTENT_SUCCESS_EMAIL';
export const ADDED_CONTENT_FAILURE_EMAIL = 'ADDED_CONTENT_FAILURE_EMAIL';

export const REMOVE_CONTENT_REQUEST_EMAIL = 'REMOVE_CONTENT_REQUEST_EMAIL';
export const REMOVE_CONTENT_SUCCESS_EMAIL = 'REMOVE_CONTENT_SUCCESS_EMAIL';
export const REMOVE_CONTENT_FAILURE_EMAIL = 'REMOVE_CONTENT_FAILURE_EMAIL';

export const GET_PROFILE_CONTENT_REQUEST_EMAIL =
  'GET_PROFILE_CONTENT_REQUEST_EMAIL';
export const GET_PROFILE_CONTENT_SUCCESS_EMAIL =
  'GET_PROFILE_CONTENT_SUCCESS_EMAIL';
export const GET_PROFILE_CONTENT_FAILURE_EMAIL =
  'GET_PROFILE_CONTENT_FAILURE_EMAIL';

export const DELETE_PROFILES_REQUEST_EMAIL = 'DELETE_PROFILES_REQUEST_EMAIL';
export const DELETE_PROFILES_SUCCESS_EMAIL = 'DELETE_PROFILES_SUCCESS_EMAIL';
export const DELETE_PROFILES_FAILURE_EMAIL = 'DELETE_PROFILES_FAILURE_EMAIL';

export const ACTIVATE_PROFILES_REQUEST_EMAIL =
  'ACTIVATE_PROFILES_REQUEST_EMAIL';
export const ACTIVATE_PROFILES_SUCCESS_EMAIL =
  'ACTIVATE_PROFILES_SUCCESS_EMAIL';
export const ACTIVATE_PROFILES_FAILURE_EMAIL =
  'ACTIVATE_PROFILES_FAILURE_EMAIL';

export const PROFILE_CALCULATE_REQUEST_EMAIL =
  'PROFILE_CALCULATE_REQUEST_EMAIL';
export const PROFILE_CALCULATE_SUCCESS_EMAIL =
  'PROFILE_CALCULATE_SUCCESS_EMAIL';
export const PROFILE_CALCULATE_FAIL_EMAIL = 'PROFILE_CALCULATE_FAIL_EMAIL';

export const RESET_PROFILE_DATA_EMAIL = 'RESET_PROFILE_DATA_EMAIL';
export const UPDATE_PROFILE_NAME_EMAIL = 'UPDATE_PROFILE_NAME_EMAIL';

export const GET_PROFILES_REQUEST_POST = 'GET_PROFILES_REQUEST_POST';
export const GET_PROFILES_SUCCESS_POST = 'GET_PROFILES_SUCCESS_POST';
export const GET_PROFILES_FAILURE_POST = 'GET_PROFILES_FAILURE_POST';

export const GET_PROFILE_MEASURES_REQUEST_POST =
  'GET_PROFILE_MEASURES_REQUEST_POST';
export const GET_PROFILE_MEASURES_SUCCESS_POST =
  'GET_PROFILE_MEASURES_SUCCESS_POST';
export const GET_PROFILE_MEASURES_FAILURE_POST =
  'GET_PROFILE_BUCKET_FAILURE_POST';

export const GET_PROFILE_BUCKET_CORPUS_REQUEST_POST =
  'GET_PROFILE_BUCKET_CORPUS_REQUEST_POST';
export const GET_PROFILE_BUCKET_CORPUS_SUCCESS_POST =
  'GET_PROFILE_BUCKET_CORPUS_SUCCESS_POST';
export const GET_PROFILE_BUCKET_CORPUS_FAILURE_POST =
  'GET_PROFILE_BUCKET_CORPUS_FAILURE_POST';

export const GET_PROFILE_MEASURES_DETAILS_REQUEST_POST =
  'GET_PROFILE_MEASURES_DETAILS_REQUEST_POST';
export const GET_PROFILE_MEASURES_DETAILS_SUCCESS_POST =
  'GET_PROFILE_MEASURES_DETAILS_SUCCESS_POST';
export const GET_PROFILE_MEASURES_DETAILS_FAILURE_POST =
  'GET_PROFILE_MEASURES_DETAILS_FAILURE_POST';

export const CREATE_PROFILES_REQUEST_POST = 'CREATE_PROFILES_REQUEST_POST';
export const CREATE_PROFILES_SUCCESS_POST = 'CREATE_PROFILES_SUCCESS_POST';
export const CREATE_PROFILES_FAILURE_POST = 'CREATE_PROFILES_FAILURE_POST';

export const ADDED_CONTENT_REQUEST_POST = 'ADDED_CONTENT_REQUEST_POST';
export const ADDED_CONTENT_SUCCESS_POST = 'ADDED_CONTENT_SUCCESS_POST';
export const ADDED_CONTENT_FAILURE_POST = 'ADDED_CONTENT_FAILURE_POST';

export const REMOVE_CONTENT_REQUEST_POST = 'REMOVE_CONTENT_REQUEST_POST';
export const REMOVE_CONTENT_SUCCESS_POST = 'REMOVE_CONTENT_SUCCESS_POST';
export const REMOVE_CONTENT_FAILURE_POST = 'REMOVE_CONTENT_FAILURE_POST';

export const GET_PROFILE_CONTENT_REQUEST_POST =
  'GET_PROFILE_CONTENT_REQUEST_POST';
export const GET_PROFILE_CONTENT_SUCCESS_POST =
  'GET_PROFILE_CONTENT_SUCCESS_POST';
export const GET_PROFILE_CONTENT_FAILURE_POST =
  'GET_PROFILE_CONTENT_FAILURE_POST';

export const DELETE_PROFILES_REQUEST_POST = 'DELETE_PROFILES_REQUEST_POST';
export const DELETE_PROFILES_SUCCESS_POST = 'DELETE_PROFILES_SUCCESS_POST';
export const DELETE_PROFILES_FAILURE_POST = 'DELETE_PROFILES_FAILURE_POST';

export const ACTIVATE_PROFILES_REQUEST_POST = 'ACTIVATE_PROFILES_REQUEST_POST';
export const ACTIVATE_PROFILES_SUCCESS_POST = 'ACTIVATE_PROFILES_SUCCESS_POST';
export const ACTIVATE_PROFILES_FAILURE_POST = 'ACTIVATE_PROFILES_FAILURE_POST';

export const PROFILE_CALCULATE_REQUEST_POST = 'PROFILE_CALCULATE_REQUEST_POST';
export const PROFILE_CALCULATE_SUCCESS_POST = 'PROFILE_CALCULATE_SUCCESS_POST';
export const PROFILE_CALCULATE_FAIL_POST = 'PROFILE_CALCULATE_FAIL_POST';

export const SET_CONTENT_SUMMARY = 'SET_CONTENT_SUMMARY';
export const RESET_CONTENT_SUMMARY = 'RESET_CONTENT_SUMMARY';
export const GET_CONTENT_SUMMARY = 'GET_CONTENT_SUMMARY';

export const UPDATE_PROFILE_NAME_POST = 'UPDATE_PROFILE_NAME_POST';

// Account settings
export const RESET_SETTINGS = 'RESET_SETTINGS';
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';
export const GET_BILLING_INFO = 'GET_BILLING_INFO';
export const TOGGLE_BILLING_FETCHING = 'TOGGLE_BILLING_FETCHING';
export const UPDATE_CUSTOM_WORD_LIST = 'UPDATE_CUSTOM_WORD_LIST';
export const UPDATE_IGNORE_WORD_LIST = 'UPDATE_IGNORE_WORD_LIST';
export const UPDATE_COMPLIANCE_WORD_LIST = 'UPDATE_COMPLIANCE_WORD_LIST';
export const SPLICE_WORD_FROM_LIST = 'SPLICE_WORD_FROM_LIST';
export const UPDATE_WORDS_TO_ADD = 'UPDATE_WORDS_TO_ADD';
export const UPDATE_HUBSPOT_BLOGS_DATA = 'UPDATE_HUBSPOT_BLOGS_DATA';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';

export const TOGGLE_FETCHING_SUB_ACCOUNT = "TOGGLE_FETCHING_SUB_ACCOUNT";
export const SUB_ACCOUNTS_DATA = "SUB_ACCOUNTS_DATA";

// Integratons
export const CHECK_HUBSPOT_REQUEST = 'CHECK_HUBSPOT_REQUEST';
export const CHECK_HUBSPOT_SUCCESS = 'CHECK_HUBSPOT_SUCCESS';
export const CHECK_HUBSPOT_NOT_CONNECTED = 'CHECK_HUBSPOT_NOT_CONNECTED';

export const HUBSPOT_AUTH_LINK_SUCCESS = 'HUBSPOT_AUTH_LINK_SUCCESS';
export const HUBSPOT_CONNECT_SUCCESS = 'HUBSPOT_CONNECT_SUCCESS';
export const HUBSPOT_CONNECT_FAIL = 'HUBSPOT_CONNECT_FAIL';

export const HUBSPOT_DISCONNECT_SUCCESS = 'HUBSPOT_DISCONNECT_SUCCESS';
export const HUBSPOT_DISCONNECT_FAIL = 'HUBSPOT_DISCONNECT_FAIL';

export const CHECK_WORDPRESS_REQUEST = 'CHECK_WORDPRESS_REQUEST';
export const CHECK_WORDPRESS_SUCCESS = 'CHECK_WORDPRESS_SUCCESS';
export const CHECK_WORDPRESS_NOT_CONNECTED = 'CHECK_WORDPRESS_NOT_CONNECTED';

export const SAVE_WORDPRESS_REQUEST = 'SAVE_WORDPRESS_REQUEST';
export const SAVE_WORDPRESS_SUCCESS = 'SAVE_WORDPRESS_SUCCESS';
export const SAVE_WORDPRESS_FAILURE = 'SAVE_WORDPRESS_FAILURE';

export const DISCONNECT_WORDPRESS_REQUEST = 'DISCONNECT_WORDPRESS_REQUEST';
export const DISCONNECT_WORDPRESS_SUCCESS = 'DISCONNECT_WORDPRESS_SUCCESS';
export const DISCONNECT_WORDPRESS_NOT_CONNECTED =
  'DISCONNECT_WORDPRESS_NOT_CONNECTED';

export const ADD_ARTICLE_REQUEST = 'ADD_ARTICLE_REQUEST';
export const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS';
export const ADD_ARTICLE_FAILED = 'ADD_ARTICLE_FAILED';

export const CHECK_MAILCHIMP_REQUEST = 'CHECK_MAILCHIMP_REQUEST';
export const CHECK_MAILCHIMP_SUCCESS = 'CHECK_MAILCHIMP_SUCCESS';
export const CHECK_MAILCHIMP_NOT_CONNECTED = 'CHECK_MAILCHIMP_NOT_CONNECTED';
export const MAILCHIMP_CONNECT_SUCCESS = 'MAILCHIMP_CONNECT_SUCCESS';
export const MAILCHIMP_CONNECT_FAIL = 'MAILCHIMP_CONNECT_FAIL';
export const SET_INITAL_PAGE = 'SET_INITAL_PAGE';

export const REQUEST_CREATE_CONTENT_SOURCE = 'REQUEST_CREATE_CONTENT_SOURCE';
export const CREATE_CONTENT_SOURCE_SUCCESS = 'CREATE_CONTENT_SOURCE_SUCCESS';
export const CREATE_CONTENT_SOURCE_FAIL = 'CREATE_CONTENT_SOURCE_FAIL';
export const GET_CONTENT_SOURCES = 'GET_CONTENT_SOURCES';
export const GET_SEARCH_CONSOLE_PROPERTIES = 'GET_SEARCH_CONSOLE_PROPERTIES';

export const FETCH_GA_PROFILES = 'FETCH_GA_PROFILES';

export const CHECK_ADWORDS_REQUEST = 'CHECK_ADWORDS_REQUEST';
export const CHECK_ADWORDS_SUCCESS = 'CHECK_ADWORDS_SUCCESS';
export const CHECK_ADWORDS_NOT_CONNECTED = 'CHECK_ADWORDS_NOT_CONNECTED';

export const CHECK_MARKETO_REQUEST = 'CHECK_MARKETO_REQUEST';
export const CHECK_MARKETO_SUCCESS = 'CHECK_MARKETO_SUCCESS';
export const CHECK_MARKETO_NOT_CONNECTED = 'CHECK_MARKETO_NOT_CONNECTED';
export const MARKETO_CONNECT_SUCCESS = 'MARKETO_CONNECT_SUCCESS';
export const MARKETO_CONNECT_FAIL = 'MARKETO_CONNECT_FAIL';
export const MARKETO_SET_FOLDERS = 'MARKETO_SET_FOLDERS';
export const MARKETO_FOLDERS_FETCHING = 'MARKETO_FOLDERS_FETCHING';

export const CHECK_SEARCHCONSOLE_SUCCESS = 'CHECK_SEARCHCONSOLE_SUCCESS';
export const CHECK_SEARCHCONSOLE_NOT_CONNECTED =
  'CHECK_SEARCHCONSOLE_NOT_CONNECTED';

export const UPDATE_SOCIAL_MEDIA_CONN_LOADING_STATUS =
  'UPDATE_SOCIAL_MEDIA_CONN_LOADING_STATUS';
export const UPDATE_SOCIAL_MEDIA_CONNS = 'UPDATE_SOCIAL_MEDIA_CONNS';

// Product Descriptions relevant actions
export const UPDATE_INITIAL_PRODUCT_DESC = 'UPDATE_INITIAL_PRODUCT_DESC';
export const UPDATE_OPTIMIZED_PRODUCT_DESC = 'UPDATE_OPTIMIZED_PRODUCT_DESC';
export const UPDATE_PRODUCT_DESC_PROFILES = 'UPDATE_PRODUCT_DESC_PROFILES';
export const SELECT_PRODUCT_DESC_PROFILE = 'SELECT_PRODUCT_DESC_PROFILE';
export const UPDATE_ACTIVE_TOKEN_INDEX = 'UPDATE_ACTIVE_TOKEN_INDEX';
export const TRY_OPTIMIZE_PRODUCT_DESC = 'TRY_OPTIMIZE_PRODUCT_DESC';
export const OPTIMIZE_PRODUCT_DESC_DONE = 'OPTIMIZE_PRODUCT_DESC_DONE';

// Product Descriptions relevant actions
export const UPDATE_INITIAL_DEMO = 'UPDATE_INITIAL_DEMO';
export const UPDATE_OPTIMIZED_DEMO = 'UPDATE_OPTIMIZED_DEMO';
export const UPDATE_DEMO_PROFILES = 'UPDATE_DEMO_PROFILES';
export const SELECT_DEMO_PROFILE = 'SELECT_DEMO_PROFILE';
export const TRY_OPTIMIZE_DEMO = 'TRY_OPTIMIZE_DEMO';
export const OPTIMIZE_DEMO_DONE = 'OPTIMIZE_DEMO_DONE';

// Tableau actions types
export const FETCH_TABLEAU_TOKEN_REQUEST = 'FETCH_TABLEAU_TOKEN_REQUEST';
export const FETCH_TABLEAU_TOKEN_SUCCESS = 'FETCH_TABLEAU_TOKEN_SUCCESS';
export const FETCH_TABLEAU_TOKEN_FAILURE = 'FETCH_TABLEAU_TOKEN_FAILURE';

export const CHARGIFY_SUBSCRIPTION_VALID = 'CHARGIFY_SUBSCRIPTION_VALID';

// Personable plugin flag
export const TOGGLE_PERSONABLE_PLUGIN = 'TOGGLE_PERSONABLE_PLUGIN';

// New Editor Highlighting
export const HIGHLIGHTING_TOGGLE_ACTIVE_MEASURE =
  'HIGHLIGHTING_TOGGLE_ACTIVE_MEASURE';
export const HIGHLIGHTING_TOGGLE_ALL_MEASURES = `HIGHLIGHTING_TOGGLE_ALL_MEASURES`;
export const HIGHLIGHTING_ACTIVE_PARAGRAPH = 'HIGHLIGHTING_ACTIVE_PARAGRAPH';
export const MEASURE_RANKING_FEEDBACK = `MEASURE_RANKING_FEEDBACK`;
export const MEASURE_RANKING_LOADING = `MEASURE_RANKING_LOADING`;
export const DRAFT_LOADING = `DRAFT_LOADING`;

export const OPTIMIZE_FEEDBACK = `OPTIMIZE_FEEDBACK`;
export const OPTIMIZE_FEEDBACK_FILTERED = `OPTIMIZE_FEEDBACK_FILTERED`;
export const TITLE_OPTIMIZE_FEEDBACK_FILTERED = `TITLE_OPTIMIZE_FEEDBACK_FILTERED`;
export const OPTIMIZE_FEEDBACK_IS_LOADING = `OPTIMIZE_FEEDBACK_IS_LOADING`;
export const OPTIMIZE_FEEDBACK_REPLACEMENT_COUNT = `OPTIMIZE_FEEDBACK_REPLACEMENT_COUNT`;
export const OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS = `OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS`;
export const OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS_RESET = `OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS_RESET`;
export const OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS_REMOVE = `OPTIMIZE_FEEDBACK_ACCEPTED_CARD_IDS_REMOVE`;

export const REGION_SUGGESTION_FEEDBACK = `REGION_SUGGESTION_FEEDBACK`;
export const REGION_SUGGESTION_IS_LOADING = `REGION_SUGGESTION_IS_LOADING`;
export const REGION_SUGGESTION_IS_ACTIVE = `REGION_SUGGESTION_IS_ACTIVE`;

export const TITLE_REPHRASE = `TITLE_REPHRASE`;
export const TITLE_REPHRASE_IS_LOADING = `TITLE_REPHRASE_IS_LOADING`;

export const REGION_SUGGESTION_SET_ACTIVE = `REGION_SUGGESTION_SET_ACTIVE`;
export const REGION_SUGGESTION_SET_INACTIVE = `REGION_SUGGESTION_SET_INACTIVE`;

export const ADD_TO_REJECT_LIST = `ADD_TO_REJECT_LIST`;
export const REMOVE_FROM_REJECT_LIST = `REMOVE_FROM_REJECT_LIST`;
export const ADD_TO_RECENTLY_ADDED_TO_LISTS_LIST = `ADD_TO_RECENTLY_ADDED_TO_LISTS_LIST`;

export const RESET_API_FEEDBACK = `RESET_API_FEEDBACK`;
