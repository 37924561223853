/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import _ from 'lodash';
import React from 'react';
import { getName } from 'country-list';

/* eslint-disable import/prefer-default-export */
export const getCategoryDictionary = network => {
  switch (network) {
    case 'instagram':
      return [
        { id: 1000, title: 'Accessories & Jewellery' },
        { id: 1048, title: 'Adult content' },
        { id: 1001, title: 'Alcohol' },
        { id: 1026, title: 'Animals' },
        { id: 1003, title: 'Architecture & Urban Design' },
        { id: 1002, title: 'Art/Artists' },
        { id: 1020, title: 'Beauty' },
        { id: 1005, title: 'Business & Careers' },
        { id: 1018, title: 'Cars & Motorbikes' },
        { id: 1032, title: 'Cinema & Actors/actresses' },
        { id: 1007, title: 'Clothing & Outfits' },
        { id: 1016, title: 'Comics & sketches' },
        { id: 1034, title: 'Computers & Gadgets' },
        { id: 1037, title: 'DIY & Design' },
        { id: 1033, title: 'Education' },
        { id: 1014, title: 'Extreme Sports & Outdoor activity' },
        { id: 1042, title: 'Family' },
        { id: 1021, title: 'Fashion' },
        { id: 1006, title: 'Finance & Economics' },
        { id: 1023, title: 'Fitness & Gym' },
        { id: 1035, title: 'Food & Cooking' },
        { id: 1022, title: 'Health & Medicine' },
        { id: 1036, title: 'Humor & Fun & Happiness' },
        { id: 1046, title: 'Kids & Toys' },
        { id: 1041, title: 'Lifestyle' },
        { id: 1004, title: 'Literature & Journalism' },
        { id: 1043, title: 'Luxury' },
        { id: 1030, title: 'Machinery & Technologies' },
        { id: 1044, title: 'Management & Marketing' },
        { id: 1010, title: 'Mobile related' },
        { id: 1039, title: 'Modeling' },
        { id: 1027, title: 'Music' },
        { id: 1025, title: 'Nature & landscapes' },
        { id: 1038, title: 'Photography' },
        { id: 1015, title: 'Racing Sports' },
        { id: 1029, title: 'Science' },
        { id: 1028, title: 'Shopping & Retail' },
        { id: 1017, title: 'Shows' },
        { id: 1013, title: 'Sports with a ball' },
        { id: 1019, title: 'Sweets & Bakery' },
        { id: 1047, title: 'Tobacco & Smoking' },
        { id: 1031, title: 'Trainers & Coaches' },
        { id: 1009, title: 'Travel' },
        { id: 1011, title: 'Water sports' },
        { id: 1012, title: 'Winter sports' },
      ];

    case 'tiktok':
      return [
        { id: 1, title: 'Animals' },
        { id: 3, title: 'Art' },
        { id: 4, title: 'Auto' },
        { id: 5, title: 'Beauty' },
        { id: 6, title: 'Comedy' },
        { id: 7, title: 'DIY & Life Hacks' },
        { id: 8, title: 'Daily Life' },
        { id: 9, title: 'Dance' },
        { id: 10, title: 'Family & Parenting' },
        { id: 11, title: 'Fashion' },
        { id: 12, title: 'Fitness' },
        { id: 13, title: 'Food & Beverage' },
        { id: 14, title: 'Gaming' },
        { id: 15, title: 'Health' },
        { id: 16, title: 'Home & Garden' },
        { id: 17, title: 'Love & Dating' },
        { id: 19, title: 'Outdoor Activities' },
        { id: 24, title: 'Science & Education' },
        { id: 25, title: 'Sport' },
        { id: 26, title: 'Travel' },
      ];

    case 'youtube':
      return [
        { id: 3, title: 'Cars & Motorbikes' },
        { id: 4, title: 'Education' },
        { id: 5, title: 'Entertainment' },
        { id: 37, title: 'How-to & Style' },
        { id: 23, title: 'Movies and TV' },
        { id: 13, title: 'Music' },
        { id: 36, title: 'News & Politics' },
        { id: 38, title: 'Nonprofits & Activism' },
        { id: 44, title: 'People & Blogs' },
        { id: 14, title: 'Pets' },
        { id: 17, title: 'Sports' },
        { id: 18, title: 'Technology & Science' },
        { id: 19, title: 'Travel & Tourism' },
        { id: 35, title: 'Video Games' },
      ];

    default:
      return [];
  }
};

// INFLUENCER LISTING FUNCTIONS

const transformGenderData = genderData => {
  if (genderData !== null) {
    let males = 0;
    let females = 0;

    if (Array.isArray(genderData)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < genderData.length; i++) {
        const { gender } = genderData[i];
        const { value } = genderData[i];

        if (gender === 'M' && value > males) {
          males = value;
        } else if (gender === 'F' && value > females) {
          females = value;
        }
      }
    } else {
      Object.keys(genderData).forEach(key => {
        males += genderData[key].male || 0;
        females += genderData[key].female || 0;
      });
    }

    return [males.toFixed(2), females.toFixed(2)];
  }

  return [null, null];
};

const transformAgeDataV3 = data => {
  try {
    const maxAgeGroup = _.maxBy(data, 'weight');
    return [(maxAgeGroup.weight * 100).toFixed(2), maxAgeGroup.code];
  } catch (e) {
    return [null];
  }
};

const transformGenderDataV3 = genderData => {
  try {
    let males = 0;
    let females = 0;
    genderData.forEach(item => {
      males += item.male || 0;
      females += item.female || 0;
    });
    return [(males * 100).toFixed(2), (females * 100).toFixed(2)];
  } catch (e) {
    return [null, null];
  }
};

const getGenderDistro = (data, network) => {
  switch (network) {
    case 'instagram':
      return [data[0] ?? null, data[1] ?? null];
    case 'tiktok':
    case 'youtube':
      return transformGenderData(data ?? null);
    case 'twitter':
      return [null, null]; // We don't have this data for Twitter
    default:
      return 0;
  }
};

const transformAgeDataInstagram = ageData => {
  if (ageData !== null && ageData !== undefined) {
    const combined = {};

    ageData.forEach(item => {
      item.by_age_group.forEach(ageGroup => {
        if (!combined[ageGroup.group]) {
          combined[ageGroup.group] = ageGroup.value;
        } else {
          combined[ageGroup.group] += ageGroup.value;
        }
      });
    });

    const combinedAgeGroups = Object.entries(combined);
    combinedAgeGroups.sort((a, b) => b[1] - a[1]);

    return [
      combinedAgeGroups[0][1].toFixed(2),
      combinedAgeGroups[0][0].replace('age', ' '),
    ];
  }

  return null;
};

const transformAgeData = ageData => {
  if (ageData !== null && ageData !== undefined && !_.isEmpty(ageData)) {
    const transformedAgeData = [];

    if (Array.isArray(ageData)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ageData.length; i++) {
        const byAgeGroup = ageData[i].by_age_group;
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < byAgeGroup.length; j++) {
          transformedAgeData.push({
            group: byAgeGroup[j].group,
            value: (byAgeGroup[j].value * ageData[i].value) / 100,
          });
        }
      }
    } else {
      Object.keys(ageData).forEach(key => {
        const male = ageData[key].male || 0;
        const female = ageData[key].female || 0;
        transformedAgeData.push({
          group: key,
          value: male + female,
        });
      });
    }

    transformedAgeData.sort((a, b) => b.value - a.value);

    const ageGroup = transformedAgeData[0]?.group?.replace('age', '');
    const value = transformedAgeData[0]?.value?.toFixed(2);

    return [value, ageGroup];
  }

  return null;
};

const getSubAge = (data, network) => {
  switch (network) {
    case 'instagram':
      return transformAgeDataInstagram(data ?? null);
    case 'tiktok':
    case 'youtube':
      return transformAgeData(data ?? null);
    default:
      return null; // We don't have this data for Twitter
  }
};

const getGenderDistroLegacy = (data, network) => {
  console.log(data, network);
  switch (network) {
    case 'instagram':
      return [data[0].value ?? null, data[1].value ?? null];
    case 'tiktok':
    case 'youtube':
      return transformGenderData(data?.data ?? null);

    default:
      return [null, null]; // We don't have this data for Twitter
  }
};

const getSubAgeLegacy = (data, network) => {
  switch (network) {
    case 'instagram':
      return transformAgeDataInstagram(data);
    case 'tiktok':
    case 'youtube':
      return transformAgeData(data);
    // We don't have this data for Twitter
    default:
      return null;
  }
};

export const formatCreatorDataListingV3 = data => {
  const newData = {
    key: 0,
    score: 0,
    notes: [],
    socialNetworks: ['instagram'],
    username: '',
    name: '',
    userId: '',
    size: '',
    subs: 0,
    er: 0,
    categories: [],
    primInterest: [],
    seconInterest: [],
    genderDistro: [],
    subAge: null,
    similarityIndex: 0,
    status: '',
    featuresRespJson: {},
    brandSafety: {},
    totalFlagsBrandSafety: 0,
  };

  try {
    const {
      primaryInterests,
      secondaryInterests,
      normalizedRespData,
      featuresRespJson,
      brandSafety,
    } = data;

    const formattedPrimaryInterests = JSON.parse(primaryInterests);
    const formattedSecondaryInterests = JSON.parse(secondaryInterests);
    const respData = JSON.parse(normalizedRespData);
    const formatteddFeaturesRespJson = JSON.parse(featuresRespJson);
    const totalFlags = brandSafety
      ? Object.values(brandSafety).reduce((acc, val) => acc + (val || 0), 0)
      : 0;

    newData.key = data.id;
    newData.detailsId = data.creatorReportDetailsId;
    newData.score = data.radScore || null;
    newData.notes = data.notes || [];
    newData.socialNetworks = [data.socialNetwork];
    newData.username = data.username;
    newData.name = respData.userFullName;
    newData.userId = data.userId;
    newData.status = data.status === '' ? 'pending' : data.status;
    newData.size = respData.influencerSize;
    newData.subs = respData.subs;
    newData.er = respData.engagementRate * 100 || 0;
    newData.categories = respData.categories || [null];
    newData.primInterest = formattedPrimaryInterests || null;
    newData.seconInterest = formattedSecondaryInterests || null;
    newData.genderDistro = transformGenderDataV3(respData.genderData);
    newData.subAge = transformAgeDataV3(respData.audienceAges);
    newData.similarityIndex = data.similarityIndex || null;
    newData.image = respData.userPhotoUrl || null;
    newData.other = respData || null;
    newData.featuresRespJson = formatteddFeaturesRespJson || null;
    newData.brandSafety = brandSafety;
    newData.totalFlagsBrandSafety = totalFlags;
  } catch (error) {
    console.error(error);
  }
  return newData;
};

export const formatCreatorDataListing = listData => {
  const template = [
    {
      key: 0,
      score: 0,
      notes: [],
      socialNetworks: ['instagram'],
      username: '',
      name: '',
      userId: '',
      size: '',
      subs: 0,
      er: 0,
      categories: [],
      primInterest: [],
      seconInterest: [],
      genderDistro: [],
      subAge: null,
      similarityIndex: 0,
      status: '',
    },
  ];

  return listData.map(data => {
    const newData = { ...template[0] };
    try {
      newData.key = data.id;
      newData.detailsId = data.creatorReportDetailsId;
      newData.score = data.radScore || null;
      newData.notes = data.notes || [];
      newData.socialNetworks = [data.socialNetwork];
      newData.username = data.username;
      newData.name = data.name;
      newData.userId = data.userId;
      newData.status = data.status === '' ? 'pending' : data.status;
      newData.size = data.size;
      newData.subs = data.subs;
      newData.er = data.er;
      newData.categories = data.categories || [null];
      newData.primInterest = data.primaryInterests || null;
      newData.seconInterest = data.secondaryInterests || null;
      newData.genderDistro = getGenderDistro(
        data.genderData,
        data.socialNetwork
      );
      newData.subAge = getSubAgeLegacy(data.ageGroup, data.socialNetwork);
      newData.similarityIndex = data.similarityIndex || null;
      newData.image = data.imageURL || null;
    } catch (error) {
      console.error(error);
    }
    return newData;
  });
};

// CREATOR DETAIL PAGE METHODS

export const getInfluencerDetailsName = data => {
  if (typeof data === 'object' && data !== null) {
    const network = data.sn;

    switch (network) {
      case 'instagram': {
        const fullName =
          data?.report?.result?.user?.full_name ?? 'No name data';
        return fullName !== '' ? fullName : data.all.username;
      }
      case 'youtube':
      case 'tiktok':
      case 'twitch':
      case 'twitter': {
        const title =
          data?.report?.result?.report?.basic?.title ?? 'No name data';
        return title !== '' ? title : data.all.username;
      }
      default:
        return 'No data';
    }
  }

  return 'Loading';
};

export const getInfluencerDetailsImage = data => {
  if (typeof data === 'object' && data !== null) {
    const network = data.sn;
    let url;

    switch (network) {
      case 'instagram':
        url = data?.report?.result?.user?.photo_url ?? 'noUrl';
        break;
      case 'youtube':
      case 'tiktok':
      case 'twitter':
      case 'twitch':
        url = data?.report?.result?.report?.basic?.avatar_url ?? 'noUrl';
        break;
      default:
        url = 'noUrl';
        break;
    }

    if (url === 'noUrl') {
      return <div className="no-creator-picture">?</div>;
    }

    return <img className="creator-picture" src={url} alt="Creator Avatar" />;
  }

  return <div className="no-creator-picture">?</div>;
};

const getInfluencerCountryFromCode = countryCode => {
  if (countryCode === 'noData') {
    return 'No location data';
  }

  return getName(countryCode);
};

export const getInfluencerDetailsLocation = data => {
  if (typeof data === 'object' && data !== null) {
    const network = data.sn;

    switch (network) {
      case 'instagram':
        return getInfluencerCountryFromCode(
          data.report?.result?.user?.blogger_geo?.country ?? 'noData'
        );
      case 'youtube':
      case 'tiktok':
      case 'twitter':
      case 'twitch':
        return getInfluencerCountryFromCode(
          data.report?.result?.report?.features?.blogger_geo?.data?.country ??
            'noData'
        );
      default:
        return 'No location data';
    }
  }

  return 'Loading';
};
