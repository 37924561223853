import {
  RESET_BLOG_POSTS_DATA,
  GET_BLOG_POSTS_REQUEST,
  GET_BLOG_POSTS_MEASURES_REQUEST,
  GET_BLOG_POSTS_MEASURES_SUCCESS,
  GET_BLOG_POSTS_SUCCESS,
  BLOG_POSTS_LOADING_DONE,
  BLOG_POSTS_MEASURES_LOADING_DONE,
  MERGE_BLOG_POSTS_DRAFTS,
  GET_DRAFTS_REQUEST,
  GET_DRAFTS_SUCCESS,
  UPDATE_BLOG_POSTS_LIST,
  GET_PROFILE_CONTENT_REQUEST_POST,
  GET_PROFILE_CONTENT_SUCCESS_POST,
  GET_PROFILE_CONTENT_FAILURE_POST,
  DELETE_PROFILES_REQUEST_POST,
  DELETE_PROFILES_SUCCESS_POST,
  DELETE_PROFILES_FAILURE_POST,
  ACTIVATE_PROFILES_REQUEST_POST,
  ACTIVATE_PROFILES_SUCCESS_POST,
  ACTIVATE_PROFILES_FAILURE_POST,
  CREATE_PROFILES_REQUEST_POST,
  CREATE_PROFILES_SUCCESS_POST,
  CREATE_PROFILES_FAILURE_POST,
  ADDED_CONTENT_REQUEST_POST,
  ADDED_CONTENT_SUCCESS_POST,
  ADDED_CONTENT_FAILURE_POST,
  REMOVE_CONTENT_REQUEST_POST,
  REMOVE_CONTENT_SUCCESS_POST,
  REMOVE_CONTENT_FAILURE_POST,
  GET_PROFILES_REQUEST_POST,
  GET_PROFILES_SUCCESS_POST,
  GET_PROFILES_FAILURE_POST,
  GET_PROFILE_MEASURES_REQUEST_POST,
  GET_PROFILE_MEASURES_SUCCESS_POST,
  GET_PROFILE_MEASURES_FAILURE_POST,
  GET_PROFILE_MEASURES_DETAILS_REQUEST_POST,
  GET_PROFILE_MEASURES_DETAILS_SUCCESS_POST,
  GET_PROFILE_MEASURES_DETAILS_FAILURE_POST,
  GET_PROFILE_BUCKET_CORPUS_REQUEST_POST,
  GET_PROFILE_BUCKET_CORPUS_SUCCESS_POST,
  GET_PROFILE_BUCKET_CORPUS_FAILURE_POST,
  PROFILE_CALCULATE_REQUEST_POST,
  PROFILE_CALCULATE_SUCCESS_POST,
  PROFILE_CALCULATE_FAIL_POST,
  UPDATE_PROFILE_NAME_POST,
  GET_PROFILE_BLOG_POST_SUCCESS,
  SCRAPE_ARTICLE,
  DELETE_LOCAL_DRAFTS,
  RE_SCRAPE_REQUEST,
  RE_SCRAPE_SUCCESS,
  RE_SCRAPE_FAILURE,
  SET_CONTENT_SUMMARY,
  GET_CONTENT_SUMMARY, RESET_CONTENT_SUMMARY,
} from '../constants/actionTypes';
import { GET_GOAL_NAMES } from '../constants/apiUrls';

const initialState = {

  fetchingBlogPosts: false,
  fetchingBlogMeasures: false,

  blogPosts: [],
  contentDataArray: [],
  blogPostsObj: {},
  blogPostMeasures: {},
  drafts: [],

  fetchingDrafts: false,
  creatingProfileId: false,
  currentScoringProfile: 0,

  fetchingProfiles: false,
  fetchingProfileMeasureDetails: false,
  fetchingProfileAllMeasures: false,
  fetchingProfileBucketCorpus: false,

  scraped: false,
  scraping: false,

  engagementNames: {
    GA_pageviews: 'Pageviews',
    GA_uniquepageviews: 'Unique Pageviews',
    GA_goalcompletionsall: 'Total Conversions',
    GA_sessions: 'Sessions',
    GA_avgTimeOnPage: 'Avg. Time on Page',
    GA_timeonpage: 'Time on Page',
    // GA_bounceRate: 'GA. Bounce Rate',
    // HS_ampViews: 'AMP Views',
    // HS_ctaclick: 'CTA Clicks',
    HS_ctaRate: 'CTA Rate',
    // HS_ctaviews: 'CTA Views',
    // HS_pageBounceRate: 'HS. Bounce Rate',
    // HS_entrances: 'Page Entrances',
    // HS_leads: 'Leads',
    // HS_marketingQualifiedLeads: 'Marketing Qualified Leads',
    // HS_opportunities: 'Marketing Opportunities',
    // HS_others: 'Others',
    HS_submissions: 'Submissions',
    // HS_subscribers: 'Subscribers',
    HS_pageviews: 'Views',
    HS_pageTime: 'Time on Page',
    // HS_timePerPageview: 'Time Per Pagview ',
    HS_submissionsPerPageview: 'View to Submission Rate',
    // HS_contactsPerPageview: 'View to Contact Rate',
  },

  negativeEngagement: ['HS_pageBounceRate', 'GA_bounceRate', 'HS_exitsPerPageview'],

  goalNames: {},



  profiles: [],
  profile: {},
  profileName: ' ',
  standardProfile: false,
  profileMeasures: {},
  profileBodyEngagement: '',
  profileTitleEngagement: '',
  profileBucket: '',
  selectedMeasures: [],
  profileCutOffEngagement: {},

  contentSummary: {
    summary: null,
    length: null,
    characterCount: null,
    wordCount: null,
    error: null,
  },
};

const blogApp = (state = initialState, action) => {

  switch (action.type) {
    case GET_BLOG_POSTS_REQUEST:
      return {
        ...state,
        blogPosts: [],
        fetchingBlogPosts: true,
      };
    case GET_BLOG_POSTS_MEASURES_REQUEST:
      return {
        ...state,
        fetchingBlogMeasures: true,
        blogPostMeasures: {},
      };
    case GET_BLOG_POSTS_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        // blogPosts: action.response.data,
        blogPostsObj: action.response.dataObj,
        fetchingBlogPosts: false,
      };
    case GET_PROFILE_BLOG_POST_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        blogPosts: action.response.data,
        contentDataArray: action.response.contentDataArray,
        fetchingBlogPosts: false,
        profileBodyEngagement: action.response.scoringProfileBodyEngagement,
        profileTitleEngagement: action.response.scoringProfileTitleEngagement,
      };
    case GET_BLOG_POSTS_MEASURES_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        blogPostMeasures: action.response.measuresData,
        blogPostMeasureNames: action.response.measuresNames,
        fetchingBlogMeasures: false
      };
    case BLOG_POSTS_LOADING_DONE:
      return {
        ...state,
        fetchingBlogPosts: false,
      };
    case BLOG_POSTS_MEASURES_LOADING_DONE:
      return {
        ...state,
        fetchingEmailMeasures: false,
      };
    case MERGE_BLOG_POSTS_DRAFTS:
      return {
        ...state,
        blogPosts: action.posts,
        // fetchingBlogMeasures: false,
      };

    case SET_CONTENT_SUMMARY:
      return {
        ...state,
        contentSummary: action.summary,
      };

    case RESET_CONTENT_SUMMARY:
      return {
        ...state,
        contentSummary: {
          summary: null,
          length: null,
          characterCount: null,
          wordCount: null,
          error: null,
        },
      };

    case GET_DRAFTS_REQUEST:
      return {
        ...state,
        fetchingDrafts: true,
      };

    case GET_DRAFTS_SUCCESS:
      return {
        ...state,
        fetchingDrafts: false,
        blogPosts:state.blogPosts.concat(action.response.data)
      };

    case UPDATE_BLOG_POSTS_LIST:
      return {
        ...state,
        fetchingDrafts: false,
      };

    case GET_PROFILE_CONTENT_REQUEST_POST:
      return {
        ...state,
        fetchingContentData: true,
      };
    case GET_PROFILE_CONTENT_SUCCESS_POST:
      return {
        ...state,
        fetchingContentData: false,
        contentData: action.response.contentDataIds
      };
    case GET_PROFILE_CONTENT_FAILURE_POST:
      return {
        ...state,
        fetchingContentData: false,
        fetchingContentDataError: action.error,
      };

    case DELETE_PROFILES_REQUEST_POST:
      return {
        ...state,
        deletingProfiles: true,
      };
    case DELETE_PROFILES_SUCCESS_POST:
      return {
        ...state,
        deletingProfiles: false,
        deleteProfileIds: action.response.profileIds
      };
    case DELETE_PROFILES_FAILURE_POST:
      return {
        ...state,
        deletingProfiles: false,
        deleteProfileIdError: action.error,
      };

    case ACTIVATE_PROFILES_REQUEST_POST:
      return {
        ...state,
        activatingProfiles: true,
      };
    case ACTIVATE_PROFILES_SUCCESS_POST:
      return {
        ...state,
        activatingProfiles: false,
        activatedProfileIds: action.response.profileIds
      };
    case ACTIVATE_PROFILES_FAILURE_POST:
      return {
        ...state,
        activatingProfiles: false,
        activatedProfileIdError: action.error,
      };

    case CREATE_PROFILES_REQUEST_POST:
      return {
        ...state,
        creatingProfileId: true,
      };
    case CREATE_PROFILES_SUCCESS_POST:
      return {
        ...state,
        creatingProfileId: false,
        createdProfileId: action.response.data.profileId
      };
    case CREATE_PROFILES_FAILURE_POST:
      return {
        ...state,
        creatingProfileId: false,
        creatingProfileIdError: action.error,
      };

    case ADDED_CONTENT_REQUEST_POST:
      return {
        ...state,
        addingContentData: true,
      };
    case ADDED_CONTENT_SUCCESS_POST:
      return {
        ...state,
        addingContentData: false,
        addedContentData: action.response.params.contentDataIds
      };
    case ADDED_CONTENT_FAILURE_POST:
      return {
        ...state,
        addingContentData: false,
        addedContentDataError: action.error,
      };

    case REMOVE_CONTENT_REQUEST_POST:
      return {
        ...state,
        removingContentData: true
      };
    case REMOVE_CONTENT_SUCCESS_POST:
      return {
        ...state,
        removingContentData: false
      };
    case REMOVE_CONTENT_FAILURE_POST:
      return {
        ...state,
        removingContentData: false
      };

    case GET_PROFILES_REQUEST_POST:

      return {
        ...state,
        fetchingProfiles: true,
        profiles: [],
      };
    case GET_PROFILES_SUCCESS_POST:

      return {
        ...state,
        fetchingProfiles: false,
        profiles: action.response.data,
      };
    case GET_PROFILES_FAILURE_POST:
      return {
        ...state,
        fetchingProfiles: false,
        fetchingProfilesError: action.error,
      };

    case GET_PROFILE_MEASURES_REQUEST_POST:
      return {
        ...state,
        fetchingProfileAllMeasures: true,
        profileMeasures: {},
      };
    case GET_PROFILE_MEASURES_SUCCESS_POST:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        profileMeasures: action.response,
        standardProfile: action.response.standard,
        profileName: action.response.profileName,
        currentScoringProfile: action.response.profileId,

      };

    case GET_PROFILE_MEASURES_FAILURE_POST:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        fetchingProfileMeasuresError: action.error,

      };

    case GET_PROFILE_MEASURES_DETAILS_REQUEST_POST:
      return {
        ...state,
        fetchingProfileMeasureDetails: true,
        selectedMeasures: []
      };
    case GET_PROFILE_MEASURES_DETAILS_SUCCESS_POST:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        selectedMeasures: action.response.data.measure,
      };
    case GET_PROFILE_MEASURES_DETAILS_FAILURE_POST:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        fetchingProfileMeasureDetailsError: action.error,
      };
    case GET_PROFILE_BUCKET_CORPUS_REQUEST_POST:
      return {
        ...state,
        fetchingProfileBucketCorpus: true,
      };
    case GET_PROFILE_BUCKET_CORPUS_SUCCESS_POST:
      return {
        ...state,
        fetchingProfileBucketCorpus: false,
        selectedBucketCorpusMeasures: action.response,
      };
    case GET_PROFILE_BUCKET_CORPUS_FAILURE_POST:
      return {
        ...state,
        fetchingProfileBucketCorpus: false,
        fetchingProfileBucketCorpusError: action.error,
      };
    case PROFILE_CALCULATE_REQUEST_POST:
      return {
        ...state,
        calculatedProfile: '',
        calculatingProfile: true,
        calculatedError: ''
      };
    case PROFILE_CALCULATE_SUCCESS_POST:

      return {
        ...state,
        calculatedProfile: action.response.message,
        calculatingProfile: false,
        calculatedError: ''
      };
    case PROFILE_CALCULATE_FAIL_POST:
      return {
        ...state,
        calculatedProfile: '',
        calculatingProfile: false,
        calculatedError: action.error
      };

    case UPDATE_PROFILE_NAME_POST:
      return {
        ...state,
        profileName: action.value,
      };
    case GET_GOAL_NAMES:

      //Append the goals names to the engagement name in state


      // this.engagementNames['Goals'] = action.goals;

      return {
        ...state,
        engagementNames: Object.assign(state.engagementNames, action.goals),
        goalNames: action.goals,
      };
    case SCRAPE_ARTICLE:
      return {
        ...state,
        scrapedMessage: action.response.message,
        scrapedStatus: action.response.status,
        scraped: true,
      };
    case RE_SCRAPE_REQUEST:
      return {
        ...state,
        scraping: true,
        scraped: false,
      };
    case RE_SCRAPE_SUCCESS:
      return {
        ...state,
        scraping: false,
        scraped: true,
      };
    case RE_SCRAPE_FAILURE:
      return {
        ...state,
        scraping: false,
        scraped: false,
        error: action.response,
      };
    case DELETE_LOCAL_DRAFTS:
      const { draftIds } = action;
      return {
        ...state,
        blogPosts: state.blogPosts.filter(el => (-1 == draftIds.indexOf(el.id))),
      };
    case RESET_BLOG_POSTS_DATA:
      return initialState;
    default:
      return state;
  }
};

export default blogApp;
