/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_CONTENT_TYPE } from '../common/constants';
import { updateUrlFilters, updateUrlSearch } from '../common/utils';
import {
  buildTableData,
  requestApiProfiles,
  requestApiStats,
  getContentTypeFromUrl,
  getDateFilterFromUrl,
  getPageFromUrl,
  getProfileFromUrl,
} from './AnalyzeUtils';

const selectKpiItem = { id: '0', name: 'Select KPI' };

const initialState = {
  profiles: null,
  headerStatsData: {},
  kpis: [selectKpiItem],
  firstLoading: true, // shows loader to all the page under filters row
  loading: true, // disable all rows, including table row
  filters: {
    kpi: '0',
    profile: null,
    contentType: DEFAULT_CONTENT_TYPE,
    date: null,
  },
  error: '',
  contentTableHeaders: {},
  tableLoading: true, // disables table row
  tablePage: 1,
  tableData: [],
  tableTotal: 0,
  tableFilter: { column: '', order: '' },
};

const AnalyzeContentSlice = createSlice({
  name: 'AnalyzeContent',
  initialState,
  reducers: {
    setInitialData(state, action) {
      const {
        payload: {
          profiles,
          urlFilters: { kpi, profile, contentType, date, page },
          error,
        },
      } = action;
      state.profiles = profiles;
      state.filters = {
        kpi,
        profile,
        contentType,
        date,
      };
      state.tablePage = page;
      state.error = error;
    },
    setStatsAndTable(state, action) {
      const {
        payload: {
          headerStatsData,
          contentTableHeaders,
          kpis,
          tableData,
          tableTotal,
        },
      } = action;
      state.headerStatsData = headerStatsData;
      state.contentTableHeaders = contentTableHeaders;
      state.kpis = kpis;
      state.firstLoading = false;
      state.loading = false;
      state.tableData = tableData;
      state.tableLoading = false;
      state.tableTotal = tableTotal;
    },
    setStatsAndTableNoFilters(state, action) {
      const {
        payload: {
          headerStatsData,
          contentTableHeaders,
          tableData,
          tableTotal,
        },
      } = action;
      state.headerStatsData = headerStatsData;
      state.contentTableHeaders = contentTableHeaders;
      state.firstLoading = false;
      state.loading = false;
      state.tableData = tableData;
      state.tableLoading = false;
      state.tableTotal = tableTotal;
    },
    setTable(state, action) {
      const {
        payload: { tableData, tableTotal },
      } = action;
      state.loading = false;
      state.tableData = tableData;
      state.tableLoading = false;
      state.tableTotal = tableTotal;
    },
    setError(state, action) {
      const {
        payload: { error },
      } = action;
      state.error = error;
      // state.firstLoading = false;
    },
    resetWithContentType(state, action) {
      const {
        payload: { filters },
      } = action;
      state.filters = filters;
      state.headerStatsData = {};
      state.contentTableHeaders = {};
      state.kpis = [selectKpiItem];
      state.profiles = null;
      state.error = '';
      state.firstLoading = true;
      state.loading = true;
      state.tableLoading = true;
      state.tablePage = 1;
      state.tableData = [];
      state.tableTotal = 0;
    },
    resetForFilter(state, action) {
      const {
        payload: { key, value },
      } = action;
      state.filters[key] = value;
      state.loading = true;
      state.error = '';
      state.tableLoading = true;
    },
    setTablePage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tablePage = page;
      state.tableLoading = true;
    },
  },
});

export const {
  setInitialData,
  setStatsAndTable,
  setStatsAndTableNoFilters,
  setTable,
  setError,
  resetWithContentType,
  resetForFilter,
  setTablePage,
} = AnalyzeContentSlice.actions;

export default AnalyzeContentSlice.reducer;

// ================================================

const handleApiResponse = (
  dispatch,
  apiData,
  tableOnly,
  skipFiltersUpdate,
  contentType
) => {
  const {
    headerStatsData: {
      total: { value: tableTotal },
    },
  } = apiData;
  const tableData = buildTableData(apiData, contentType);
  if (!tableOnly) {
    if (skipFiltersUpdate) {
      // First set header data, then set Table data
      const { headerStatsData, contentTableHeaders } = apiData;
      dispatch(
        setStatsAndTableNoFilters({
          headerStatsData,
          contentTableHeaders,
          tableData,
          tableTotal,
        })
      );
    } else {
      // First set header data, then set Table data
      const { headerStatsData, contentTableHeaders, fieldsKPIData } = apiData;
      const apiKpis = Object.keys(fieldsKPIData).map(key => ({
        id: key,
        name: fieldsKPIData[key].label,
      }));
      const kpis = [selectKpiItem, ...apiKpis];
      dispatch(
        setStatsAndTable({
          headerStatsData,
          contentTableHeaders,
          kpis,
          tableData,
          tableTotal,
        })
      );
    }
  } else {
    dispatch(setTable({ tableData, tableTotal }));
  }
};

const initializeWithUrl = (searchParams, history, location) => async (
  dispatch,
  getState
) => {
  const {
    AnalyzeContent: {
      filters: { kpi },
    },
  } = getState();
  const urlFilters = {
    kpi,
    profile: null,
    contentType: getContentTypeFromUrl(searchParams),
    date: getDateFilterFromUrl(searchParams),
    page: getPageFromUrl(searchParams),
  };
  let profiles = null;

  try {
    profiles = await requestApiProfiles(urlFilters.contentType);
    if (profiles.length === 0) {
      dispatch(setError({ error: 'No profiles found.' }));
      return;
    }
    urlFilters.profile = getProfileFromUrl(searchParams, profiles);

    dispatch(setInitialData({ profiles, urlFilters, error: '' }));
    updateUrlFilters({ searchParams, history, location, urlFilters });
    const apiData = await requestApiStats(urlFilters);

    handleApiResponse(dispatch, apiData, false, false, urlFilters.contentType);
  } catch (error) {
    dispatch(setInitialData({ profiles, urlFilters, error }));
    updateUrlFilters({ searchParams, history, location, urlFilters });
  }
};

export const initialize = (history, location) => async (dispatch, getState) => {
  const {
    AnalyzeContent: { filters, firstLoading, tablePage },
  } = getState();
  const searchParams = new URLSearchParams(location.search);
  if (!firstLoading) {
    // Coming back from another page, just reuse current stored data
    const urlFilters = {
      kpi: filters.kpi,
      profile: filters.profile,
      contentType: filters.contentType,
      date: filters.date,
      page: tablePage,
    };
    updateUrlFilters({ searchParams, history, location, urlFilters });
  } else {
    // First load, use url query string and start all
    dispatch(initializeWithUrl(searchParams, history, location));
  }
};

const initializeForFilter = (history, location, key, value) => async (
  dispatch,
  getState
) => {
  const {
    AnalyzeContent: { filters },
  } = getState();
  updateUrlSearch(history, location, key, value);
  dispatch(resetForFilter({ key, value }));
  const myFilters = { ...filters, [key]: value };
  // Coming back from another page, just reuse current stored data
  const urlFilters = {
    kpi: myFilters.kpi,
    profile: myFilters.profile,
    contentType: myFilters.contentType,
    date: myFilters.date,
    page: 1,
  };
  try {
    const apiData = await requestApiStats(urlFilters);

    handleApiResponse(dispatch, apiData, false, true, urlFilters.contentType);
  } catch (error) {
    console.log('initializeForFilter error:', error);
    dispatch(
      setError({ error: 'Error loading filtered stats, please try again.' })
    );
  }
};

const initializeTable = (filters, tablePage) => async (dispatch, getState) => {
  const {
    AnalyzeContent: { tableTotal },
  } = getState();

  const urlFilters = {
    ...filters,
    page: tablePage,
  };
  try {
    const apiData = await requestApiStats(urlFilters, true);
    // overwrite with current tableTotal for table paginate flow
    apiData.headerStatsData.total.value = tableTotal;

    handleApiResponse(dispatch, apiData, true, false, urlFilters.contentType);
  } catch (error) {
    console.log('initializeTable error:', error);
    dispatch(setError({ error: 'Error loading table, please try again.' }));
  }
};

export const updateFilter = (history, location, key, value) => dispatch => {
  switch (key) {
    case 'contentType': {
      const filters = {
        kpi: '0',
        profile: null,
        contentType: value,
        date: null,
      };
      const urlFilters = {
        kpi: null,
        profile: null,
        contentType: value,
        date: null,
        page: 1,
      };
      const searchParams = new URLSearchParams(location.search);
      updateUrlFilters({ searchParams, history, location, urlFilters });
      dispatch(resetWithContentType({ filters }));
      dispatch(initializeWithUrl(searchParams, history, location));
      break;
    }
    case 'profile':
    case 'kpi':
    case 'date': {
      dispatch(initializeForFilter(history, location, key, value));
      break;
    }
    default: {
      console.log('Failed to update unknown filter.');
      break;
    }
  }
};

export const updateTablePage = (history, location, page) => (
  dispatch,
  getState
) => {
  const {
    AnalyzeContent: { filters },
  } = getState();
  updateUrlSearch(history, location, 'page', page);
  dispatch(setTablePage({ page }));
  dispatch(initializeTable(filters, page));
};
