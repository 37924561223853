import {
  RESET_ADS_DATA,
  GET_ADS_REQUEST,
  GET_ADS_MEASURES_REQUEST,
  GET_ADS_MEASURES_SUCCESS,
  GET_ADS_SUCCESS,
  ADS_LOADING_DONE,
  ADS_MEASURES_LOADING_DONE,
  GET_PROFILE_CONTENT_REQUEST_ADS,
  GET_PROFILE_CONTENT_SUCCESS_ADS,
  GET_PROFILE_CONTENT_FAILURE_ADS,
  DELETE_PROFILES_REQUEST_ADS,
  DELETE_PROFILES_SUCCESS_ADS,
  DELETE_PROFILES_FAILURE_ADS,
  ACTIVATE_PROFILES_REQUEST_ADS,
  ACTIVATE_PROFILES_SUCCESS_ADS,
  ACTIVATE_PROFILES_FAILURE_ADS,
  CREATE_PROFILES_REQUEST_ADS,
  CREATE_PROFILES_SUCCESS_ADS,
  CREATE_PROFILES_FAILURE_ADS,
  ADDED_CONTENT_REQUEST_ADS,
  ADDED_CONTENT_SUCCESS_ADS,
  ADDED_CONTENT_FAILURE_ADS,
  REMOVE_CONTENT_REQUEST_ADS,
  REMOVE_CONTENT_SUCCESS_ADS,
  REMOVE_CONTENT_FAILURE_ADS,
  GET_PROFILES_REQUEST_ADS,
  GET_PROFILES_SUCCESS_ADS,
  GET_PROFILES_FAILURE_ADS,
  GET_PROFILE_MEASURES_REQUEST_ADS,
  GET_PROFILE_MEASURES_SUCCESS_ADS,
  GET_PROFILE_MEASURES_FAILURE_ADS,
  GET_PROFILE_BUCKET_CORPUS_REQUEST_ADS,
  GET_PROFILE_BUCKET_CORPUS_SUCCESS_ADS,
  GET_PROFILE_BUCKET_CORPUS_FAILURE_ADS,
  GET_PROFILE_MEASURES_DETAILS_REQUEST_ADS,
  GET_PROFILE_MEASURES_DETAILS_SUCCESS_ADS,
  GET_PROFILE_MEASURES_DETAILS_FAILURE_ADS,
  PROFILE_CALCULATE_REQUEST_ADS,
  PROFILE_CALCULATE_SUCCESS_ADS,
  PROFILE_CALCULATE_FAIL_ADS,
  UPDATE_PROFILE_NAME_ADS,
  GET_PROFILE_ADS_SUCCESS,
  GET_PROFILE_ADS_REQUEST,
  UPDATE_ADS_EDITOR_ORIGINAL_AD,
  GET_CAMPAIGN_SUCCESS,
  GET_ADGROUPS_SUCCESS,
  SELECT_EDITOR_PROFILE,
  DESELECT_EDITOR_PROFILE,
  SWITCH_SIDEBAR_EDITOR_MODE,
  UPDATE_ORIGINAL_AD_ALL_FIELDS,
  SELECT_ADS_EDITOR_CAMPAIGN,
  SELECT_ADS_EDITOR_AD_GROUP,
  SELECT_ADS_CONNECTION,
  RECEIVE_ADS_EDITOR_CAMPAIGNS,
  RECEIVE_EDITOR_AD_GROUPS,
  GET_CAMPAIGNS_REQUEST,
  GET_ADGROUPS_REQUEST,
  SELECT_ADS_EDITOR_CAMPAIGN_NAME,
  SELECT_ADS_EDITOR_AD_GROUP_NAME,
  ADD_OPTIMIZED_AD,
  RESET_OPTIMIZED_AD,
  RESET_ORIGINAL_AD,
  POP_OPTIMIZED_AD,
  RESET_EDITOR_PROFILES,
  SELECTED_PROFILE_FOR_MUTILPLE_ADS,
  SAVED_BATCH_SUCCESS,
  TRY_SAVING_BATCH,
  UPDATE_BATCH_ADS,
  BATCH_UPDATE_LIST,
  UPDATE_QUEUE_ADS,
  UPDATE_BATCH_ORIGINAL_AD,
  TRY_BATCHES_REQUEST,
  SUCCESS_BATCHES_REQUEST,
  TRY_DELETE_BATCHES_REQUEST,
  SUCCESS_DELETE_BATCHES_REQUEST,
  GET_AD_TOTALS_REQUEST,
  GET_ADS_TOTALS,
  UPDATE_AD_REPLACEMENT,
  GET_ADS_PROFILE_SUCCESS,
  ADS_SELECTION_REQUEST,
  ADS_SELECTION_SUCCESS,
  UPDATE_ADS_OPTIMIZE_LOADING,
  RESET_ADS_LIST,
  UPDATE_BATCH_PROFILE_SUCCESS,
  SELECT_MULTI_EDITOR_PROFILE,
  BATCH_UPDATE_LIST_RT,
  UPDATE_SINGLE_OPTIMIZE_LOADING,
} from '../constants/actionTypes';


const initialState = {
  profileId:0,
  fetchingAds: false,
  fetchingAdGroups:false,
  fetchingCampaigns:false,
  fetchingAdsMeasures: false,
  fetchingProfileAds: false,
  fetchingAdTotals: false,
  savingBatch: false,
  fetchingBatch: false,
  deletingBatch: false,
  fetchingAdSelection: false,
  fetchingProfileBucketCorpus: false,

  adsMeasures: {},

  batches: [],

  profileDetails:{
    ads:[],
    tableTotal:0,
    offset:0,
    limit:10,
    page:1,

  },


  ads: [],
  offset: 0,
  limit: 0,
  totalCount: 0,
  tableTotal: 0,
  pausedTotal:0,
  enabledTotal:0,
  draftTotal:0,

  profileAds: [],
  profileOffset: 0,
  profileLimit: 10,
  profilePage: 1,
  profileTotalCount: 0,
  profileMatch: 0,

  profileSelected:{
    items:[],
    totalCount:0
  },


  campaigns: [],
  campaignsObj: {},

  adGroups: [],
  adGroupsObj: {},

  contentDataArray: [],
  adMeasures: {},
  adsMeasureNames: '',

  creatingProfileId: false,
  currentScoringProfile: 0,

  fetchingProfiles: false,
  fetchingProfileMeasureDetails: false,
  fetchingProfileAllMeasures: false,

  engagementNames: {
    clickThroughRate: 'Click-Through Rate',
    clicks: 'Clicks',
    conversionRate: 'Conversion Rate',
    conversions: 'Conversions',
    // allConversions: 'All Conversions',
  },

  selectedProfileForMultipleAds: 0,
  negativeEngagement: [],


  profiles: [],
  profile: {},
  profileName: ' ',
  standardProfile: false,
  profileMeasures: {},
  profileBodyEngagement: '',
  profileTitleEngagement: '',
  profileBucket: '',
  selectedMeasures: [],
  profileCutOffEngagement: {},
  calculatingProfile: false,
  addingContentData: false,
  removingContentData: false,

  editor: {
    originalAd: {
      headline1: '',
      headline2: '',
      headline3: '',
      displayUrl: '',
      displayPath1: '',
      displayPath2: '',
      finalUrl: '',
      description1: '',
      description2: '',
    },
    // array of ad objects to be optimized (key - value storage, key is an ad id)
    batchAds: {},
    // queue of optimized ads (key - value storage, key is an original ad id)
    approvedContent: {},
    badContent: {},
    optimizedAds: {},
    profilesSelected: [],
    multiProfileSelected: {},
    campaigns: [],
    adGroups: [],
    campaignSelected: null,
    campaignSelectedName: '',
    adGroupSelected: null,
    adGroupSelectedName: '',
    connectionSelected: null,
    optimizeLoading: false,
  },
};

const adApp = (state = initialState, action) => {

  switch (action.type) {
    case GET_ADS_REQUEST:
      return {
        ...state,
        ads: [],
        fetchingAds: true,
      };

    case ADS_SELECTION_REQUEST:

      return {
        ...state,
        fetchingAdSelection: true,
        fetchingProfileAds: true,
        profileSelected:{
          items:[],
          totalCount:0,
        },
        profileDetails:{
          ads:[],
          totalCount:0,
          offset:0,
          limit:10,
          page:1,

        },

      };

    case ADS_SELECTION_SUCCESS:

      return {
        ...state,
        fetchingAdSelection: false,
        fetchingProfileAds: false,
        profileBodyEngagement:action.response.scoringProfileBodyEngagement,
        profileTitleEngagement:action.response.scoringProfileTitleEngagement,
        profileSelected: action.response.selectedData,
        profileDetails:{
          ads:action.response.allData.items,
          tableTotal:action.response.allData.totalCount,
          offset:action.response.allData.offset,
          limit:10,
          page:action.response.allData.page,
        },
      };

    case GET_PROFILE_ADS_REQUEST:

      return {
        ...state,
        fetchingProfileAds: true,
      };
    case GET_AD_TOTALS_REQUEST:
      return {
        ...state,
        pausedTotal:0,
        enabledTotal:0,
        draftTotal:0,
        fetchingAdTotals: true,
      };
    case GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaigns: [],
        campaignsObj: {},
        fetchingCampaigns: true,
      };
    case GET_ADGROUPS_REQUEST:
      return {
        ...state,
        adGroups: [],
        adGroupsObj: {},
        fetchingAdGroups: true,
      };
    case GET_ADS_MEASURES_REQUEST:
      return {
        ...state,
        fetchingAdsMeasures: true,
        adsMeasures: {},
      };
    case GET_ADS_SUCCESS:

      // take care of the case when there are no emails
      return {
        ...state,
        ads: action.response.data.items,
        offset: action.response.data.offset,
        limit: action.response.data.limit,
        tableTotal: action.response.data.totalCount,
        fetchingAds: false,
      };
    case GET_ADS_PROFILE_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        profileDetails:{
          ads: action.response.data.items,
          offset: action.response.data.offset,
          limit: action.response.data.limit,
          tableTotal: action.response.data.totalCount,
          page: action.response.data.page,
        },
        fetchingProfileAds: false,
      };
      case GET_ADS_TOTALS:
      // take care of the case when there are no emails
      return {
        ...state,
        totalCount: action.response.data.totalCount,
        pausedTotal:action.response.data.pausedTotal,
        enabledTotal:action.response.data.enabledTotal,
        draftTotal:action.response.data.draftTotal,
        fetchingAdTotals: false,
      };
    case SELECTED_PROFILE_FOR_MUTILPLE_ADS:

      return {
        ...state,
        selectedProfileForMultipleAds: action.profile.id
      };
    case GET_PROFILE_ADS_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        profileAds: action.response.data.items,
        selectedAds: action.response.selectedData,
        profileOffset: action.response.data.offset,
        profileLimit: action.response.data.limit,
        profileMatch: action.response.profileMatchPercentage,
        profileTotalCount: action.response.data.totalCount,
        profilePage: action.response.data.page,
        fetchingAds: false,
        profileBodyEngagement: action.response.scoringProfileBodyEngagement,
        profileTitleEngagement: action.response.scoringProfileTitleEngagement,
      };
    case GET_ADS_MEASURES_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        adsMeasures: action.response.measuresData,
        adsMeasureNames: action.response.measuresNames,
        fetchingAdsMeasures: false
      };

    case GET_ADGROUPS_SUCCESS:
      return {
        ...state,
        adGroups: action.response.data.items,
        adGroupsObj: action.response.data.itemObj,
        fetchingAdGroups:false
      };

    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: action.response.data.items,
        campaignsObj: action.response.data.itemObj,
        fetchingCampaigns: false
      };
    case ADS_LOADING_DONE:
      return {
        ...state,
        fetchingAds: false,
      };
    case ADS_MEASURES_LOADING_DONE:
      return {
        ...state,
        fetchingAdsMeasures: false,
      };

    case GET_PROFILE_CONTENT_REQUEST_ADS:
      return {
        ...state,
        fetchingContentData: true,
      };
    case GET_PROFILE_CONTENT_SUCCESS_ADS:
      return {
        ...state,
        fetchingContentData: false,
        contentData: action.response.contentDataIds
      };
    case GET_PROFILE_CONTENT_FAILURE_ADS:
      return {
        ...state,
        fetchingContentData: false,
        fetchingContentDataError: action.error,
      };
    case GET_PROFILE_BUCKET_CORPUS_REQUEST_ADS:
      return {
        ...state,
        fetchingProfileBucketCorpus: true,
      };
    case GET_PROFILE_BUCKET_CORPUS_SUCCESS_ADS:
      return {
        ...state,
        fetchingProfileBucketCorpus: false,
        selectedBucketCorpusMeasures: action.response,
      };
    case GET_PROFILE_BUCKET_CORPUS_FAILURE_ADS:
      return {
        ...state,
        fetchingProfileBucketCorpus: false,
        fetchingProfileBucketCorpusError: action.error,
      };

    case DELETE_PROFILES_REQUEST_ADS:
      return {
        ...state,
        deletingProfiles: true,
      };
    case DELETE_PROFILES_SUCCESS_ADS:
      return {
        ...state,
        deletingProfiles: false,
        deleteProfileIds: action.response.profileIds
      };
    case DELETE_PROFILES_FAILURE_ADS:
      return {
        ...state,
        deletingProfiles: false,
        deleteProfileIdError: action.error,
      };

    case ACTIVATE_PROFILES_REQUEST_ADS:
      return {
        ...state,
        activatingProfiles: true,
      };
    case ACTIVATE_PROFILES_SUCCESS_ADS:
      return {
        ...state,
        activatingProfiles: false,
        activatedProfileIds: action.response.profileIds
      };
    case ACTIVATE_PROFILES_FAILURE_ADS:
      return {
        ...state,
        activatingProfiles: false,
        activatedProfileIdError: action.error,
      };

    case CREATE_PROFILES_REQUEST_ADS:
      return {
        ...state,
        creatingProfileId: true,
      };
    case CREATE_PROFILES_SUCCESS_ADS:
      return {
        ...state,
        creatingProfileId: false,
        createdProfileId: action.response.data.profileId,
      };
    case CREATE_PROFILES_FAILURE_ADS:
      return {
        ...state,
        creatingProfileId: false,
        creatingProfileIdError: action.error,
      };

    case ADDED_CONTENT_REQUEST_ADS:

      return {
        ...state,
        addingContentData: true,
      };
    case ADDED_CONTENT_SUCCESS_ADS:
      return {
        ...state,
        addingContentData: false,
        addedContentData: action.response.params.contentDataIds,
      };
    case ADDED_CONTENT_FAILURE_ADS:
      return {
        ...state,
        addingContentData: false,
        addedContentDataError: action.error,
      };

    case REMOVE_CONTENT_REQUEST_ADS:
      return {
        ...state,
        removingContentData: true
      };
    case REMOVE_CONTENT_SUCCESS_ADS:
      return {
        ...state,
        removingContentData: false
      };
    case REMOVE_CONTENT_FAILURE_ADS:
      return {
        ...state,
        removingContentData: false
      };

    case GET_PROFILES_REQUEST_ADS:

      return {
        ...state,
        fetchingProfiles: true,
        profiles: [],
      };
    case GET_PROFILES_SUCCESS_ADS:

      return {
        ...state,
        fetchingProfiles: false,
        profiles: action.response.data,
      };
    case GET_PROFILES_FAILURE_ADS:
      return {
        ...state,
        fetchingProfiles: false,
        fetchingProfilesError: action.error,
      };

    case GET_PROFILE_MEASURES_REQUEST_ADS:
      return {
        ...state,
        fetchingProfileAllMeasures: true,
        profileMeasures: {},
      };
    case GET_PROFILE_MEASURES_SUCCESS_ADS:

      return {
        ...state,
        fetchingProfileAllMeasures: false,
        profileMeasures: action.response,
        standardProfile: action.response.standard,
        profileName: action.response.profileName,
        currentScoringProfile: action.response.profileId,
        profileBodyEngagement: action.response.scoringProfileBodyEngagement,
        profileTitleEngagement: action.response.scoringProfileTitleEngagement,

      };

    case GET_PROFILE_MEASURES_FAILURE_ADS:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        fetchingProfileMeasuresError: action.error,

      };

    case GET_PROFILE_MEASURES_DETAILS_REQUEST_ADS:
      return {
        ...state,
        fetchingProfileMeasureDetails: true,
        selectedMeasures: []
      };
    case GET_PROFILE_MEASURES_DETAILS_SUCCESS_ADS:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        selectedMeasures: action.response.data.measure,
      };
    case GET_PROFILE_MEASURES_DETAILS_FAILURE_ADS:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        fetchingProfileMeasureDetailsError: action.error,
      };

    case PROFILE_CALCULATE_REQUEST_ADS:
      return {
        ...state,
        calculatedProfile: '',
        calculatingProfile: true,
        calculatedError: ''
      };
    case PROFILE_CALCULATE_SUCCESS_ADS:
      return {
        ...state,
        calculatedProfile: action.response.message,
        calculatingProfile: false,
        calculatedError: '',
      };
    case PROFILE_CALCULATE_FAIL_ADS:
      return {
        ...state,
        calculatedProfile: '',
        calculatingProfile: false,
        calculatedError: action.error
      };
    case UPDATE_PROFILE_NAME_ADS:
      return {
        ...state,
        profileName: action.value,
      };
    case UPDATE_ADS_EDITOR_ORIGINAL_AD:
      return {
        ...state,
        editor: {
          ...state.editor,
          originalAd: {
            ...state.editor.originalAd,
            [action.field]: action.value,
          },
        },
      };
    case UPDATE_ORIGINAL_AD_ALL_FIELDS:
      return {
        ...state,
        editor: {
          ...state.editor,
          originalAd: {
            ...action.editorFields,
          },
        },
      };
    case SELECT_EDITOR_PROFILE:
      return {
        ...state,
        editor: {
          ...state.editor,
          profilesSelected: state.editor.profilesSelected.slice().concat([action.profile]),
        },
      };
    case SELECT_MULTI_EDITOR_PROFILE:
      return {
        ...state,
        editor: {
          ...state.editor,
          multiProfileSelected: action.profile,
        },
      };
    case DESELECT_EDITOR_PROFILE:
      const selected = state.editor.profilesSelected.slice();
      return {
        ...state,
        editor: {
          ...state.editor,
          profilesSelected: selected.filter(profile => profile.id !== action.profile.id),
        },
      };
    case RESET_EDITOR_PROFILES:
      return {
        ...state,
        editor: {
          ...state.editor,
          profilesSelected: [],
        },
      };
    case RESET_ADS_LIST:
      return {
        ...state,
        ads: [],
      };
    case SWITCH_SIDEBAR_EDITOR_MODE:
      return {
        ...state,
        editor: {
          ...state.editor,
          sidePanelMode: action.mode,
        },
      };
    case SELECT_ADS_EDITOR_CAMPAIGN:
      return {
        ...state,
        editor: {
          ...state.editor,
          campaignSelected: action.campaignSelected,
        },
      };
    case SELECT_ADS_EDITOR_CAMPAIGN_NAME:
      return {
        ...state,
        editor: {
          ...state.editor,
          campaignSelectedName: action.campaignName,
        },
      };
    case SELECT_ADS_EDITOR_AD_GROUP_NAME:
      return {
        ...state,
        editor: {
          ...state.editor,
          adGroupSelectedName: action.adGroupName,
        },
      };
    case SELECT_ADS_EDITOR_AD_GROUP:
      return {
        ...state,
        editor: {
          ...state.editor,
          adGroupSelected: action.adGroupSelected,
        },
      };
    case SELECT_ADS_CONNECTION:
      return {
        ...state,
        editor: {
          ...state.editor,
          connectionSelected: action.connectionSelected,
        },
      };
    case RECEIVE_ADS_EDITOR_CAMPAIGNS:
      return {
        ...state,
        editor: {
          ...state.editor,
          campaigns: action.campaigns.slice(),
        },
      };
    case RECEIVE_EDITOR_AD_GROUPS:
      return {
        ...state,
        editor: {
          ...state.editor,
          adGroups: action.adGroups.slice(),
        },
      };
    case ADD_OPTIMIZED_AD:
      return {
        ...state,
        editor: {
          ...state.editor,
          optimizedAds: {
            ...state.editor.optimizedAds,
            [action.profileId]: action.adObject,
          },
        },
      };
    case RESET_OPTIMIZED_AD:
      return {
        ...state,
        editor: {
          ...state.editor,
          optimizedAds: {},
        },
      };
    case RESET_ORIGINAL_AD:
      return {
        ...state,
        editor: {
          ...state.editor,
          batchAds: [],
          badContent: [],
          approvedAds: [],
        },
      };

    case TRY_SAVING_BATCH:
      return {
        ...state,
        savingBatch: true,
      };

    case SAVED_BATCH_SUCCESS:
      return {
        ...state,
        savingBatch: false
      };
    case TRY_BATCHES_REQUEST:
      return {
        ...state,
        fetchingBatch: true,
        batches: []
      };

    case SUCCESS_BATCHES_REQUEST:
      return {
        ...state,
        fetchingBatch: false,
        batches: action.response.data
      };

    case TRY_DELETE_BATCHES_REQUEST:
      return {
        ...state,
        deletingBatch: true,
      };

    case SUCCESS_DELETE_BATCHES_REQUEST:
      return {
        ...state,
        deletingBatch: false,
      };

    case POP_OPTIMIZED_AD:
      const optimizedAds = { ...state.editor.optimizedAds };
      delete optimizedAds[action.profileId];
      return {
        ...state,
        editor: {
          ...state.editor,
          optimizedAds,
        },
      };
    case UPDATE_BATCH_ADS:
      return {
        ...state,
        editor: {
          ...state.editor,
          batchAds: action.adsObj.originalContent,
          approvedContent: action.adsObj.approvedContent,
          badContent: action.adsObj.badContent,
        },
      };
      case UPDATE_BATCH_PROFILE_SUCCESS:
      return {
        ...state,
        editor: {
          ...state.editor,
          approvedContent: action.adsObj.approvedContent,
          badContent: action.adsObj.badContent,
          optimizedAds: {},
        },
      };
    case BATCH_UPDATE_LIST:
      return {
        ...state,
        editor: {
          ...state.editor,
          approvedContent: action.adsObj.approvedContent,
          badContent: action.adsObj.badContent,
        },
      };
    case BATCH_UPDATE_LIST_RT:
        return {
        ...state,
        editor: {
          ...state.editor,
          approvedContent: action.approvedContent,
          badContent: action.badContent,
        },
      };
    case UPDATE_QUEUE_ADS:
      return {
        ...state,
        editor: {
          ...state.editor,
          queueAds: action.queueObj,
        },
      };
    case UPDATE_BATCH_ORIGINAL_AD:
      return {
        ...state,
        editor: {
          ...state.editor,
          batchAds: {
            ...state.editor.batchAds,
            [action.adId]: { ...action.adObj },
          },
        },
      };
    /** This action will update the token for the right optimized ad according to replacement */
    case UPDATE_AD_REPLACEMENT:
      const {
        profileId,
        partOfAd,
        tokenNumber,
        alternativeReplacement,
      } = action;


      return {
        ...state,
        editor: {
          ...state.editor,
          optimizedAds: {
            ...state.editor.optimizedAds,
            [profileId]: {
              ...state.editor.optimizedAds[profileId],
              [partOfAd]: (() => {
                // eslint-disable-next-line max-len
                const tokens = JSON.parse(JSON.stringify(state.editor.optimizedAds[profileId][partOfAd]));
                const token = Object.assign({}, tokens[tokenNumber]);
                const idx = token.alternatives.indexOf(alternativeReplacement);
                token.alternatives[idx] = token.replacement;
                token.replacement = alternativeReplacement;
                tokens[tokenNumber] = token;
                return tokens;
              })(),
            },
          },
        },
      };
    case UPDATE_ADS_OPTIMIZE_LOADING:
      return {
        ...state,
        editor: {
          ...state.editor,
          optimizeLoading: action.loading,
        },
      };
    case UPDATE_SINGLE_OPTIMIZE_LOADING:
      const {
        adId,
        loading,
      } = action;

      return {
        ...state,
        editor: {
          ...state.editor,
          optimizedAds: {
            ...state.editor.optimizedAds,
            [adId]: {
              ...state.editor.optimizedAds[adId],
              optimizingAd: loading,
            },
          },
        },
      };
    case RESET_ADS_DATA:
      return initialState;
    default:
      return state;
  }
};

export default adApp;
