import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Space } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { SETTINGS_URL } from '../../../constants/internalUrls';

export class TopAccountWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      hasContent: false,
      hasAnalytics: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accountInfo } = this.props;

    if (_.size(accountInfo) > 0 && _.size(prevProps.accountInfo) === 0) {
      if (accountInfo.articleCount > 0 || accountInfo.emailCount > 0) {
        this.setHasContent();
      }
      if (accountInfo.hasGA || accountInfo.has_hubspot_integration) {
        this.setHasAnalytics();
      }
    }
  }

  setHasContent() {
    this.setState({
      hasContent: true,
    });
  }

  setHasAnalytics() {
    this.setState({
      hasAnalytics: true,
    });
  }

  onStepChange = currentStep => {
    this.setState({ currentStep });
  };

  render() {
    return null;
    const { hasContent, hasAnalytics } = this.state;
    // https://react-bootstrap.github.io/components/buttons/
    return (
      <Space className="topAccountWizard" align="center">
        <span>Complete your account setup</span>
        <a href={SETTINGS_URL}>
          <span className="label label-pill bg-primary-light font-weight-bolder text-primary mr-1">
            1
          </span>
          <span className="text-dark">Add Content</span>
        </a>
        <a href={SETTINGS_URL}>
          <span className="label label-pill bg-primary-light font-weight-bolder text-primary mr-1">
            2
          </span>
          <span className="text-dark">Add Analytics</span>
        </a>
        <a href="/blogs/editor">
          <span className="label label-pill bg-primary-light font-weight-bolder text-primary mr-1">
            3
          </span>
          <span className="text-dark">Optimize</span>
        </a>
      </Space>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  accountInfo: state.settings.accountInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopAccountWizard);

TopAccountWizard.propTypes = {
  // accountId: PropTypes.number,
};

TopAccountWizard.defaultProps = {
  // accountId: null,
};
