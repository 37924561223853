import {
  FETCH_TABLEAU_TOKEN_REQUEST,
  FETCH_TABLEAU_TOKEN_SUCCESS,
  FETCH_TABLEAU_TOKEN_FAILURE,
} from '../constants/actionTypes';

const DEFAULT_STATE = {
  loading: false,
  data: [],
  showTableauTab: false,
  error: null,
};

export default function tableauApp(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FETCH_TABLEAU_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        showTableauTab: false,
      };
    case FETCH_TABLEAU_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        showTableauTab: true,
        data: action.payload,
      };
    case FETCH_TABLEAU_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        showTableauTab: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
