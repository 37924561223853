/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import arClient from '../../../util/api_client';
import { tableData as mockTableData } from './dataHelper';
import { OK } from '../../../constants/platformStatusCodes';
import { ANALYZE_TRENDING } from '../../../constants/apiUrls';
import { buildEditorArticleUrl } from '../common/utils';

const initialState = {
  stats: {
    contentHitsCount: '0',
    matchingTopicsCount: '0',
    socialAudienceCount: '0',
    searchVolumeCount: '0',
  },
  filters: {
    topic: null,
  },
  searchText: '',
  firstLoading: true,
  tableLoading: true,
  chartJsonDataArray: [],
  tableData: [],
  tableTotal: 0,
  tablePage: 1,
  hitsTableData: [],
  itsTableDataTotal: 0,
  hitsTablePage: 1,
  tableFilter: { column: '', order: '' },
  series: [],
  relatedTopicsSeries: [],
  relatedPostItemsArray: [],
  relatedPostItemsSize: 0,
  newsItemsArray: [],
  newsItemsArraySize: 0,
};

const AnalyzeTrendingSlice = createSlice({
  name: 'AnalyzeTrending',
  initialState,
  reducers: {
    setAll(state, action) {
      const {
        payload: {
          error,
          tableLoading,
          tableData,
          tableTotal,
          hitsTableData,
          hitsTableDataTotal,
          chartJsonDataArray,
          stats,
        },
      } = action;
      state.stats = stats;
      state.tableData = tableData;
      state.tableTotal = tableTotal;
      state.hitsTableData = hitsTableData;
      state.hitsTableDataTotal = hitsTableDataTotal;
      state.chartJsonDataArray = chartJsonDataArray;
      state.error = error;
      state.tableLoading = tableLoading;
      state.firstLoading = false;
      state.loading = false;
    },
    updateStats(state, action) {
      const {
        payload: { contentHits, matchingTopics, socialAudience, searchVolume },
      } = action;
      state.contentHits = contentHits;
      state.matchingTopics = matchingTopics;
      state.socialAudience = socialAudience;
      state.searchVolume = searchVolume;
      state.firstLoading = false;
      state.searchText = '';
    },
    resetTable(state, action) {
      state.tableLoading = true;
      state.tableData = [];
      state.tablePage = 1;
      state.tableTotal = 0;
    },
    updateTable(state, action) {
      const {
        payload: { tableData, tableTotal },
      } = action;
      state.tableData = tableData;
      state.tableLoading = false;
      state.tableTotal = tableTotal;
    },
    setTableLoading(state, action) {
      state.tableLoading = true;
    },
    updateFilter(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.filters[id] = value;
    },
    updateTableFilter(state, action) {
      const {
        payload: { column, order },
      } = action;
      if (!order) {
        state.tableFilter = { column: '', order: '' };
      } else {
        state.tableFilter = { column, order };
      }
    },
    updateTablePage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tablePage = page;
    },
    updateSeries(state, action) {
      const {
        payload: { series },
      } = action;
      state.series = series;
    },
    updateRelatedTopicsSeries(state, action) {
      const {
        payload: { series },
      } = action;
      state.relatedTopicsSeries = series;
    },
    updateRelatedPostItemsArray(state, action) {
      const {
        payload: { relatedPostItemsArray },
      } = action;
      const relatedPostItemsArrayNew = restructureRelatedPostItemsArray(
        relatedPostItemsArray
      );
      state.relatedPostItemsArray = relatedPostItemsArrayNew;
      state.relatedPostItemsSize = relatedPostItemsArrayNew.length;
    },
    updateSearchText(state, action) {
      const {
        payload: { searchText },
      } = action;
      state.searchText = searchText;
    },
    updateNewsItemsArray(state, action) {
      const {
        payload: { newsItemsArray },
      } = action;
      state.newsItemsArray = newsItemsArray;
      state.newsItemsArraySize = newsItemsArray.length;
    },
  },
});

export const {
  setAll,
  updateStats,
  resetTable,
  updateTable,
  setTableLoading,
  updateFilter,
  updateTableFilter,
  updateTablePage,
  updateSeries,
  updateRelatedTopicsSeries,
  updateRelatedPostItemsArray,
  updateSearchText,
  updateNewsItemsArray,
} = AnalyzeTrendingSlice.actions;

const fetchData = () => async (dispatch, getState) => {
  const resp = {
    contentHits: '12',
    matchingTopics: '12',
    socialAudience: '15.52M',
    searchVolume: '5.03M',
    series: [],
    relatedTopicsSeries: [],
    tableData: mockTableData,
    tableTotal: '0',
  };
  // TODO make api request here
  dispatch(updateStats(resp));
  dispatch(
    updateTable({ tableData: resp.tableData, tableTotal: resp.tableTotal })
  );
};

export const initTrendingData = () => async (dispatch, getState) => {
  try {
    const {
      stats,
      tableData,
      tableTotal,
      hitsTableData,
      hitsTableDataTotal,
      chartJsonDataArray,
      WOEID,
    } = await requestApiTrendingDash(getState);
    dispatch(
      setAll({
        stats,
        tableData,
        tableTotal,
        hitsTableData,
        hitsTableDataTotal,
        chartJsonDataArray,
        error: '',
        tableLoading: false,
        WOEID,
      })
    );
  } catch (error) {
    // TODO show error on UI
    console.error(error);
    dispatch(
      setAll({
        stats: {
          contentHitsCount: '0',
          matchingTopicsCount: '0',
          socialAudienceCount: '0',
          searchVolumeCount: '0',
        },
        tableData: [],
        tableTotal: 0,
        hitsTableData: [],
        hitsTableDataTotal: 0,
        chartJsonDataArray: [],
        error: error.message,
        tableLoading: false,
      })
    );
  }
};

export const requestApiTrendingDash = getState =>
  new Promise((resolve, reject) => {
    // Callback executed on API response
    const cb = respApi => {
      console.log(respApi);
      if (respApi.status !== OK) {
        reject(respApi.error);
      } else {
        const {
          data: { chartJsonDataArray, topicsDataArray, stats, WOEID },
        } = respApi;

        const tableData = buildTableData(topicsDataArray);
        const tableTotal = tableData.length;
        const filteredTopicsDataArray = _.filter(
          topicsDataArray,
          item => item.relatedPostsItemsArray.length > 0
        );
        const hitsTableData = buildTableData(filteredTopicsDataArray);
        const hitsTableDataTotal = hitsTableData.length;

        resolve({
          stats,
          tableData,
          tableTotal,
          hitsTableData,
          hitsTableDataTotal,
          chartJsonDataArray,
          WOEID,
        });
      }
    };
    arClient.doApiCall(ANALYZE_TRENDING, { hours: 24 }, cb);
  });

export const fetchStats = () => async dispatch => {
  dispatch(setTableLoading());
  dispatch(resetTable());
  // dispatch(fetchData());
  dispatch(initTrendingData());
};

export const fetchTable = (column, order, page) => async dispatch => {
  // dispatch(updateTableFilter({ column, order }));
  dispatch(updateTablePage({ page }));
  dispatch(setTableLoading());
  dispatch(updateSeries({ series: [] }));
  dispatch(updateRelatedTopicsSeries({ series: [] }));
  dispatch(fetchData());
};

export const buildTableData = data => {
  const tableData = Object.keys(data).map((index, row) => {
    const {
      rank,
      trend,
      topicId,
      topicName,
      hashtag,
      relatedPostsItemsArray,
      relatedTopicsArray,
      topicAudVol,
      seriesAudVol,
      relatedNewsItemsArray,
    } = data[index];

    return {
      key: index,
      rank,
      topicId,
      hashtag,
      topic: topicName,
      hits: relatedPostsItemsArray.length,
      aud: topicAudVol,
      trend,
      series: seriesAudVol,
      relatedPostsItemsArray,
      relatedTopicsArray,
      relatedNewsItemsArray,
    };
  });

  return tableData;
};

export const restructureRelatedPostItemsArray = data => {
  const tableData = Object.keys(data).map((index, row) => {
    const {
      title,
      dataKeywordAvgHits,
      dataAvgSum,
      postId,
      status,
      integrationType,
    } = data[index];

    return {
      key: index,
      title,
      dataKeywordAvgHits,
      dataAvgSum,
      postId,
      status,
      integrationType,
      editorURL: buildEditorArticleUrl('Article', data[index]),
    };
  });
  return tableData;
};

export default AnalyzeTrendingSlice.reducer;
