/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
// import { Badge } from 'react-bootstrap';
import _ from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  CAMPAIGNS_URL,
  MRM_LIBRARY_DEFAULT_URL,
  MRM_UPLOAD_DEFAULT_URL,
  UNIVERSAL_INFLUENCER_LIST,
} from '../../../../../constants/internalUrls';
import { ReactComponent as ArrowDownIcon } from '../icons/arrow-down.svg';
import { ReactComponent as RadIcon } from '../icons/rad-sqaure.svg';

const menuItems = [
  {
    label: 'RAD Influence',
    submenus: [
      {
        label: 'Campaigns',
        url: CAMPAIGNS_URL,
      },
      {
        label: 'Influencer List',
        url: UNIVERSAL_INFLUENCER_LIST,
      },
    ],
  },
  {
    label: 'Settings',
    submenus: [
      {
        label: 'Integrations',
        url: '/settings?to=integrations',
      },
      {
        label: 'Profile & Billing',
        url: '/settings?to=profile',
      },
    ],
  },
  {
    label: 'Content Ranking',
    submenus: [
      {
        label: 'Library',
        url: MRM_LIBRARY_DEFAULT_URL,
      },
      {
        label: 'Upload',
        url: MRM_UPLOAD_DEFAULT_URL,
      },
    ],
  },
];

function getActiveMenuItem(location, items) {
  let activeItem = {};
  items.forEach(item => {
    const fullURL = location.pathname + location.search;
    if (item.url === fullURL) {
      activeItem = item;
    } else if (_.isArray(item.submenus)) {
      item.submenus.forEach(sub => {
        if (sub.url === fullURL) {
          activeItem = sub;
        }
      });
    }
  });
  return activeItem.label;
}

function getExpandedMenuItem(location, items) {
  let expandedItem = {};
  items.forEach(item => {
    if (_.isArray(item.submenus)) {
      item.submenus.forEach(sub => {
        const fullURL = location.pathname + location.search;
        if (sub.url === fullURL) {
          expandedItem = item;
        }
      });
    }
  });
  return expandedItem.label;
}

export default function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { isNavMinimized } = useSelector(state => state.Layout);

  const [activeMenuItem, setActiveMenuItem] = useState(
    getActiveMenuItem(location, menuItems)
  );
  const [expandedMenuItem, setExpandedMenuItem] = useState(
    getExpandedMenuItem(location, menuItems)
  );

  function toggleSubMenu(label) {
    setExpandedMenuItem(label === expandedMenuItem ? null : label);
  }

  useEffect(() => {
    setActiveMenuItem(getActiveMenuItem(location, menuItems));
    setExpandedMenuItem(getExpandedMenuItem(location, menuItems));
  }, [location]);

  return (
    <ul className={`${layoutProps.ulClasses} side-nav-bar-wrapper`}>
      {menuItems.map(menu => {
        const { submenus = [] } = menu;
        const hasSubmenu = _.isEmpty(submenus) === false;
        const isParentMenuActive = submenus.some(
          sub => sub.label === activeMenuItem
        );

        const activeItemIdx = submenus.findIndex(
          item => item.label === activeMenuItem
        );
        const isExpanded = menu.label === expandedMenuItem;
        return (
          <li
            key={menu.label}
            hidden={isNavMinimized}
            className="menu-item-wrapper"
          >
            {hasSubmenu ? (
              <div>
                <div
                  aria-haspopup="true"
                  className={classNames([
                    'menu-item',
                    'menu-item-submenu',
                    isParentMenuActive && 'menu-item--active',
                  ])}
                  onClick={() => toggleSubMenu(menu.label)}
                >
                  <div className="menu-item__rad-icon">
                    {isParentMenuActive && <RadIcon />}
                  </div>
                  <div className="menu-item__text">
                    {/* <NavLink to={menu.url}> */}
                    <span className="menu-text">{menu.label}</span>
                    {/* </NavLink> */}
                  </div>
                  <div
                    className={classNames([
                      'menu-item__arrow-icon',
                      isExpanded && 'menu-item__arrow-icon--active',
                    ])}
                  >
                    <ArrowDownIcon />
                  </div>
                </div>
                <ul
                  hidden={expandedMenuItem !== menu.label}
                  className="sub-menu-list"
                >
                  {submenus.map((submenu, idx) => {
                    return (
                      <li
                        key={submenu.label}
                        className={classNames([
                          'menu-item sub-menu-item',
                          activeMenuItem === submenu.label &&
                            'menu-item--active',
                        ])}
                        onClick={() => setActiveMenuItem(submenu.label)}
                      >
                        <div
                          className="d-flex"
                          style={{ position: 'relative', width: '30%' }}
                        >
                          {idx < activeItemIdx ? (
                            <div className="left-border " />
                          ) : idx === activeItemIdx ? (
                            <div className="left-bottom-border" />
                          ) : null}
                        </div>
                        <div style={{ width: '50%' }}>
                          <NavLink to={submenu.url}>{submenu.label}</NavLink>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <NavLink
                className={classNames([
                  'link-menu-item',
                  activeMenuItem === menu.label && 'link-menu-item--active',
                ])}
                to={menu.url}
                onClick={() => {
                  setActiveMenuItem(menu.label);
                  setExpandedMenuItem(null);
                }}
              >
                <div className="menu-item__rad-icon">
                  {activeMenuItem === menu.label && <RadIcon />}
                </div>
                <div className="menu-item__text">
                  <span className="menu-text">{menu.label}</span>
                </div>
                <div
                  className={classNames([
                    'menu-item__arrow-icon',
                    'menu-item__arrow-icon--hidden',
                  ])}
                >
                  <ArrowDownIcon />
                </div>
              </NavLink>
            )}
          </li>
        );
      })}
    </ul>
  );
}
