import _ from 'lodash';

/**
 * reconcile three different versions of demographics data
 * @param {*} audienceData
 * @returns demographics object
 */
export default function reconcileDemographicsData(audienceData) {
  const v2Data = audienceData.demographicsV2;
  // if demographicsV2 exists and is not empty array
  if (v2Data && !_.isArray(v2Data)) {
    // convert v2 data to right format
    // eslint-disable-next-line no-param-reassign
    return {
      locations: v2Data.audienceGeo.reduce(
        (res, { name, weight }) => ({
          ...res,
          [name]: Number((weight * 100).toFixed(1)),
        }),
        {}
      ),
      ethnicity: v2Data.audienceRaces.reduce(
        (res, { name, weight }) => ({
          ...res,
          [name]: Number((weight * 100).toFixed(1)),
        }),
        {}
      ),
      ageGenderRanges: v2Data.genderData.reduce(
        (res, item) => {
          const { code, male, female } = item;
          res.female[code] = female * 100;
          res.male[code] = male * 100;
          return res;
        },
        {
          female: {},
          male: {},
        }
      ),
      cities: v2Data.audienceGeoCities.reduce(
        (res, { name, weight }) => ({
          ...res,
          [name]: Number((weight * 100).toFixed(1)),
        }),
        {}
      ),
    };
  }
  if (_.has(audienceData, 'demographics')) {
    // demographics is an empty array when there's no data
    if (_.isArray(audienceData.demographics)) {
      return {};
    }
    return audienceData.demographics;
  }
  // another version of demographics data
  return _.pick(audienceData, [
    'ageGenderRanges',
    'locations',
    'ethnicity',
    'cities',
  ]);
}
