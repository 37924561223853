export const OK = 10;
export const INCOMPLETE = 11;
export const UNFINISHED = 12;
export const QUOTA_EXCEEDED = 13;
export const ACCOUNT_EXPIRED = 14;
export const FORBIDDEN = 15;
export const INTERNAL_ERROR = 20;
export const INVALID_ACCESS_TOKEN = 21;
export const THRESHOLD_EXCEEDED = 22;
export const INVALID_ACTION = 23;
export const INVALID_DATA = 24;
export const MAINTENANCE_MODE = 25;
export const NOT_ENOUGH_DATA = 26;
export const NOT_FOUND = 27;
