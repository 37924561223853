import {
  ADD_ARTICLE_FAILED,
  ADD_ARTICLE_REQUEST,
  ADD_ARTICLE_SUCCESS,
  CHECK_HUBSPOT_NOT_CONNECTED,
  CHECK_HUBSPOT_REQUEST,
  CHECK_HUBSPOT_SUCCESS,
  CHECK_MAILCHIMP_NOT_CONNECTED,
  CHECK_MAILCHIMP_REQUEST,
  CHECK_MAILCHIMP_SUCCESS,
  CHECK_MARKETO_SUCCESS,
  CHECK_WORDPRESS_NOT_CONNECTED,
  CHECK_WORDPRESS_REQUEST,
  CHECK_WORDPRESS_SUCCESS,
  CREATE_CONTENT_SOURCE_FAIL,
  CREATE_CONTENT_SOURCE_SUCCESS,
  FETCH_GA_PROFILES,
  GET_ACCOUNT_INFO,
  GET_BILLING_INFO,
  GET_CONTENT_SOURCES,
  HUBSPOT_CONNECT_FAIL,
  HUBSPOT_CONNECT_SUCCESS,
  HUBSPOT_DISCONNECT_FAIL,
  HUBSPOT_DISCONNECT_SUCCESS,
  MAILCHIMP_CONNECT_FAIL,
  MAILCHIMP_CONNECT_SUCCESS,
  REQUEST_CREATE_CONTENT_SOURCE,
  RESET_SETTINGS,
  SET_INITAL_PAGE,
  SET_PREVIOUS_PAGE,
  SPLICE_WORD_FROM_LIST,
  TOGGLE_BILLING_FETCHING,
  UPDATE_COMPLIANCE_WORD_LIST,
  UPDATE_CUSTOM_WORD_LIST,
  UPDATE_IGNORE_WORD_LIST,
  UPDATE_WORDS_TO_ADD,
  MARKETO_CONNECT_FAIL,
  MARKETO_CONNECT_SUCCESS,
  CHECK_MARKETO_REQUEST,
  CHECK_MARKETO_NOT_CONNECTED,
  UPDATE_HUBSPOT_BLOGS_DATA,
  CHECK_ADWORDS_REQUEST,
  CHECK_ADWORDS_SUCCESS,
  CHECK_ADWORDS_NOT_CONNECTED,
  ADS_LOADING_DONE,
  ADS_LOADING_START,
  GET_SEARCH_CONSOLE_PROPERTIES,
  MARKETO_SET_FOLDERS,
  MARKETO_FOLDERS_FETCHING, TOGGLE_FETCHING_SUB_ACCOUNT, SUB_ACCOUNTS_DATA, UPDATE_SOCIAL_MEDIA_CONNS, UPDATE_SOCIAL_MEDIA_CONN_LOADING_STATUS,
} from '../constants/actionTypes';
import { MARKETO_GET_FOLDERS } from '../constants/apiUrls';

const initialState = {
  previousTab: '',
  previousPath: '',
  previousData: {},
  initialPrevious: false,
  accountInfo: {},
  billingInfo: {},
  fetchingBillingInfo: false,
  wordList: {
    dictionary: {},
    compliance: {},
    ignore: {},
  },
  wordsToAdd: [],
  fetchingHubspotConnection: false,
  fetchingMailchimpConnection: false,
  fetchingMarketoConnection: false,
  fetchingWordpressConnection: false,
  fetchingAdwordsConnection: false,
  wordpressConnection: {
    connected: false,
    data: {},
  },
  hubspotConnection: {
    connected: false,
    data: {},
  },
  hubspotBlogs: [],
  mailchimpConnection: {
    connected: false,
    data: {},
  },
  marketoConnection: {
    connected: false,
    data: {},
  },
  marketoFoldersFetching: false,
  marketoFolders: {
    treeData: [],
    defaultCheckedKeys: [],
  },
  adwordsConnection: {
    connected: false,
    data: {},
  },
  adwordsConnections: [],
  adwordsDefault: '',
  activeConnectionId: '',
  contentSources: [],
  creatingContentSource: false,
  creatingContentSourceSuccess: '',
  creatingContentSourceError: '',
  GaProfiles: {},
  clientCustomerAccounts: [],
  addingNewArticle: false,
  fetchingSubAccounts: false,
  subAccountsData: {},
  loadingSocialMediaConnections: false,
  socialMediaConnections: {},
};

const settingsApp = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: action.accountInfo,
      };
    case GET_BILLING_INFO:
      return {
        ...state,
        billingInfo: {
          customer: action.billingInfo.customer,
          paymentProfile: action.billingInfo.paymentProfile,
          statement: action.billingInfo.statement,
          subscription: action.billingInfo.subscription,
        },
        billingInfoFound: true,
      };
    case TOGGLE_BILLING_FETCHING:
      return {
        ...state,
        fetchingBillingInfo: !state.fetchingBillingInfo,
      };
    case TOGGLE_FETCHING_SUB_ACCOUNT:
      return {
        ...state,
        fetchingSubAccounts: !state.fetchingSubAccounts,
      };
    case SUB_ACCOUNTS_DATA:
      return{
        ...state,
        subAccountsData: action.subAccountsData
      }
    case UPDATE_CUSTOM_WORD_LIST:
      const wordList = { ...state.wordList, dictionary: action.wordList };
      return {
        ...state,
        wordList,
      };
    case UPDATE_IGNORE_WORD_LIST:
      const wordList2 = { ...state.wordList, ignore: action.wordList };
      return {
        ...state,
        wordList: wordList2,
      };
    case UPDATE_COMPLIANCE_WORD_LIST:
      const wordList3 = { ...state.wordList, compliance: action.wordList };
      return {
        ...state,
        wordList: wordList3,
      };
    case SPLICE_WORD_FROM_LIST:
      const { word, letter, list } = action;
      const wl = { ...state.wordList };
      const index = wl[list][letter].indexOf(word);
      if (index !== -1) {
        wl[list][letter].splice(index, 1);
      }
      return {
        ...state,
        wordList: wl,
      };
    case UPDATE_WORDS_TO_ADD:
      return {
        ...state,
        wordsToAdd: action.words,
      };

    case CHECK_HUBSPOT_REQUEST:
      return {
        ...state,
        fetchingHubspotConnection: true,
      };
    case CHECK_HUBSPOT_SUCCESS:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: true,
          data: action.response.data,
        },
      };
    case CHECK_HUBSPOT_NOT_CONNECTED:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: false,
          data: {},
        },
      };
    case CHECK_MARKETO_REQUEST:
      return {
        ...state,
        fetchingMarketoConnection: true,
      };
    case MARKETO_SET_FOLDERS:
      return {
        ...state,
        marketoFolders: action.response.data,
      };

    case MARKETO_FOLDERS_FETCHING:
      return {
        ...state,
        marketoFoldersFetching: action.status,
      };
    case CHECK_MARKETO_SUCCESS:
      return {
        ...state,
        fetchingMarketoConnection: false,

        marketoConnection: {
          connected: true,
          data: action.response.data,
        },
      };
    case CHECK_MARKETO_NOT_CONNECTED:
      return {
        ...state,
        fetchingMarketoConnection: false,
        marketoConnection: {
          connected: false,
          data: false,
        },
      };

    case CHECK_ADWORDS_REQUEST:
      return {
        ...state,
        fetchingAdwordsConnection: true,
      };
    case ADS_LOADING_START:
      return {
        ...state,
        fetchingAdwordsConnection: true,
      };
    case ADS_LOADING_DONE:
      return {
        ...state,
        fetchingAdwordsConnection: false,
      };
    case CHECK_ADWORDS_SUCCESS:
      const clientCustomerList = [];

      Object.keys(action.response.data).forEach(key => {
        const results = {
          id: key,
          name: action.response.data[key],
        };

        clientCustomerList.push(results);
      });

      return {
        ...state,
        fetchingAdwordsConnection: false,
        adwordsDefault: action.response.defaultCustomerId,
        adwordsConnections: action.response.connections,
        activeConnectionId: action.response.activeConnectionId,
        clientCustomerAccounts: clientCustomerList,
        adwordsConnection: {
          connected: true,
          data: action.response.data,
        },
      };
    case CHECK_ADWORDS_NOT_CONNECTED:
      return {
        ...state,
        fetchingAdwordsConnection: false,
        adwordsConnection: {
          connected: false,
          data: false,
        },
      };

    case CHECK_MAILCHIMP_REQUEST:
      return {
        ...state,
        fetchingMailchimpConnection: true,
      };
    case CHECK_MAILCHIMP_SUCCESS:
      return {
        ...state,
        fetchingMailchimpConnection: false,
        mailchimpConnection: {
          connected: true,
          data: action.response.data,
        },
      };
    case CHECK_MAILCHIMP_NOT_CONNECTED:
      return {
        ...state,
        fetchingMailchimpConnection: false,
        mailchimpConnection: {
          connected: false,
          data: false,
        },
      };
    case HUBSPOT_DISCONNECT_SUCCESS:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: false,
          data: false,
        },
      };

    case HUBSPOT_DISCONNECT_FAIL:
      return {
        ...state,
        fetchingHubspotConnection: false,
      };

    case HUBSPOT_CONNECT_SUCCESS:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: false,
          data: action.response.data,
        },
      };
    case HUBSPOT_CONNECT_FAIL:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: false,
          data: false,
        },
      };

    case MAILCHIMP_CONNECT_SUCCESS:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: false,
          data: action.response.data,
        },
      };
    case MAILCHIMP_CONNECT_FAIL:
      return {
        ...state,
        fetchingHubspotConnection: false,
        hubspotConnection: {
          connected: false,
          data: action.response.data,
        },
      };
    case RESET_SETTINGS:
      return initialState;

    case SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousTab: action.tab,
        previousPath: action.path,
        previousProfileData: action.profileData,
      };
    case SET_INITAL_PAGE:
      return {
        ...state,
        initialPrevious: action.value,
      };

    case REQUEST_CREATE_CONTENT_SOURCE:
      return {
        ...state,
        creatingContentSource: true,
      };
    case CREATE_CONTENT_SOURCE_SUCCESS:
      return {
        ...state,
        creatingContentSource: false,
        creatingContentSourceSuccess: action.response,
      };
    case CREATE_CONTENT_SOURCE_FAIL:
      return {
        ...state,
        creatingContentSource: false,
        creatingContentSourceError: action.response.error,
      };

    case CHECK_WORDPRESS_REQUEST:
      return {
        ...state,
        fetchingWordpressConnection: true,
      };
    case CHECK_WORDPRESS_SUCCESS:
      return {
        ...state,
        fetchingWordpressConnection: false,
        wordpressConnection: {
          connected: action.response.data.length > 0,
          data: action.response.data,
        },
      };
    case CHECK_WORDPRESS_NOT_CONNECTED:
      return {
        ...state,
        fetchingWordpressConnection: false,
        wordpressConnection: {
          connected: false,
          data: {},
        },
      };

    case GET_CONTENT_SOURCES:
      return {
        ...state,
        contentSources: action.response.sources,
      };

    case GET_SEARCH_CONSOLE_PROPERTIES:
      return {
        ...state,
        searchConsoleProperties: action.response.data
          ? action.response.data
          : null,
      };

    case ADD_ARTICLE_REQUEST:
      return {
        ...state,
        addingNewArticle: true,
      };
    case ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        addingNewArticle: false,
      };
    case ADD_ARTICLE_FAILED:
      return {
        ...state,
        addingNewArticle: true,
      };
    case FETCH_GA_PROFILES:
      return {
        ...state,
        GaProfiles: action.response.data,
      };
    case UPDATE_HUBSPOT_BLOGS_DATA:
      return {
        ...state,
        hubspotBlogs: action.hubspotBlogs,
      };
    case UPDATE_SOCIAL_MEDIA_CONN_LOADING_STATUS:
      return {
        ...state,
        loadingSocialMediaConnections: action.payload,
      };
    case UPDATE_SOCIAL_MEDIA_CONNS:
      return {
        ...state,
        socialMediaConnections: action.payload,
      };
    default:
      return state;
  }
};

export default settingsApp;
