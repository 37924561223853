/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { PROFILE_DATA_CLIENT_URL } from '../../constants/apiUrls';
import { BLOG_CONTENT_TYPE } from '../../constants/internalConsts';
import arClient from '../../util/api_client';
import {
  DEFAULT_KPI,
  KPIS,
  CONTENT_TYPES,
  DEFAULT_CONTENT_TYPE,
} from './common/constants';

const initialState = {
  profiles: null,
};

const AnalyzeSlice = createSlice({
  name: 'Analyze',
  initialState,
  reducers: {
    setProfiles(state, action) {
      const {
        payload: { profiles },
      } = action;
      state.profiles = profiles;
    },
  },
});

export const { setProfiles } = AnalyzeSlice.actions;

export const loadProfiles = () => (dispatch, getState) => {
  const {
    Analyze: { profiles },
  } = getState();
  // Load profiles list once
  if (profiles !== null) {
    return;
  }
  arClient.doApiCall(
    PROFILE_DATA_CLIENT_URL,
    {
      type: BLOG_CONTENT_TYPE,
    },
    respApi => {
      const { data } = respApi;
      // Be aware to store ids as strings to make easier comparison
      // with filters defined in the url query string
      const list = data
        .filter(({ status }) => status !== 'archived')
        .map(({ id, name }) => ({ id: `${id}`, name }));
      dispatch(setProfiles({ profiles: list }));
    }
  );
};

export const updateSearchParamsFilter = (history, location, key, value) => {
  const searchParams = new URLSearchParams(location.search);
  if (key === 'profile') {
    // profile changed, reset all filters
    searchParams.delete('date');
    searchParams.set('kpi', DEFAULT_KPI);
  }
  if (value === null) {
    // when value is null remove filter
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  // Any filter change resets table page
  searchParams.set('page', '1');
  history.push({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
  if (key === 'profile') {
    return { page: 1, date: null, kpi: DEFAULT_KPI, profile: value };
  }
  return { page: 1, [key]: value };
};

export const initFilters = (history, location, profiles) => {
  const searchParams = new URLSearchParams(location.search);
  let kpi = searchParams.get('kpi');
  let profile = searchParams.get('profile');
  let contentType = searchParams.get('contentType');
  let dateFilter = null;
  let page = searchParams.get('page');
  if (!Object.keys(KPIS).includes(kpi)) {
    kpi = DEFAULT_KPI; // invalid kpi on query string
  }
  if (!profiles.find(elem => elem.id === [profile])) {
    profile = profiles.length > 0 ? profiles[0].id : null;
  }
  if (!CONTENT_TYPES.find(elem => elem.id === contentType)) {
    contentType = DEFAULT_CONTENT_TYPE;
  }
  const dateTokens = (searchParams.get('date') || '')
    .split('-')
    .filter(elem => /^\d+$/.test(elem));
  if (dateTokens.length === 2) {
    const moment0 = moment(dateTokens[0], 'x');
    const moment1 = moment(dateTokens[1], 'x');
    if (moment0.isBefore(moment1)) {
      dateFilter = searchParams.get('date');
    }
  }
  if (!/^\d+$/.test(page)) {
    page = 1; // invalid page
  }
  searchParams.set('kpi', kpi);
  searchParams.set('profile', profile);
  searchParams.set('contentType', contentType);
  if (dateFilter) {
    searchParams.set('date', dateFilter);
  }
  searchParams.set('page', page);
  history.push({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
  return { kpi, profile, contentType, page, dateFilter };
};

export default AnalyzeSlice.reducer;
