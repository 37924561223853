import { createSlice } from '@reduxjs/toolkit';
import arClient from '../../util/api_client';

const initialState = {
  sort: { field: 'followers', direction: 'desc' },
  page: 0,
  universalPersonaFilters: {
    instagram: {
      filter: {
        influencer: {
          followers: { min: 0, max: 50000000 },
          engagementRate: null,
          location: [],
          language: '',
          lastposted: null,
          relevance: [],
          gender: '',
          age: { min: 0, max: 45 },
          followersGrowthRate: null,
          hasYouTube: null,
          hasContactDetails: [],
          accountTypes: [],
          brands: [],
          interests: [],
          keywords: '',
          bio: '',
          textTags: [],
          reelsPlays: {},
        },
        audience: {
          location: [],
          language: '',
          gender: '',
          age: [],
          interests: [],
          credibility: null,
          ethnicity: [],
        },
        LDA: null,
      },
      selectedFilters: {},
    },
    tiktok: {
      filter: {
        influencer: {
          followers: { min: 0, max: 50000000 },
          engagementRate: null,
          location: [],
          language: '',
          lastposted: null,
          relevance: [],
          gender: '',
          age: { min: 0, max: 45 },
          followersGrowthRate: null,
          views: {},
          hasYouTube: null,
          hasContactDetails: [],
          accountTypes: [],
          brands: [],
          interests: [],
          keywords: '',
          bio: '',
          textTags: [],
          reelsPlays: {},
        },
        audience: {
          location: [],
          language: '',
          gender: '',
          age: [],
          interests: [],
          credibility: null,
          ethnicity: [],
        },
        LDA: null,
      },
      selectedFilters: {},
    },
    youtube: {
      filter: {
        influencer: {
          followers: { min: 0, max: 50000000 },
          engagementRate: null,
          location: [],
          language: '',
          lastposted: null,
          relevance: [],
          gender: '',
          age: { min: 0, max: 45 },
          followersGrowthRate: null,
          views: {},
          hasContactDetails: [],
          accountTypes: [],
          brands: [],
          interests: [],
          keywords: '',
          bio: '',
          textTags: [],
        },
        audience: {
          location: [],
          language: '',
          gender: '',
          age: [],
          interests: [],
          credibility: null,
          ethnicity: [],
        },
        LDA: null,
      },
      selectedFilters: {},
    },
    twitch: {
      filter: {
        influencer: {
          followers: { min: 0, max: 50000000 },
          twitch_live_viewers_avg: {},
          subscribers_count: {},
          twitch_games: {
            games: [],
            period: 30,
          },
          twitch_hours_streamed: {},
          twitch_active_days_per_week: {},
          hasYouTube: null,
          hasContactDetails: [],
          accountTypes: [],
          brands: [],
          interests: [],
          keywords: '',
          bio: '',
          textTags: [],
          age: { min: 0, max: 45 },
          engagementRate: null,
          location: [],
          gender: '',
        },
        audience: {
          location: [],
          language: '',
          gender: '',
          age: [],
          interests: [],
          credibility: null,
          ethnicity: [],
        },
        LDA: null,
      },
      selectedFilters: {},
    },
    twitter: {
      filter: {
        influencer: {
          followers: { min: 0, max: 50000000 },
          twitter_tweet: { min: 100 },
          subscribers_count: { min: 5000 },
          hasYouTube: false,
          hasContactDetails: [],
          accountTypes: [],
          brands: [],
          interests: [],
          keywords: '',
          bio: '',
          textTags: [],
          age: { min: 0, max: 45 },
          engagementRate: null,
          location: [],
          gender: '',
        },
        audience: {
          location: [],
          language: '',
          gender: '',
          age: [],
          interests: [],
          credibility: null,
          ethnicity: [],
        },
        LDA: null,
      },
      selectedFilters: {},
    },
  },
  universalList: {},
  universalListLoading: false,
  influencerReport: {},
  influencerReportLoading: false,
  savedList: [],
  savedListLoading: false,
};

const UniversalPersonasSlice = createSlice({
  name: 'UniversalPersona',
  initialState,
  reducers: {
    setSort(state, action) {
      state.sort = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setFilter(state, action) {
      const { network, filter, value } = action.payload;
      if (state.universalPersonaFilters[network]) {
        state.universalPersonaFilters[network].filter.influencer[
          filter
        ] = value;

        if (
          !state.universalPersonaFilters[network].selectedFilters.influencer
        ) {
          state.universalPersonaFilters[
            network
          ].selectedFilters.influencer = {};
        }

        state.universalPersonaFilters[network].selectedFilters.influencer[
          filter
        ] = value;
      }
    },
    setAudienceFilter(state, action) {
      const { network, filter, value } = action.payload;
      if (state.universalPersonaFilters[network]) {
        state.universalPersonaFilters[network].filter.audience[filter] = value;


        if (!state.universalPersonaFilters[network].selectedFilters.audience) {
          state.universalPersonaFilters[network].selectedFilters.audience = {};
        }

        state.universalPersonaFilters[network].selectedFilters.audience[
          filter
        ] = value;
      }
    },
    setGeneralFilter(state, action) {
      const { network, filter, value } = action.payload;
      if (state.universalPersonaFilters[network]) {
        state.universalPersonaFilters[network].filter[filter] = value;
        state.universalPersonaFilters[network].selectedFilters[filter] = value;
      }
    },
    resetToInitialState(state) {
      state.universalPersonaFilters = initialState.universalPersonaFilters;
      state.universalList = initialState.universalList;
    },

    setUniversalList(state, action) {
      const { payload } = action;
      state.universalList = payload;
    },

    setUniversalListLoading(state, action) {
      const { payload } = action;
      state.universalListLoading = payload;
    },

    setInfluencerReport(state, action) {
      const { payload } = action;
      state.influencerReport = payload;
    },

    setInfluencerReportLoading(state, action) {
      const { payload } = action;
      state.influencerReportLoading = payload;
    },
    setSavedList(state, action) {
      const { payload } = action;
      state.savedList = payload;
    },

    setSavedListLoading(state, action) {
      const { payload } = action;
      state.savedListLoading = payload;
    },
  },
});

export const {
  setSort,
  setPage,
  setFilter,
  setAudienceFilter,
  resetToInitialState,
  setGeneralFilter,
  setUniversalList,
  setUniversalListLoading,
  setInfluencerReport,
  setInfluencerReportLoading,
  setSavedList,
  setSavedListLoading,
} = UniversalPersonasSlice.actions;

export const getCreatorReport = (
  universalListId,
  username,
  socialNetwork
) => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      let report = {};
      try {
        report =
          respApi?.data && respApi?.data?.normalizedRespData
            ? JSON.parse(respApi?.data?.normalizedRespData)
            : [];
      } catch (error) {
        console.error('invalid JSON string', error);
      }
      dispatch(setInfluencerReport(report));
    } else {
      dispatch(setInfluencerReport({}));
      console.error('get report failed', respApi);
    }
    dispatch(setInfluencerReportLoading(false));
  };
  dispatch(setInfluencerReportLoading(true));
  arClient.doApiCall(
    '/universal-list/getCreatorReport',
    { universalListId, username, socialNetwork },
    callback
  );
};

export const searchUniversalList = (
  socialNetwork,
  objParam,
  pageIndex
) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = respApi => {
      console.log('respapi', respApi);
      if (respApi.status === 10) {
        if (respApi.data.length > 0) {
          dispatch(
            setUniversalList({
              0: {
                data: respApi.data,
              },
              total: respApi.total,
              universalListId: respApi.universalListId,
              indStdER: respApi.indStdER,
            })
          );
        }
        resolve();
      } else {
        console.error('universal list failed', respApi);
        reject();
      }
      dispatch(setUniversalListLoading(false));
    };

    const filterMod = JSON.stringify(objParam);

    const payload = {
      socialNetwork,
      filtersJson: filterMod,
      pageIndex,
    };

    dispatch(setUniversalListLoading(true));
    arClient.doApiCall('/universal-list/create', payload, callback);
  });
};

export const getUniversalList = (
  name,
  socialNetwork,
  objParam,
  pageIndex
) => async (dispatch, getState) => {
  const state = getState();
  const { universalList } = state.UniversalPersona;

  if (universalList[pageIndex]) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    const callback = respApi => {
      if (respApi.status === 10) {
        if (pageIndex === 0) {
          dispatch(
            setUniversalList({
              0: {
                data: respApi.data,
              },
              total: respApi.total,
              universalListId: respApi.universalListId,
              indStdER: respApi.indStdER,
            })
          );
        } else {
          dispatch(
            setUniversalList({
              ...universalList,
              [pageIndex]: {
                data: respApi.data,
              },
            })
          );
        }
        resolve();
      } else {
        console.error('universal list failed', respApi);
        reject();
      }
      dispatch(setUniversalListLoading(false));
    };

    const filterMod = JSON.stringify(objParam);

    const payload = {
      socialNetwork,
      filtersJson: filterMod,
      pageIndex,
    };

    if (name) {
      payload.name = name;
    }

    dispatch(setUniversalListLoading(true));
    arClient.doApiCall('/universal-list/create', payload, callback);
  });
};

export const getSavedLists = () => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(
        setSavedList({ data: respApi.data, indStdER: respApi.indStdER })
      );
    } else {
      console.error('universal list failed', respApi);
    }
    dispatch(setSavedListLoading(false));
  };
  dispatch(setSavedListLoading(true));
  arClient.doApiCall('/universal-list/fetchSavedLists', {}, callback);
};

export const linkInfluencersToCampaigns = (
  builderRequestId,
  segmentId,
  infArray
) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/universal-list/saveInfToAud',
      {
        builderRequestId,
        segmentId,
        infArray: JSON.stringify(infArray),
      },
      callback
    );
  });
};

export const saveList = (universalListId, name) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/universal-list/saveList',
      {
        universalListId,
        name,
      },
      callback
    );
  });
};

export default UniversalPersonasSlice.reducer;
