import {
  RESET_PRODUCT_DESCRIPTIONS_PROFILE_DATA,
  GET_PRODUCT_DESCRIPTIONS_REQUEST,
  GET_PRODUCT_DESCRIPTIONS_MEASURES_REQUEST,
  GET_PRODUCT_DESCRIPTIONS_MEASURES_SUCCESS,
  GET_PRODUCT_DESCRIPTIONS_SUCCESS,
  PRODUCT_DESCRIPTIONS_LOADING_DONE,
  CREATE_PROFILES_REQUEST_PRODUCT_DESCRIPTIONS,
  CREATE_PROFILES_SUCCESS_PRODUCT_DESCRIPTIONS,
  CREATE_PROFILES_FAILURE_PRODUCT_DESCRIPTIONS,
  ADDED_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS,
  ADDED_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS,
  ADDED_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS,  
  PRODUCT_DESCRIPTIONS_GET_PROFILES_REQUEST,
  PRODUCT_DESCRIPTIONS_GET_PROFILES_SUCCESS,
  GET_PROFILE_MEASURES_SUCCESS_PRODUCT_DESCRIPTIONS,
  GET_PROFILE_PRODUCT_DESCRIPTIONS_SUCCESS,
  GET_PRODUCT_DESCRIPTIONS_CATEGORIES_SUCCESS,
  GET_ADGROUPS_SUCCESS,
  GET_PRODUCT_DESCRIPTIONS_CATEGORIES_REQUEST,
  GET_ADGROUPS_REQUEST,
  SELECTED_PROFILE_FOR_MUTILPLE_ADS,
  SAVED_BATCH_SUCCESS,
  TRY_SAVING_BATCH,
  GET_PRODUCT_DESCRIPTION_TOTALS_REQUEST,
  GET_PRODUCT_DESCRIPTIONS_TOTALS,
  GET_PRODUCT_DESCRIPTIONS_PROFILE_SUCCESS,
  PRODUCT_DESCRIPTIONS_SELECTION_REQUEST,
  PRODUCT_DESCRIPTIONS_SELECTION_SUCCESS,
  RESET_ADS_LIST,
  PROFILE_CALCULATE_SUCCESS_PRODUCT_DESCRIPTIONS,
  GET_PROFILE_MEASURES_REQUEST_PRODUCT_DESCRIPTIONS,
  GET_PROFILE_MEASURES_DETAILS_SUCCESS_PRODUCT_DESCRIPTIONS,
  REMOVE_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS,
  REMOVE_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS,
  REMOVE_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS,
} from '../constants/actionTypes';

const initialState = {
  profileId: 0,
  fetchingProductDescriptions: false,
  fetchingContentMeasures: false,
  fetchingCategories: false,
  fetchingProductDescriptionsMeasures: false,
  fetchingProfileAProductDescriptions: false,
  fetchingAdTotals: false,
  savingBatch: false,
  fetchingBatch: false,
  deletingBatch: false,
  fetchingProductDescriptionselection: false,
  fetchingProfileBucketCorpus: false,

  adsMeasures: {},

  batches: [],

  profileDetails: {
    productDescriptions: [],
    tableTotal: 0,
    offset: 0,
    limit: 10,
    page: 1,

  },


  productDescriptions: [],
  offset: 0,
  limit: 0,
  totalCount: 0,
  tableTotal: 0,

  profileAds: [],
  profileOffset: 0,
  profileLimit: 10,
  profilePage: 1,
  profileTotalCount: 0,
  profileCutOff: 0,
  profileMatch: 0,
  cutOffValue: 0,

  profileSelected: {
    items: [],
    totalCount: 0,
  },


  campaigns: [],
  campaignsObj: {},

  adGroups: [],
  adGroupsObj: {},

  contentDataArray: [],
  adMeasures: {},
  adsMeasureNames: '',

  creatingProfileId: false,
  currentScoringProfile: 0,

  fetchingProfiles: true,
  fetchingProfileMeasureDetails: false,
  fetchingProfileAllMeasures: false,

  engagementNames: {
    unitsSold: 'Units Sold',
    viewToCart: 'View to Cart',
    viewToBuy: 'View to Buy',
    GA_revenuePerItem: 'Revenue per Item',
    GA_itemQuantity: 'Item Quantity',
    GA_uniquePurchases: 'Unique Purchases',
  },

    engagementSelection: [
    {
      icon: 'trending_up',
      text: 'UnitsSold',
      color: '#68d4f0',
      name: 'unitSold',
    },
    {
      icon: 'hand-pointer',
      text: 'CTR',
      color: '#FBB157',
      name: 'ctr',
    },
    {
      icon: 'ban',
      text: 'Unsubscribe Rate',
      color: '#dc3545',
      name: 'unsubscribeRate',
    },
  ],

  selectedProfileForMultipleAds: 0,
  negativeEngagement: [],


  profiles: [],
  profile: {},
  profileName: ' ',
  standardProfile: false,
  profileMeasures: {},
  profileBodyEngagement: '',
  profileTitleEngagement: '',
  profileBucket: '',
  selectedMeasures: [],
  profileCutOffEngagement: {},
  calculatingProfile: false,
  addingContentData: false,
  removingContentData: false,

  editor: {
    originalAd: {
      headline1: '',
      headline2: '',
      headline3: '',
      displayUrl: '',
      displayPath1: '',
      displayPath2: '',
      finalUrl: '',
      description1: '',
      description2: '',
    },
    // array of ad objects to be optimized (key - value storage, key is an ad id)
    batchAds: {},
    // queue of optimized ads (key - value storage, key is an original ad id)
    queueAds: {},

    optimizedAds: {},
    profilesSelected: [],
    campaigns: [],
    adGroups: [],
    campaignSelected: null,
    campaignSelectedName: '',
    adGroupSelected: null,
    adGroupSelectedName: '',
    connectionSelected: null,
    optimizeLoading: false,
  },
};

const productDescriptionsApp = (state = initialState, action) => {  
  switch (action.type) {
    case GET_PRODUCT_DESCRIPTIONS_REQUEST:
      return {
        ...state,
        productDescriptions: [],
        fetchingProductDescriptions: true,
      };

    case PRODUCT_DESCRIPTIONS_SELECTION_REQUEST:

      return {
        ...state,
        fetchingProductDescriptionselection: true,
        fetchingProfileProductDescriptions: true,
        profileSelected: {
          items: [],
          totalCount: 0,
        },
        profileDetails: {
          productDescriptions: [],
          totalCount: 0,
          offset: 0,
          limit: 10,
          page: 1,

        },

      };

    case PRODUCT_DESCRIPTIONS_SELECTION_SUCCESS:
      return {
        ...state,
        fetchingProductDescriptionselection: false,
        fetchingProfileProductDescriptions: false,
        profileBodyEngagement: action.response.scoringProfileBodyEngagement,
        profileTitleEngagement: action.response.scoringProfileTitleEngagement,
        profileSelected: action.response.selectedData,
        profileDetails: {
          productDescriptions: action.response.allData.items,
          tableTotal: action.response.allData.totalCount,
          offset: action.response.allData.offset,
          limit: 10,
          page: action.response.allData.page,
        },
      };
    case GET_PRODUCT_DESCRIPTION_TOTALS_REQUEST:
      return {
        ...state,
        fetchingAdTotals: true,
      };
    case GET_PRODUCT_DESCRIPTIONS_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: [],
        fetchingCategories: true,
      };
    case GET_ADGROUPS_REQUEST:
      return {
        ...state,
        adGroups: [],
        adGroupsObj: {},
        fetchingAdGroups: true,
      };
    case GET_PRODUCT_DESCRIPTIONS_MEASURES_REQUEST:
      return {
        ...state,
        fetchingProductDescriptionsMeasures: true,
        adsMeasures: {},
      };
    case GET_PRODUCT_DESCRIPTIONS_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        productDescriptions: action.response.data.items,
        offset: action.response.data.offset,
        limit: action.response.data.limit,
        tableTotal: action.response.data.totalCount,
        fetchingProductDescriptions: false,
      };
    case GET_PRODUCT_DESCRIPTIONS_PROFILE_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        profileDetails: {
          productDescriptions: action.response.data.items,
          offset: action.response.data.offset,
          limit: action.response.data.limit,
          tableTotal: action.response.data.totalCount,
          page: action.response.data.page,
        },
        fetchingProfileProductDescriptions: false,
      };
      case GET_PRODUCT_DESCRIPTIONS_TOTALS:
      // take care of the case when there are no emails
      return {
        ...state,
        totalCount: action.response.data.totalCount,
        profileMatch: action.response.data.profileMatchPercentage,
        cutOffValue: action.response.data.cutOffValue,
        fetchingAdTotals: false,
      };
    case SELECTED_PROFILE_FOR_MUTILPLE_ADS:

      return {
        ...state,
        selectedProfileForMultipleAds: action.profile.id,
      };

    case GET_PROFILE_PRODUCT_DESCRIPTIONS_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        profileAds: action.response.data.items,
        profileOffset: action.response.data.offset,
        profileLimit: action.response.data.limit,
        profileMatch: action.response.data.profileMatchPercentage,
        cutOffValue: action.response.data.cutOffValue,
        profileTotalCount: action.response.data.totalCount,
        profilePage: action.response.data.page,
        fetchingProductDescriptions: false,
      };
    case GET_PRODUCT_DESCRIPTIONS_MEASURES_SUCCESS:
      // take care of the case when there are no emails
      return {
        ...state,
        adsMeasures: action.response.measuresData,
        adsMeasureNames: action.response.measuresNames,
        fetchingProductDescriptionsMeasures: false,
      };

    case GET_ADGROUPS_SUCCESS:
      return {
        ...state,
        adGroups: action.response.data.items,
        adGroupsObj: action.response.data.itemObj,
        fetchingAdGroups: false,
      };

    case GET_PRODUCT_DESCRIPTIONS_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesData: action.response.data.items,
        fetchingCategories: false,
      };
    case PRODUCT_DESCRIPTIONS_LOADING_DONE:
      return {
        ...state,
        fetchingProductDescriptions: false,
      };
    case CREATE_PROFILES_REQUEST_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        creatingProfileId: true,
      };
    case CREATE_PROFILES_SUCCESS_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        creatingProfileId: false,
        createdProfileId: action.response.data.profileId,
      };
    case CREATE_PROFILES_FAILURE_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        creatingProfileId: false,
        creatingProfileIdError: action.error,
      };
    case ADDED_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        addingContentData: true,
      };
    case ADDED_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        addingContentData: false,
        addedContentData: action.response.params.contentDataIds
      };
    case ADDED_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        addingContentData: false,
        addedContentDataError: action.error,
      };      
    case PRODUCT_DESCRIPTIONS_GET_PROFILES_REQUEST:
      return {
        ...state,
        fetchingProfiles: true,
        profiles: [],
      };
    case PRODUCT_DESCRIPTIONS_GET_PROFILES_SUCCESS:

      return {
        ...state,
        fetchingProfiles: false,
        profiles: action.response.data,
      };
    case GET_PROFILE_MEASURES_SUCCESS_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        profileMeasures: action.response,
        standardProfile: action.response.standard,
        profileName: action.response.profileName,
        currentScoringProfile: action.response.profileId,
        profileBodyEngagement: action.response.scoringProfileBodyEngagement,
        profileTitleEngagement: action.response.scoringProfileTitleEngagement,

      };
    case PROFILE_CALCULATE_SUCCESS_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        calculatedProfile: action.response.message,
        calculatingProfile: false,
        calculatedError: '',
      };
    case RESET_ADS_LIST:
      return {
        ...state,
        productDescriptions: [],
      };
    case TRY_SAVING_BATCH:
      return {
        ...state,
        savingBatch: true,
      };

    case SAVED_BATCH_SUCCESS:
      return {
        ...state,
        savingBatch: false,
      };
    case RESET_PRODUCT_DESCRIPTIONS_PROFILE_DATA:
      return initialState;
    case GET_PROFILE_MEASURES_REQUEST_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        fetchingProfileAllMeasures: true,
        profileMeasures: {},
      };
    case GET_PROFILE_MEASURES_DETAILS_SUCCESS_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        fetchingProfileMeasureDetails: false,
        selectedMeasures: action.response.data.measure,
      };
    case REMOVE_CONTENT_REQUEST_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        fetchingProfileAllMeasures: true,
        fetchingProductDescriptions: true,
      };
    case REMOVE_CONTENT_SUCCESS_PRODUCT_DESCRIPTIONS:
    case REMOVE_CONTENT_FAILURE_PRODUCT_DESCRIPTIONS:
      return {
        ...state,
        fetchingProfileAllMeasures: false,
        fetchingProductDescriptions: false,
      };
    default:
      return state;
  }
};

export default productDescriptionsApp;
