import _ from 'lodash';

export default function getTotalPostCommentsCount(sources) {
  if (!sources) {
    return 0;
  }

  const { meta } = sources;

  // look for `Total` and 'total' property first
  if (meta && meta.length > 0) {
    if (meta[0].Total != null) {
      return _.sumBy(sources.meta, val =>
        _.isNumber(val.Total) ? val.Total : 0
      );
    }

    if (meta[0].total != null) {
      return _.sumBy(sources.meta, val =>
        _.isNumber(val.total) ? val.total : 0
      );
    }
  }

  if (_.has(sources, 'posts count')) {
    return sources['posts count'] + sources['comments count'];
  }

  if (_.has(sources, 'postsCount')) {
    return sources.postsCount + sources.commentsCount;
  }

  return 0;
}
