/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import arClient from '../../../util/api_client';
import {
  getGraphLabels,
  getDefaultDate,
  getRowCellData,
  buildEditorArticleUrl,
  buildEditorEmailUrl,
} from '../common/utils';
import { OK } from '../../../constants/platformStatusCodes';
import { PERFORMANCE_DASHBOARD_URL } from '../../../constants/apiUrls';
import { DEFAULT_KPI } from './inc/constants';

const initialState = {
  stats: {
    series: [],
    pageViewSeries: [],
    totalArticles: 0,
    needingAttention: '',
    performance: '',
    avgLeads: 0,
    avgSales: 0,
  },
  optimizedContent: {
    lift: 0,
  },
  leftGraphData: { Optimized: [], 'Non-Optimized': [] },
  rightGraphData: { Optimized: [], 'Non-Optimized': [] },
  selectedKPI: DEFAULT_KPI,
  fieldsKPIData: {},
  firstLoading: true,
  loading: true,
  tableLoading: true,
  contentItems: {},
  contentItemHeaders: [],
  contentTableHeaders: {},
  tableData: [],
  tablePage: 1,
  tableTotal: 0,
  filters: {
    kpi: DEFAULT_KPI,
    profile: null,
    contentType: 'Article',
    date: getDefaultDate(),
  },
  tableFilter: { column: '', order: '' },
};

const AnalyzePerformanceSlice = createSlice({
  name: 'AnalyzePerformance',
  initialState,
  reducers: {
    setAll(state, action) {
      const {
        payload: {
          optimizedContent,
          leftGraphData,
          rightGraphData,
          contentItems,
          contentTableHeaders,
          selectedKPI,
          fieldsKPIData,
          error,
          tableLoading,
          tableData,
          tableTotal,
        },
      } = action;
      state.contentItems = contentItems;
      state.leftGraphData = leftGraphData;
      state.rightGraphData = rightGraphData;
      state.contentTableHeaders = contentTableHeaders;
      state.optimizedContent = optimizedContent;
      state.tableData = tableData;
      state.tableTotal = tableTotal;
      state.filters.kpi = selectedKPI;
      state.fieldsKPIData = fieldsKPIData;
      state.error = error;
      state.tableLoading = tableLoading;
      state.firstLoading = false;
      state.loading = false;
    },
    updateStats(state, action) {
      const { payload: stats } = action;
      state.stats = stats;
      state.firstLoading = false;
      state.loading = false;
    },
    resetTable(state, action) {
      state.tableLoading = true;
      state.tableData = [];
      state.tablePage = 1;
      state.tableTotal = 0;
    },
    updateTable(state, action) {
      const {
        payload: { tableData, tableTotal },
      } = action;
      state.tableData = tableData;
      state.tableLoading = false;
      state.tableTotal = tableTotal;
    },
    setLoading(state, action) {
      state.loading = true;
      state.tableLoading = true;
    },
    setTableLoading(state, action) {
      state.tableLoading = true;
    },
    updateFilter(state, action) {
      const {
        payload: { id, value },
      } = action;
      state.filters[id] = value;
    },
    updateTableFilter(state, action) {
      const {
        payload: { column, order },
      } = action;
      if (!order) {
        state.tableFilter = { column: '', order: '' };
      } else {
        state.tableFilter = { column, order };
      }
    },
    updateTablePage(state, action) {
      const {
        payload: { page },
      } = action;
      state.tablePage = page;
    },
  },
});

export const {
  setAll,
  updateStats,
  resetTable,
  updateTable,
  setLoading,
  setTableLoading,
  updateFilter,
  updateTableFilter,
  updateTablePage,
} = AnalyzePerformanceSlice.actions;

export const initPerfData = () => async (dispatch, getState) => {
  try {
    const {
      optimizedContent,
      leftGraphData,
      rightGraphData,
      contentItems,
      contentTableHeaders,
      fieldsKPIData,
      selectedKPI,
      tableData,
      tableTotal,
    } = await requestApiPerfDash(getState);
    dispatch(
      setAll({
        optimizedContent,
        leftGraphData,
        rightGraphData,
        contentItems,
        contentTableHeaders,
        tableData,
        tableTotal,
        fieldsKPIData,
        selectedKPI,
        error: '',
        tableLoading: false,
      })
    );
  } catch (error) {
    // TODO show error on UI
    console.log(error);
    dispatch(
      setAll({
        optimizedContent: {
          lift: 0,
        },
        leftGraphData: { Optimized: [], 'Non-Optimized': [] },
        rightGraphData: { categories: [], Optimized: [], 'Non-Optimized': [] },
        contentItems: {},
        contentTableHeaders: {},
        tableData: [],
        tableTotal: 0,
        fieldsKPIData: {},
        selectedKPI: DEFAULT_KPI,
        error: error.message,
        tableLoading: false,
      })
    );
  }
};

export const requestApiPerfDash = getState =>
  new Promise((resolve, reject) => {
    const {
      AnalyzePerformance: {
        filters: { contentType, profile, kpi, date },
      },
    } = getState();

    let startDate = null;
    let endDate = null;
    if (date) {
      const splitDate = date.split('_');
      startDate = splitDate[0];
      endDate = splitDate[1];
    }

    if (profile == null || profile === 0 || kpi == 0) return null;

    // Callback executed on API response
    const cb = respApi => {
      if (respApi.status !== OK) {
        reject(respApi.error);
      } else {
        const {
          data: {
            leftTopPerformanceData,
            leftGraphData,
            rightGraphData,
            contentItems,
            contentTableHeaders,
            fieldsKPIData,
            selectedKPI,
            totContentItems,
          },
        } = respApi;

        const tableData = buildTableData(respApi.data, contentType);
        const optimizedContent = {};
        optimizedContent.lift = leftTopPerformanceData.Optimized.liftPerc;
        const tableTotal = totContentItems;

        resolve({
          optimizedContent,
          leftGraphData,
          rightGraphData,
          contentItems,
          contentTableHeaders,
          fieldsKPIData,
          selectedKPI,
          tableData,
          totContentItems,
          tableTotal,
        });
      }
    };
    arClient.doApiCall(
      PERFORMANCE_DASHBOARD_URL,
      { contentType, profileId: profile, selectedKPI: kpi, startDate, endDate },
      cb
    );
  });

const fetchData = () => async (dispatch, getState) => {
  dispatch(updateStats({}));

  const respTable = {
    tableData: [],
    tableTotal: 0,
  };
  dispatch(updateTable(respTable));
};

export const fetchStats = () => async dispatch => {
  dispatch(setLoading());
  dispatch(resetTable());
  // dispatch(fetchData());
  dispatch(initPerfData());
};

export const fetchTable = (column, order, page) => async dispatch => {
  dispatch(updateTableFilter({ column, order }));
  dispatch(updateTablePage({ page }));
  dispatch(setTableLoading());
  dispatch(fetchData());
};

export const updateSearchParamsFilter = (history, location, key, value) => {
  const searchParams = new URLSearchParams(location.search);
  if (key === 'profile') {
    // profile changed, reset all filters
    searchParams.delete('date');
    searchParams.set('kpi', DEFAULT_KPI);
  }
  if (value === null) {
    // when value is null remove filter
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  // Any other filter change resets table page
  if (key !== 'page') {
    searchParams.set('page', '1');
  }
  history.push({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
  if (key === 'profile') {
    return { page: 1, date: null, kpi: DEFAULT_KPI, profile: value };
  }
  return { page: 1, [key]: value };
};

export const buildTableData = (apiData, contentType) => {
  const {
    contentItems,
    contentTableHeaders: { columnNames },
  } = apiData;
  const tableData = contentItems.map(row => {
    const {
      id,
      title,
      publishDate,
      engagementData,
      URL,
      // trend,
      profileMatch,
      trendData,
    } = row;

    const editorUrl =
      contentType === 'Article'
        ? buildEditorArticleUrl(contentType, row)
        : buildEditorEmailUrl(contentType, row);

    return {
      key: id,
      title,
      publishDate,
      url: URL,
      editorUrl,
      engagementData,
      trend: {
        labels: getGraphLabels(1, 14),
        datasets: [
          {
            label: 'Orders',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: profileMatch ? '#1fb7e4' : '#faa84f',
            borderDash: [0, 0],
            data: trendData.map(elem => parseInt(elem, 10)),
          },
        ],
      },
    };
  });
  return tableData;
};

export default AnalyzePerformanceSlice.reducer;
