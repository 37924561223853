import { toAbsoluteUrl } from '../../_helpers';

export function getInitLayoutConfig() {
  return {
    demo: 'demo1',
    js: {
      breakpoints: {
        sm: '576',
        md: '768',
        lg: '992',
        xl: '1200',
        xxl: '1200',
      },
      colors: {
        theme: {
          base: {
            white: '#ffffff',
            primary: '#69E1C6',
            secondary: '#0182EB',
            success: '#69E1C6',
            info: '#0182EB',
            warning: '#FFC258',
            danger: '#FE8083',
            light: '#F3F6F9',
            dark: '#3E3E3E',
          },
          light: {
            white: '#ffffff',
            primary: '#69E1C6',
            secondary: '#0182EB',
            success: '#69E1C6',
            info: '#0182EB',
            warning: '#FFC258',
            danger: '#FE8083',
            light: '#F3F6F9',
            dark: '#3E3E3E',
          },
          inverse: {
            white: '#ffffff',
            primary: '#ffffff',
            secondary: '#212121',
            success: '#ffffff',
            info: '#ffffff',
            warning: '#ffffff',
            danger: '#ffffff',
            light: '#464E5F',
            dark: '#ffffff',
          },
        },
        gray: {
          gray100: '#F3F6F9',
          gray200: '#ECF0F3',
          gray300: '#E5EAEE',
          gray400: '#D6D6E0',
          gray500: '#B5B5C3',
          gray600: '#80808F',
          gray700: '#464E5F',
          gray800: '#1B283F',
          gray900: '#212121',
        },
      },
      fontFamily: 'Poppins',
    },
    loader: {
      enabled: false,
      type: '',
      logo: '/metronic/react/demo1/media/logos/logo-dark-sm.png',
      message: 'Please wait...',
    },
    toolbar: {
      display: false,
    },
    header: {
      self: {
        width: 'fluid',
        theme: 'light',
        fixed: {
          desktop: true,
          mobile: true,
        },
      },
      menu: {
        self: {
          display: true,
          layout: 'default',
          'root-arrow': false,
          'icon-style': 'duotone',
        },
        desktop: {
          arrow: true,
          toggle: 'click',
          submenu: {
            theme: 'light',
            arrow: true,
          },
        },
        mobile: {
          submenu: {
            theme: 'light',
            accordion: true,
          },
        },
      },
    },
    subheader: {
      display: false,
      displayDesc: false,
      displayDaterangepicker: false,
      layout: 'subheader-v1',
      fixed: false,
      width: 'fluid',
      clear: false,
      style: 'solid',
    },
    content: {
      width: 'fixed',
    },
    brand: {
      self: {
        theme: 'light',
      },
    },
    aside: {
      self: {
        theme: 'dark',
        display: true,
        fixed: true,
        minimize: {
          toggle: true,
          default: false,
          hoverable: false,
        },
      },
      footer: {
        self: {
          display: true,
        },
      },
      menu: {
        dropdown: false,
        scroll: true,
        'icon-style': 'duotone',
        submenu: {
          accordion: true,
          dropdown: {
            arrow: true,
            'hover-timeout': 500,
          },
        },
      },
    },
    footer: {
      self: {
        fixed: true,
        width: 'fluid',
      },
      fixed: false,
    },
    extras: {
      search: {
        display: false,
        layout: 'dropdown',
        offcanvas: {
          direction: 'right',
        },
      },
      notifications: {
        display: false,
        layout: 'dropdown',
        dropdown: {
          style: 'dark',
        },
        offcanvas: {
          directions: 'right',
        },
      },
      'quick-actions': {
        display: true,
        layout: 'dropdown',
        dropdown: {
          style: 'dark',
        },
        offcanvas: {
          directions: 'right',
        },
      },
      user: {
        display: false,
        layout: 'offcanvas',
        dropdown: {
          style: 'dark',
        },
        offcanvas: {
          directions: 'right',
        },
      },
      languages: {
        display: false,
      },
      cart: {
        display: false,
        dropdown: {
          style: 'dark',
        },
      },
      'quick-panel': {
        display: false,
        offcanvas: {
          directions: 'none',
        },
      },
      chat: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      scrolltop: {
        display: false,
      },
    },
  };
}
