export const PAGINATION_SIZE = 10;

export const API_MEASURE_RTSYNONYMS = 'RtSynonyms';
export const API_MEASURE_AROUSAL = 'Arousal';
export const API_MEASURE_READABILITY = 'Readability';
export const API_MEASURE_RTSPELLING = 'RtSpellingGrammar';
export const API_MEASURE_TOPICS = 'Topics';
export const API_MEASURE_WORDSCOUNT = 'WordsCount';
export const API_SUMMARYISSUES = 'summaryIssues';
export const API_SUMMARYISSUES_CRITICAL = 'critical';
export const API_SUMMARYISSUES_STYLISTIC = 'stylistic';

export const EMAIL_CONTENT_TYPE = 'email';
export const BLOG_CONTENT_TYPE = 'article';
export const PRODUCT_DESC_TYPE = 'ProductDesc';
export const DEMO_TYPE = 'demo';
export const ADS_CONTENT_TYPE = 'GoogleAds';
export const PRODUCT_DESCRIPTIONS_CONTENT_TYPE = 'ProductDesc';
export const ADS_BATCH_INTEGRATION = 'batch';

export const FETCH_ERROR = 'FETCH_ERROR';

export const HUBSPOT = 'HUBSPOT';
export const WORDPRESS = 'WORDPRESS';
export const AR = 'AR';

export const EMAIL_GENERIC_TYPE = 'GENERIC';

export const TRACK_JS_KEY = '43193a5febda48998aa5ee08f7af0ef0';

export const ADS_EDITOR_SIDEBAR_MODES = {
  multiselect: 'multiselect',
  sinlgeselect: 'sinlgeselect',
};

export const GRAMMAR_POPOVER = 'GRAMMAR_POPOVER';
export const EMOTION_POPOVER = 'EMOTION_POPOVER';
export const OPTIMIZE_API_POPOVER = 'OPTIMIZE_API_POPOVER';
export const ADS_POPOVER = 'ADS_POPOVER';

export const USERFLOW_DEV = 'ct_gc2jq5apabamzp4m47xj2zrmwe';
export const USERFLOW_PRODUCTION = 'ct_i2czp5pu3vgsxk7kf7lyobsrxa';

export const DEFAULT_PROFILE_NAME = 'Universal Profile';
