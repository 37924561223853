/* eslint-disable camelcase */
import _ from 'lodash';

export const INF_MIN_AGE_FILTER_VALUE = 18;
export const INF_MAX_AGE_FILTER_VALUE = 45;
export const INF_SIZE_MIN_VALUE = 1000;
export const INF_SIZE_MAX_VALUE = 50000000;

/**
 * "audience_age": [
  {
      "id": "18-24",
      "weight": 0.45
  },
]
 */
function reconcileAudienceAge(ageData) {
  if (_.isArray(ageData)) {
    return ageData;
  }
  try {
    const { groups, prc } = ageData;
    const [fromAge, toAge] = groups[0].split('_');
    return [{ id: `${fromAge}-${toAge}`, weight: prc / 100 }];
  } catch (e) {
    return [];
  }
}

/**
  "influencer_age": {
      "min": 25,
      "max": 45
  }
 */
function reconcileInfluencerAge(infAgeData) {
  if (infAgeData) {
    return infAgeData;
  }
  return {
    min: INF_MIN_AGE_FILTER_VALUE,
    max: INF_MAX_AGE_FILTER_VALUE,
  };
}

/**
  "audience_gender": {
      "id": "UNKNOWN",
      "weight": 0
  }
 */
function reconcileAudienceGender(genderData) {
  if (Array.isArray(genderData)) {
    return genderData;
  }

  if (_.has(genderData, 'id')) {
    return genderData;
  }

  if (genderData.gender === 'any') {
    return {
      id: 'UNKNOWN',
      weight: 0,
    };
  }
  return {
    id: _.capitalize(genderData.gender),
    weight: genderData.prc,
  };
}

export default function reconcileCreatorFiltersData(data) {
  const toBeProcessedNetworks = ['instagram', 'tiktok', 'youtube'];

  const revertModashStructure = selectedFilters => {
    // Revert influencer filters
    const influencerFilters = _.mapKeys(
      selectedFilters.influencer || {},
      (value, key) => `influencer_${key}`
    );

    // Revert audience filters
    const audienceFilters = _.mapKeys(
      selectedFilters.audience || {},
      (value, key) => `audience_${key}`
    );

    // Merge reverted filters and similar field back into selectedFilters
    return {
      ...influencerFilters,
      ...audienceFilters,
      similar: selectedFilters.similar || '',
      LDACompliance: selectedFilters.LDACompliance || null,
    };
  };

  const result = _.keys(data).reduce((result, currNetwork) => {
    const newObj = _.cloneDeep(data[currNetwork]);

    // handle modash platforms
    if (toBeProcessedNetworks.includes(currNetwork)) {
      // Revert modash structure for selectedFilters
      newObj.selectedFilters = revertModashStructure(newObj.selectedFilters);

      // Get selectedFilters for the current network
      const { selectedFilters } = newObj;

      // Iterate through each filter and apply necessary transformations
      _.forEach(selectedFilters, (value, key) => {
        if (key === 'audience_age') {
          selectedFilters[key] = reconcileAudienceAge(value);
        } else if (key === 'audience_gender') {
          selectedFilters[key] = reconcileAudienceGender(value);
        } else if (key === 'influencer_age') {
          selectedFilters[key] = reconcileInfluencerAge(value);
        } else if (key === 'influencer_followers' && !value) {
          selectedFilters[key] = {
            min: INF_SIZE_MIN_VALUE,
            max: INF_SIZE_MAX_VALUE,
          };
        }
      });

      // Handle default value for audience_location if not present
      if (!selectedFilters.audience_location) {
        selectedFilters.audience_location = [
          {
            id: 148838,
            label: 'United States',
            weight: 0.5,
          },
        ];
      }
      // Handle default value for influencer_location if not present
      if (!selectedFilters.influencer_location) {
        selectedFilters.influencer_location = ['148838'];
      }
    }

    return {
      ...result,
      [currNetwork]: newObj,
    };
  }, {});

  return result;
}
